<template>
  <div>
    <div class="banner">
      <h3 class="text-36 text-30 font-weight-black pb-md-2 aa">{{ $vuetify.lang.t("$vuetify.contact.title") }}</h3>
      <h3 class="text-36 text-30 font-weight-medium tt">CONTACT US</h3>
    </div>
    <v-container class="content">
      <v-form ref="form">
        <div class="text-20 pt-md-5 pb-md-8 pb-2 font-weight-bold">
          {{ $vuetify.lang.t("$vuetify.contact.text1") }} <br>
          {{ $vuetify.lang.t("$vuetify.contact.text2") }}
        </div>
        <v-row>
          <v-col md="6" cols="12">
            <v-img src="../../assets/contact/img2.png" contain></v-img>
          </v-col>
          <v-col md="6" cols="12">
            <div class="form-title pb-md-8 pb-4 font-weight-bold">{{
                $vuetify.lang.t("$vuetify.contact.text3")
              }}
            </div>
            <v-row>
              <v-col cols="12" md="6" class="pb-0">
                <div class="form-item pb-2 font-weight-bold">
                  <span class="text-red">*</span>
                  {{ $vuetify.lang.t("$vuetify.contact.text4") }}
                </div>
                <v-text-field v-model="formData.userName" outlined solo flat dense class="text-14"
                              :rules="rules.userName"
                              :placeholder="$vuetify.lang.t('$vuetify.contact.text7')" required></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="pb-0">
                <div class="form-item pb-2 font-weight-bold">
                  <span class="text-red">*</span>
                  {{ $vuetify.lang.t("$vuetify.contact.text5") }}
                </div>
                <v-text-field v-model="formData.contact" outlined solo flat dense class="text-14" :rules="rules.contact"
                              :placeholder="$vuetify.lang.t('$vuetify.contact.text8')" required></v-text-field>
              </v-col>
              <v-col cols="12" class="pb-0 pt-0">
                <div class="form-item pb-2 font-weight-bold">
                  <span class="text-red">*</span>
                  {{ $vuetify.lang.t("$vuetify.contact.text6") }}
                </div>
                <v-textarea outlined flat solo class="text-14" v-model="formData.content" :rules="rules.content"
                            :placeholder="$vuetify.lang.t('$vuetify.contact.text9')"></v-textarea>
              </v-col>
              <v-col cols="12" class="d-none d-md-block">
                <v-btn :width="219" :height="64" class="btn-submit font-weight-bold" @click="submitFunc">
                  {{ $vuetify.lang.t("$vuetify.submit") }}
                </v-btn>
              </v-col>
              <v-col cols="12" class="d-md-none text-center">
                <v-btn :width="154" :height="48" class="btn-submit font-weight-bold" @click="submitFunc">
                  {{ $vuetify.lang.t("$vuetify.submit") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import {feedback} from "../../api/index";

export default {
  name: "ContactIndex",
  data() {
    return {
      formData: {
        userName: '',
        contact: '',
        content: ''
      },
      rules: {
        contact: [
          value => !!value || this.$vuetify.lang.t('$vuetify.contact.text8'),
          value => (value || '').length <= 20 || 'Max 20 characters',
          value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
          },
        ],
        userName: [
          value => !!value || this.$vuetify.lang.t('$vuetify.contact.text7'),
        ],
        content: [
          value => !!value || this.$vuetify.lang.t('$vuetify.contact.text9'),
        ]
      },
    }
  },
  methods: {
    async submitFunc() {
      const valid = this.$refs.form.validate();
      if (!valid) {
        return;
      }
      let res = await feedback({
        ...this.formData
      });
      if (res.code == 200) {
        this.$SnackBar.show('提交成功', {type: 'success'});
        this.$refs.form.reset();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.banner {
  background: url(../../assets/free/bg.jpg) no-repeat;
  //background-size: 100% 100%;
  //padding: 80px 0;
  color: #fff;
  text-align: center;
  background-position: center;
  padding: 100px 0;
}

.content {
  padding-bottom: 5.625rem;

  .form-item {
    font-size: 1rem;
    color: #1f1f1f;

    .text-red {
      color: #F56C6C;
    }
  }

  .btn-submit {
    background: linear-gradient(135deg, #FFED00 0%, #FED600 100%);
    // font-size: 1.625rem;
  }
}

@media (max-width: 960px) {
  .content {
    padding-bottom: 2rem;

    .form-item {
      font-size: 0.875rem;
    }
  }

  .banner {
    background-size: 100% 100%;
    padding: 52px 0;
    //text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
    //letter-spacing: 8px;
    color: #fff;
  }
  .tt {
    letter-spacing: 1px;
    font-size: 1rem !important;
  }
  .aa{
    font-size: 1.875rem !important;
  }
}
</style>