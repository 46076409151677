<template>
  <v-container class="home">
    <!-- 轮播图 -->
    <div class="banner">
      <v-carousel cycle hide-delimiters show-arrows-on-hover class="d-none d-md-block">
        <template v-if="list.length > 0">
          <v-carousel-item v-for="(item, i) in list" :key="i">
            <v-img contain :src="item.pic" height="100%" width="100%"></v-img>
          </v-carousel-item>
        </template>
        <v-carousel-item v-else>
          <v-img contain src="../assets/banner2.png" height="100%" width="100%"></v-img>
        </v-carousel-item>
      </v-carousel>
      <v-carousel
        height="200"
        cycle
        hide-delimiters
        show-arrows-on-hover
        class="d-md-none"
      >
        <template v-if="list.length > 0">
          <v-carousel-item v-for="(item, i) in list" :key="i">
            <v-img contain :src="item.pic" height="100%" width="100%"></v-img>
          </v-carousel-item>
        </template>
        <v-carousel-item v-else>
          <v-img contain src="../assets/banner2.png" height="100%" width="100%"></v-img>
        </v-carousel-item>
      </v-carousel>
      <div class="banner-tip" @click="overlay = true">
        <v-img contain src="../assets/banner_img.png"></v-img>
      </div>
      <v-overlay :value="overlay" @click="overlay = false">
        <div class="address d-flex">
          <v-img contain src="../assets/address_1.png" class="address-img"></v-img>
          <v-img contain src="../assets/address_2.png" class="address-img"></v-img>
        </div>
      </v-overlay>
    </div>

    <div class="text-center d-none d-md-block">
      <div class="home-title">{{ $vuetify.lang.t("$vuetify.home.title1") }}</div>
      <div class="text-md-34 text-20 font-weight-bold pb-md-9 pb-2">
        {{ $vuetify.lang.t("$vuetify.home.text2") }}
      </div>
      <!-- <div class="d-none d-md-block">
        <v-img src="../assets/img1.png" contain></v-img>
      </div>
      <div class="d-md-none">
        <v-img src="../assets/img1_m.png" contain></v-img>
      </div> -->
      <div class="d-none d-md-block">
        <v-img src="../assets/img1.png" contain></v-img>
      </div>
      <div class="step">
        <div class="d-md-flex justify-space-between">
          <div class="item pb-7">
            <div class="step-img d-md-none">
              <v-img src="../assets/step1.png" contain></v-img>
            </div>
            <div class="text-20 font-weight-bold pb-4">
              {{ $vuetify.lang.t("$vuetify.home.text3") }}
            </div>
            <div class="text-md-16 text-14 text-000-5">
              {{ $vuetify.lang.t("$vuetify.home.text4") }}
            </div>
          </div>
          <div class="item pb-7">
            <div class="step-img d-md-none">
              <v-img src="../assets/step2.png" contain></v-img>
            </div>
            <div class="text-20 font-weight-bold pb-4">
              {{ $vuetify.lang.t("$vuetify.home.text5") }}
            </div>
            <div class="text-md-16 text-14 text-000-5">
              {{ $vuetify.lang.t("$vuetify.home.text6") }}
            </div>
          </div>
          <div class="item pb-7">
            <div class="step-img d-md-none">
              <v-img src="../assets/step3.png" contain></v-img>
            </div>
            <div class="text-20 font-weight-bold pb-4">
              {{ $vuetify.lang.t("$vuetify.home.text7") }}
            </div>
            <div class="text-md-16 text-14 text-000-5">
              {{ $vuetify.lang.t("$vuetify.home.text8") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="home-title">{{ $vuetify.lang.t("$vuetify.home.title2") }}</div>
    <v-row class="list d-md-none" v-if="items.length > 0">
      <v-col cols="12" v-for="(item, index) in items" :key="index">
        <div class="list-item">
          <v-img
            cover
            :src="item.vehiclePic.split(',')[0]"
            width="100%"
            height="190"
          ></v-img>
          <div class="font-weight-bold text-20 pt-4 pb-2">{{ item.name }}</div>
          <div class="text-14 text-000-5 pb-2">
            {{ item.brandName }} / {{ item.subBrand }}
          </div>
          <div class="text-14 text-000-5 pb-2">
            {{ item.speed }} / {{ item.passenger }}{{ $vuetify.lang.t("$vuetify.seat") }}
          </div>
          <CommentStar
            class="pb-3"
            :score="item.score"
            :width="38"
            :text-size="20"
            isText
          ></CommentStar>
          <MyLabel :labels="(item.tagList && item.tagList.split(',')) || []"></MyLabel>
          <div class="price">
            <span class="text-md-50 text-32 font-weight-bold text-red"
              >JPY {{ $formatPrice(item.price) }}</span
            ><span class="text-24">/{{ $vuetify.lang.t("$vuetify.day") }}</span>
          </div>
          <v-btn
            class="btn-submit text-md-22 text-20 mt-3"
            width="100%"
            @click="$router.push('/book/detail/' + item.vehicleId)"
            >{{ $vuetify.lang.t("$vuetify.home.text1") }}</v-btn
          >
        </div>
      </v-col>
    </v-row>
    <v-carousel
      cycle
      show-arrows-on-hover
      height="auto"
      hide-delimiter-background
      delimiter-icon="mdi-minus"
      class="d-none d-md-block mt-10"
      v-if="items.length > 0"
    >
      <v-carousel-item v-for="(items, index) in groupedItems" :key="index">
        <v-row class="list align-stretch">
          <v-col cols="6" md="4" v-for="item in items" :key="item.title">
            <div class="list-item mb-5">
              <v-img
                :src="item.vehiclePic.split(',')[0]"
                cover
                width="100%"
                height="240"
              ></v-img>
              <div class="font-weight-bold text-20 mt-4 mb-2 two-line-clamp">
                {{ item.name }}
              </div>
              <div class="text-14 text-000-5 pb-2">
                {{ item.brandName }} / {{ item.subBrand }}
              </div>
              <div class="text-14 text-000-5 pb-2">
                {{ item.speed }} / {{ item.passenger
                }}{{ $vuetify.lang.t("$vuetify.seat") }}
              </div>
              <CommentStar class="pb-3" :score="item.score" isText></CommentStar>
              <MyLabel
                :labels="(item.tagList && item.tagList.split(',')) || []"
              ></MyLabel>
              <div class="price">
                <span class="text-32 font-weight-bold text-red"
                  >JPY {{ $formatPrice(item.price) }}</span
                >
                <span class="text-14">/{{ $vuetify.lang.t("$vuetify.day") }}</span>
              </div>
              <v-btn
                class="btn-submit text-14 mt-3"
                width="100%"
                height="44px"
                @click="$router.push('/book/detail/' + item.vehicleId)"
                >{{ $vuetify.lang.t("$vuetify.home.text1") }}</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-carousel-item>
    </v-carousel>
    <div class="text-center pt-8 text-14 text-000-5" v-if="items.length == 0">
      {{ $vuetify.lang.t("$vuetify.none") }}
    </div>
    <div
      class="more mt-md-14 mt-7"
      @click="$router.push('/book')"
      v-if="items.length > 0"
    >
      {{ $vuetify.lang.t("$vuetify.home.text9") }}>>
    </div>
    <div class="text-center mt-md-10 mt-5">
      <div class="home-title">{{ $vuetify.lang.t("$vuetify.home.title3") }}</div>
      <div class="text-md-34 text-20 font-weight-bold">
        {{ $vuetify.lang.t("$vuetify.home.text10") }}
      </div>
      <div class="language d-flex justify-space-between">
        <div>
          <div><v-img src="../assets/lang_cn.png" contain></v-img></div>
          <div class="text-20 font-weight-bold pt-4">中文</div>
        </div>
        <div>
          <div><v-img src="../assets/lang_ja.png" contain></v-img></div>
          <div class="ttext-20 font-weight-bold pt-4">日本语</div>
        </div>
        <div>
          <div><v-img src="../assets/lang_ko.png" contain></v-img></div>
          <div class="text-20 font-weight-bold pt-4">한국어</div>
        </div>
        <div>
          <div><v-img src="../assets/lang_en.png" contain></v-img></div>
          <div class="text-20 font-weight-bold pt-4">English</div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
// @ is an alias to /src
import CommentStar from "@/components/CommentStar/index.vue";
import MyLabel from "@/components/MyLabel/index.vue";
import { vehiclesList, getCarouselMapList } from "../api/index";

export default {
  name: "HomeView",
  components: {
    CommentStar,
    MyLabel,
  },
  data() {
    return {
      items: [],
      list: [],
      overlay: false,
    };
  },
  computed: {
    groupedItems() {
      const groups = [];
      for (let i = 0; i < this.items.length; i += 3) {
        groups.push(this.items.slice(i, i + 3));
      }
      return groups;
    },
  },
  watch: {
    "$vuetify.lang.current": {
      immediate: true,
      handler() {
        // 语言切换时重新请求数据
        this.getList();
        this.getCarouselMapList();
      },
    },
  },
  created() {
    // this.getList();
  },
  methods: {
    async getList() {
      let params = {
        page: 1,
        size: 9,
      };
      let res = await vehiclesList(params);
      if (res.code == 200) {
        this.items = res.data.list;
      }
    },
    async getCarouselMapList() {
      let res = await getCarouselMapList();
      if (res.code == 200) {
        this.list = res.data.list;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  padding-top: 0;
  .banner {
    position: relative;
    // height: 200px;
    background-color: #edeff0;

    .banner-tip {
      position: absolute;
      right: 0;
      bottom: 0;
    }

    .address {
      width: 40%;
      padding: 20px;
      background: #ffffff;
      box-shadow: 0px 1px 9px 0px rgba(0, 0, 0, 0.08);
      margin: 0 auto;

      .v-responsive {
        flex: inherit;
      }
    }
  }

  .step {
    padding: 10px 0 25px;
    position: relative;

    .item {
      width: 35%;
    }
  }

  .home-title {
    font-size: 1.5rem;
    color: rgba(0, 0, 0, 0.36);
    font-weight: 500;
    text-align: center;
    padding: 58px 0 20px;
  }

  .list-item {
    box-shadow: 0px 5px 45px 0px rgba(34, 53, 162, 0.08);
    border-radius: 30px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .more {
    width: 172px;
    height: 46px;
    border-radius: 6px;
    border: 1px solid #fed600;
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
    font-size: 0.875rem;
    line-height: 46px;
    cursor: pointer;
    margin: 0 auto;
  }

  .language {
    width: 80%;
    margin: 70px auto 90px;
  }

  .v-carousel__controls {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .v-carousel__controls .v-btn {
    background-color: transparent;
    color: #000;
  }
}

::v-deep .v-overlay__content {
  width: 100%;
}

@media (max-width: 960px) {
  .home {
    .banner {
      .address {
        width: 100%;
      }

      .banner-tip {
        width: 50px;
      }

      .address-img {
        // width: 50%;
      }
    }

    .list-item {
      border-radius: 20px;
    }

    .step {
      padding: 8px 0 0;

      .item {
        width: 100%;

        .step-img {
          width: 120px;
          margin: 0 auto;
        }
      }
    }

    .home-title {
      padding: 20px 0 8px;
      font-size: 0.875rem;
    }

    .more {
      width: 142px;
      height: 36px;
      line-height: 36px;
      font-size: 0.875rem;
    }

    .language {
      width: 95%;
      margin: 22px auto 30px;

      & > div {
        width: 20%;
      }
    }
  }
}
</style>
