import en from 'vuetify/es5/locale/en';

export default {
    appName: 'Qingsong Car Rental',
    label: 'English',
    //page login
    login: 'Login',
    register: 'Register',
    username: 'Username',
    password: 'Password',
    login_account: 'Login account',
    submit: 'submit',
    logout: 'logout',
    none: 'No data',
    seat: 'Seat',
    day: 'Day',
    cancel: 'Cancel',
    del: 'Delete',
    bookText: 'Reserve',
    week: 'Week',
    month: 'Month',
    year: 'Year',
    cDay:'Day',
    // menu
    menu: {
        home: 'Home',
        free: 'Car Rental',
        about: 'About',
        book: "Book",
        contact: "Contact",
        order: "Order",
        answer: 'FAQ',
        personal: 'Personal Data',
        account: 'Account Security',
        payRecord: 'Payment Record',
        charter: 'Chartered bus',
        consult: 'Contact Us'
    },
    about: {
        title: 'About Us',
        content: 'Here is about the content of our page',
        text1: 'Reasons to choose UTOR',
        text2: 'Why choose UTOR',
        text3: 'Customer Reviews',
        text4: 'Social Media',
        text5: 'As lifestyles change, people\'s demands for car life are becoming more and more diversified. Uto Rental Car will be committed to providing customers with clean, convenient, safe and thoughtful car rental services. In addition, we will also attach importance to ideas from the customer\'s perspective and continue to work hard to provide more customers with safe and comfortable car rental services in the future.\n' +
            'If you need advice on car selection when traveling, please feel free to contact us.',
        text6: 'Rich selection of models',
        text7: 'Whether you are traveling alone or with family and friends, we can provide a vehicle that is suitable for you. From affordable compact cars to spacious and comfortable luxury MPVs, our fleet covers a wide range of types to ensure you enjoy the best driving experience during your trip.',
        text8: 'Convenient services designed for families',
        text9: 'We have specially prepared child seats for family travel to ensure the safety and comfort of children. In addition, all vehicles are equipped with ETC equipment, allowing you to quickly pass through Japan\'s highway toll booths, eliminating the trouble of queuing and saving precious travel time.',
        text10: 'Transparent pricing mechanism',
        text11: 'When you rent a car with Qingsong, you don’t have to worry about hidden fees. We provide a transparent pricing system, and all costs are clear at a glance when booking, so you can enjoy your trip without worrying about extra expenses.',
        text12: '24/7 multilingual customer support',
        text13: 'We understand the importance of communication when traveling to a foreign country. That’s why our customer service team provides 24-hour multilingual support to provide you with timely assistance no matter what problems you encounter at any time during your trip.',
        text14: 'Kansai Airport Exclusive Service',
        text15: 'To make your trip more convenient, we provide exclusive pick-up and return services at Kansai International Airport. Whether you have just arrived or are about to leave Japan, you can easily complete the rental or return procedures at the airport to start or end your worry-free journey.',
        text16: 'Comprehensive security protection',
        text17: 'Safety is our top priority. All vehicles undergo a rigorous safety inspection before each rental and come with full insurance coverage, giving you peace of mind every kilometer you drive in Japan.',
        text18: 'Qingsong Car Rental is committed to providing first-class car rental services to every traveler coming to Japan, ensuring that your journey is not only comfortable but also worry-free. We provide not only a car, but a complete set of travel solutions tailored for you. Here are six reasons to choose Qingsong Car Rental:',
        text19: 'Qingsong Car Rental is not only your travel partner, but also your intimate guide in Japan. Choose us to make your trip to Japan more memorable and enjoyable. We look forward to serving you and wish you a pleasant journey!'
    },
    contact: {
        title: 'Contact Us',
        text1: 'Contact Us',
        text2: 'We will be happy to serve you',
        text3: 'Problem Feedback',
        text4: 'Your Name',
        text5: 'Your Email Address',
        text6: 'Content',
        text7: 'Please enter your name',
        text8: 'Please enter email',
        text9: 'Please enter your feedback',
    },
    charter: {
        text1: 'Toyota Alpha',
        text2: 'Toyota Hiace',
        text3: '* Airport transfers, theme park transfers, etc. are all one-way prices.',
        text4: 'Osaka pick up',
        text5: 'Kyoto pick-up',
        text6: 'Universal Studios Osaka',
        text7: 'Osaka (Round trip within the city)',
        text8: 'Kyoto (Round trip within the city)',
        text9: 'Osaka, Nara, Kyoto (same-day trip)',
        text10: 'All-inclusive price/Chinese driver-guide/airport pick-up and drop-off available<br>※ If you need other car models, please consult and confirm. The price fluctuates slightly according to the peak and off-season, and reservations require a second price check.',
        text11: 'Kansai region',
        text12: 'Contact Qingsong Car Rental',
        alphard: 'Maximum 6 passengers',
        hiace: 'Maximum 9 passengers'
    },
    consult: {
        text1: 'Please select your requirements:',
        text2: '【Car rental inquiry】',
        text3: '【Inquiries about rental services】',
        text4: 'Last Name',
        text5: 'First Name',
        text6: 'Please select the location of the consultant',
        text7: 'Living in Japan',
        text8: 'Living outside Japan',
        text9: 'Both (when there are multiple consultants)',
        text10: 'postal code',
        text11: 'Please enter without hyphens',
        text12: '点击此处进行邮政编码搜索',
        text13: 'Place',
        text14: 'Email',
        text15:'Phone number',
        text16: 'If you are leasing a car, please fill in the type of vehicle you want and the duration of its use.',
        text17: 'If you would like to rent a car, please select the type of car below.',
        text18: 'Light Vehicles',
        text19: 'hybrid electric vehicle',
        text20: 'Vans/Minivans',
        text21: 'Compact Car',
        text22: 'Light truck/truck',
        text23: 'Other',
        text24: 'Other models',
        text25: 'If you would like a different model or above, please fill in the information below.',
        text26: 'Select car rental add-on options：',
        text27: 'ETC vehicles',
        text28: 'Car Navigation',
        text29: 'Driving Recorder',
        text30: 'Car transporter',
        text31: '4WD Four-wheel Drive (only for small commercial vehicles and light vans)',
        text32: 'Studless tires',
        text33: 'Contact information, such as LINE or WeChat ID',
        text34: 'Expected start time',
        text35: 'Please select your desired start date',
        text36: 'Expected duration',
        text37: 'Please enter a number',
        text38: 'Expected duration unit',
        text39: 'Number of drivers：',
        text40: 'people',
        text41: 'above',
        text42: '(Rental service only)',
        text43: 'Number of cars required：',
        text44: 'Car',
        text45: 'or above',
        text46: 'Questions, comments, etc.',
        text47: 'send',
        text48: 'Contact us by email'
    },
    user: {
        text1: 'Please enter your email/phone number',
        text2: 'Please enter password',
        text3: 'Forgot password',
        text4: 'Register Now',
        text5: 'Email Retrieval',
        text6: 'Mobile phone retrieval',
        text7: 'Please enter your phone number',
        text8: 'Please enter the verification code',
        text9: 'Please enter your password again',
        text10: 'Please enter email',
        text11: 'Get verification code',
        text12: 'Change password',
        text13: 'Please select an area code',
        text14: 'Nickname',
        text15: 'Please enter a nickname',
        text16: 'Avatar',
        text17: 'Save',
        text18: 'Email',
        text19: 'Cell phone',
        text20: 'Save successfully',
        text21: 'Distinguished member',
        text22: 'Change password',
        text23: 'Current password',
        text24: 'New password',
        text25: 'Confirm new password',
        text26: 'Please enter your current password',
        text27: 'Please enter your new password',
        text28: 'Please enter your new password again',
        text29: 'The two passwords are inconsistent',
        text30: 'Clicking to log in or register means you have read or agreed',
        text31: 'Utor Car Rental Agreement',
        text32: 'Privacy Policy',
        text33: 'Email Registration',
        text34: 'Mobile phone registration',
        text35: 'Please enter a valid email address',
        text36: 'Please enter a valid phone number',
        text37: 'Verification code sent'
    },
    home: {
        text1: 'View now',
        title1: 'Car rental process',
        title2: 'Hot vehicle',
        title3: 'Multi language customer service support',
        text2: 'Easy access to your car needs in just 3 steps',
        text3: 'Select a vehicle',
        text4: 'Select your desired vehicle model and click Book',
        text5: 'Fill in information',
        text6: 'Select the ride time and fill in your personal information',
        text7: 'Airport pickup',
        text8: 'Pick up the car at the designated location according to the vehicle information you have filled in',
        text9: 'See more',
        text10: 'Multilingual services for you'
    },
    answer: {
        title: '',
        text1: 'What kind of driver\'s license do I need to drive in Japan?',
        text2: 'A valid international driver\'s license in the format specified by the Geneva Convention is required. <a href="/#/license">check the details</a><div style="text-indent: 1.5em;">※For those holding a driver\'s license from Switzerland, Germany, France, Belgium, Taiwan, or Monaco, an official Japanese translation issued by the Japan Automobile Federation or the embassy is required.</div>',
        text3: 'Can the vehicle be driven by someone other than the applicant?',
        text4: 'Yes. However, all drivers are required to apply for a license, so please present the driver\'s license of all drivers at the store on the day of departure.',
        text5: 'Can I rent a car without making a reservation?',
        text6: 'We will rent a car if there is a vacant car in the store on the day. However, we may not be able to provide a car if it is fully booked, so we recommend making a reservation in advance.',
        text7: 'Do I need to pay a cancellation fee when I cancel my reservation?',
        text8: 'If you cancel your reservation for personal reasons, we will charge you a cancellation fee (cancellation fee) based on our company regulations. No cancellation fee is required if you cancel 7 days in advance. If you cancel within 6 days of the scheduled departure date, you will be charged a cancellation fee.',
        text9: 'What should I do if I park illegally?',
        text10: 'If you are issued a ticket, please go to the local police station to complete the procedures and pay the fine before returning. When returning the car, please present the "Notice of Traffic Violation" and the "payment slip, receipt, etc." containing the payment date. If you do not present the "Notice of Traffic Violation", "Delivery Letter, Proof of Receipt, etc." when returning the car, you will be required to pay an illegal parking fine of 30,000 yen. <div style="text-indent: 1.5em;">※After paying the fine in the future and presenting the "Notice of Violation of Traffic Rules" and the "payment slip, receipt, etc." recording the date of payment to our company, the equivalent amount of illegal parking can be refunded fee. The refund will be remitted to the designated account. (The remittance fee is borne by the customer.)</div><div style="text-indent: 1.5em;">※The company will pay the deposit after receiving an order to pay the deposit, or the customer fails to pay the full amount by the date specified by the company. Please note that when paying the prescribed fee, measures such as registration in the information management system of the National Car Rental Association will be taken. The association\'s car rental agencies may reject the customer\'s car rental request in the future. </div>',
        text11: 'What procedures are required upon departure?',
        text12: 'When going through the departure procedures, the company will confirm the driving license documents and passports of all drivers, and ask customers to confirm the rules of use. After completing the formalities, the booking fee must be paid according to the contract. (Settled upon departure.) <div style="text-indent: 1.5em;">※If the fee has been settled in advance, you can ignore this step.After a brief explanation of the vehicle\'s condition, the clerk will visually inspect the vehicle\'s surface for scratches, etc., together with the customer. The vehicle can be used after this inspection is completed.',
        text13: 'Can I pick up or return my car outside business hours?',
        text14: 'Departure and return procedures must be completed during the company\'s business hours.',
        text15: 'Can a registered member rent a car if he/she forgets to bring his/her driver\'s license?',
        text16: 'If you cannot present a valid driver\'s license and passport upon departure, you will not be able to rent the vehicle. Please be sure to bring them with you on the day.',
        text17: 'What should I pay attention to when returning the car?',
        text18: 'Please check if you have forgotten any items before returning the vehicle. We are not responsible for the loss of any forgotten items.',
        text19: 'What should I do if I cannot return the vehicle with a full tank of gas?',
        text20: 'Please return the vehicle directly to our company. We will charge customers a specified refueling fee based on the actual distance traveled.',
    },
    free: {
        title: 'Car rental',
        text1: 'Why rent a car？',
        text2: 'Travel as you please',
        text3: 'Car rental free travel, as you please, convenient and comfortable, get rid of the shackles of fixed routes and schedules, anytime and anywhere to explore the unknown beauty. You can stop as you like, walk as you like, and each stop is up to you.',
        text4: 'Go deep and explore unseen beauty',
        text5: 'Get rid of public transport time restrictions, visit your destination anytime, anywhere, and save time waiting for transfers. Compared with chartered cars or taxis, it can effectively control travel costs.',
        text6: 'Free to explore, private journey',
        text7: 'Have a private space to share intimate time with friends and family. Plenty of riding and storage space to make your journey more comfortable. Give you more freedom, privacy and comfort.',
        text8: 'The whole family will be together and enjoy the journey',
        text9: 'All luxury nanny cars, equipped with luxury interior, multimedia entertainment system and more. The large interior space ensures the comfort of the elderly and children during your journey with greater freedom and comfort.',
        text10: 'Best service',
        text11: 'To provide you with the best quality service',
        text12: 'Deep travel itinerary suggestions',
        text13: 'Free in-depth travel advice for you',
        text14: 'Airport transfer service',
        text15: 'Airport transfer service is available for you',
        text16: 'Delivery service',
        text17: 'For your delivery service'
    },
    book: {
        title: 'Reserved vehicle',
        text1: 'Please enter a keyword search',
        text2: 'Search',
        text3: 'All models',
        text4: 'All brands',
        text5: 'Number of passengers',
        text6: 'Rented',
        text7: 'Times',
        text8: 'Reviews',
        text9: 'Earliest available date',
        text10: 'User Reviews',
        text11: 'Sort by',
        text12: 'Price'
    },
    booking: {
        title: 'Book now',
        text1: 'Name of reservation',
        text2: 'Please enter the reservation name',
        text3: 'Phone number',
        text4: 'Select area code',
        text5: 'Please enter your phone number',
        text5_1: 'Please enter the correct phone number',
        text6: 'Date of rental',
        text7: 'Please select a rental date',
        text6_1: 'Rental time',
        text7_1: 'Please select the rental time',
        text8: 'Return date',
        text9: 'Please select a return date',
        text8_1: 'Return time',
        text9_1: 'Please select a return time',
        text10: 'Car rental location',
        text11: 'Please select a rental location',
        text12: 'Drop-off point',
        text13: 'Please select a return location',
        text14: 'Passport',
        text15: 'No parenthesis',
        text16: 'Please enter your passport number',
        text17: 'Date of birth',
        text18: 'Please select a date of birth',
        text19: 'Child seat',
        text20: 'By law, children under the age of 6 must use a child safety seat or a baby safety seat.',
        text21: 'ETC',
        text21_1: 'Please select ETC',
        text22: 'No need for ETC',
        text23: 'Need ETC',
        text24: 'Some stores do not offer ETC devices and cards',
        text25: 'Insurance compensation system',
        text25_1: 'Please select an insurance reimbursement system',
        text26: 'Double protection for rental cars',
        text27: 'Indemnity only',
        text28: 'Out of it',
        text29: 'Information about the liability compensation system. At the scheduled detailed briefing, the exemption items for liability and compensation for Business Loss (NOC) are listed separately',
        text30: 'Driver\'s license type',
        text31: 'Please select the type of driver\'s license you hold below.。',
        text32: '1.International Driving licence (issued within the past year) in accordance with the Geneva Convention',
        text33: '2.Hold a driver\'s license issued by Switzerland, Germany, France, Belgium, Taiwan, Monaco, etc., and a Japanese translation of the license',
        text34: '3.Driver\'s license issued in Japan',
        text35: 'The validity period of the driver\'s license 1 and 2 above is 1 year from the customer\'s arrival in Japan, or the validity period of the driver\'s license, whichever is earlier. (If a Japanese resident registration ticket holder goes abroad from Japan after being confirmed to go abroad or has obtained a re-entry permit, and re-enters Japan within 3 months, the date of his return (entry) will not be regarded as the start date of driving in Japan.) We will confirm the date of entry through the entry stamp on the passport. If you enter the country through the automated clearance gates, you cannot provide car rental service without an entry stamp on your passport.',
        text36: 'Please select',
        title2: 'Flight information',
        text37: 'Arrive',
        text38: 'Please enter the airline company',
        text39: 'Please enter the flight number',
        text40: 'Return trip',
        text41: 'coupon',
        text42: 'Enter the coupon code to deduct the order amount',
        text43: 'Price breakdown',
        text44: 'Total price',
        text45: 'Coupon deduction',
        text46: 'Total',
        text47: 'Options are free',
        text48: 'Click here'
    },
    orderDetail: {
        title1: 'Order progress',
        title2: 'Payment method',
        title3: 'Order information',
        title4: 'Rental car list',
        text1: 'To be paid',
        text2: 'The order has been submitted. Please pay as soon as possible',
        text3: 'Store confirmation',
        text4: 'Vehicle to be assigned',
        text5: 'Vehicle waiting for collection',
        text6: 'In the car',
        text7: 'Order fulfillment',
        text8: 'Bank card payment',
        text9: 'WechatPay',
        text10: 'AliPay',
        text11: 'Order number',
        text12: 'Order time',
        text13: 'Name of reservation',
        text14: 'Phone number',
        text15: 'Passport',
        text16: 'Car rental agency',
        text17: 'Drop-off point',
        text18: 'Immediate payment',
        text19: 'Evaluate',
        text20: 'Price Details',
        text21: 'Total Price',
        text22: 'lump sum'
    },
    orderComment: {
        text1: 'Please enter the evaluation content here, your recognition is our biggest support!',
        text2: 'comment'
    },
    footer: {
        text1: 'Contact Us',
        text2: 'Telephone',
        text3: 'Email',
        text4: 'Address',
        text5: 'About Us',
        text6: 'Company Profile',
        text7: 'Customer Reviews',
        text8: 'Social Media',
        text9: 'Book a vehicle',
        text10: 'Book Now',
        text11: 'Contact Us',
        text12: 'Question Feedback',
        text13: 'Order History',
        text14: 'My itinerary',
        text15: 'Past trips',
        text16: '3-192-6, Okubobei, Kumatsumi, Izumi, Prefecture, Osaka',
        text17: 'Company Name',
        text18: 'Baoyuan Trading Co., LTD',
        text19: 'Customer'
    },
    pigination: {
        text1: 'Total',
        text2: 'Records',
        text3: 'No.',
        text4: 'Page',
        text5: 'Previous',
        text6: 'Next',
        text7: 'Jump'
    },
    msg: {
        text1: 'Login successful',
        text2: 'Successful registration',
        text3: 'Password changed successfully',
        text4: 'Comment Success',
        text5: 'Congratulations, your order payment is successful!',
        text6: 'Thank you for your payment!',
        text7: 'Payment failed, user canceled payment',
        text8: 'Payment canceled',
        text9: 'View Order',
        text10: 'Some information is missing, please check'
    },
    errorCode: {
        10001: 'System abnormality',
        10002: 'Data does not exist',
        10003: 'Only supports images（png、png、jpeg）',
        10004: 'Exception in getting verification code',
        10005: 'Verification code error',
        10006: 'Wrong password',
        10007: 'Account information does not exist',
        10008: 'Parameter abnormality',
        10009: 'Order information does not exist',
        10010: 'The current order is not completed, so you cannot comment.',
        10011: 'The original password is incorrect',
        10012: 'Vehicle does not exist',
        10013: 'Lack of vehicles',
        10014: 'The current order cannot be deleted',
        10015: 'The current order cannot be cancelled',
        10016: 'Account already registered',
        10017: 'The rental start time is abnormal, and there is no car available at the current time',
        511: 'Your login has expired, please log in again',
        402: 'Your login has expired, please log in again'
    },
    enum: {
        text1: 'Vehicle Price',
        text2: 'Child seats',
        text3: 'ETC',
        text4: 'Insurance',
        text5: 'Not paid',
        text6: 'Paid',
        text7: 'Refunded',
        text8: 'Cancelled',
        text9: 'Waiting for pick up',
        text10: 'Picked up the car',
        text11: 'Completed'
    },
    address: {
        text1: 'Kansai Airport'
    },
    //驾照单页
    driving: {
        title: 'About driver\'s license',
        desc: 'We will introduce the driver\'s license and related documents that are necessary for driving in Japan. <br/>In recent years, the phenomenon of ' +
            'forging international driver\'s licenses for car rental has become more and more common, and it has become a serious problem. If the customer\'s ' +
            'passport and the country of issue of the international driver\'s license are inconsistent, the staff may confirm the entry and exit records on the ' +
            'passport. If there is no entry and exit record of the country of issue of the international driver\'s license on the passport, you will need to show ' +
            'proof of residence in the issuing country, so please remember to bring it. <br/>Example: ID, driver\'s license, residence permit, etc. from the ' +
            'country of issue of the international driver\'s license<br/>If it cannot be confirmed that the international driver\'s license was obtained by ' +
            'the customer from an institution recognized by the issuing country, and the validity of the international driver\'s license held by the customer ' +
            'cannot be proved, our company will refuse to provide car rental services. <br/>*When renting the W6 model, a valid driver\'s license that can ' +
            'drive a 10-passenger car is required.',
        detail: [
            {
                title: 'Customers with an international driving license',
                desc: 'You must have a passport and a valid international driver\'s license. <br/>International driver\'s licenses must comply with the Geneva Convention on International Road Traffic of 1949. <br/>※International driver\'s licenses are valid for 1 year after issuance, and are valid for driving in Japan for 1 year from the date of entry (confirmed by the year, month and day of entry into Japan stamped on your passport). <br/>※If you use the "automatic gate" to pass through customs, please obtain an entry stamp (or landing permission sticker) from the staff after passing through customs. If you do not have the stamp (or landing permission sticker), you will not be able to rent a car because your entry date/re-landing date cannot be confirmed. <br/>※If a person who is recorded in the Japanese Resident Register or has registered as an alien re-enters Japan within 3 months (not total, but consecutive) after leaving Japan, the date of re-entry into Japan will not be considered as the "landing date" (the "landing date" is used to calculate the validity period of the international driver\'s license), and we will not be able to rent a car to you because you do not meet the regulations for driving in Japan.',
                text: 'International Driving License',
                text1: 'A valid passport',
            },
            {
                title: 'Customers with a driver\'s license from France, Germany, Switzerland, Monaco, Belgium, Taiwan',
                desc: 'According to Japanese traffic regulations, you need to bring the following documents when driving. Please show them to our staff at the departure store. <br/>※ If you use the "automatic gate" to pass through customs, please obtain an entry stamp (or landing permission sticker) from the staff after passing through customs. If you do not have the stamp (or landing permission sticker), you will not be able to rent a car because your entry date/re-landing date cannot be confirmed. <br/>※ If a person who holds a designated foreign driver\'s license and is recorded in the Japanese resident register or has registered as an alien re-enters Japan within 3 months (not total, but consecutive) after leaving Japan, the date of re-entry into Japan will not be considered as the "landing date" (the "landing date" is used to calculate the validity period that the foreign driver\'s license can be used), and we will not be able to rent a car to you because you do not meet the regulations for driving in Japan.',
                text: 'Valid driving license from your home country',
                text1: 'A valid passport',
                text2: 'Official translation documents'
            }
        ],
        //翻译文书
        translate: {
            title: 'How to obtain official translation documents',
            desc: 'Please obtain the official Japanese translation through the following agencies。',
            detail: '・The issuing organization of the driver\'s license or the embassy or consulate of the corresponding country<br/>・Japan Automobile Federation (JAF)<br/>・German Automobile Association (ADAC) ※Only German driver\'s license<br/>・Taiwan Japan Relations Association ※Only Taiwanese driver\'s license<br/>・ZIPLUS Co., Ltd. ※Only Taiwanese driver\'s license<br/>'

        }
    },
    order: {
        // text1: '租车时间',
    },
    ...en
}