<template>
  <v-footer color="#fff" class="footer-container">
    <v-row>
      <v-col lg="4" sm="6" cols="12">
        <p class="name">
          {{ $vuetify.lang.t("$vuetify.footer.text1") }}
          <v-btn class="register d-md-none" v-if="!user.userId" @click="toUrl('/register')">{{
            $vuetify.lang.t("$vuetify.register")
            }}</v-btn>
        </p>
        <v-row>
          <v-col cols="6" class="pl-0">
            <p>utor {{ $vuetify.lang.t("$vuetify.footer.text19") }}1</p>
            <p>{{ $vuetify.lang.t("$vuetify.footer.text2") }}： 090-4923-1666</p>
            <div class="d-flex">
              <div class="mr-3">
                <v-img contain src="../../assets/utor1_whatsApp.jpg" class="qrcode"></v-img>
                <div class="font-weight-bold text-center">WhatsApp</div>
              </div>
              <div>
                <v-img contain src="../../assets/utor1_line.jpg" class="qrcode"></v-img>
                <div class="font-weight-bold text-center">Line</div>
              </div>
            </div>
          </v-col>
          <v-col cols="6" class="pr-0">
            <p>utor {{ $vuetify.lang.t("$vuetify.footer.text19") }}2</p>
            <p>{{ $vuetify.lang.t("$vuetify.footer.text2") }}：090-8895-3999</p>
            <div class="d-flex">
              <div class="mr-3">
                <v-img class="qrcode" contain src="../../assets/utor2_whatsApp.jpg"></v-img>
                <div class="font-weight-bold text-center">WhatsApp</div>
              </div>
              <div>
                <v-img class="qrcode" contain src="../../assets/utor2_line.jpg"></v-img>
                <div class="font-weight-bold text-center">Line</div>
              </div>
            </div>
          </v-col>
        </v-row>

        <p>{{ $vuetify.lang.t("$vuetify.footer.text3") }}：utorjapan@gmail.com</p>
        <p>
          {{ $vuetify.lang.t("$vuetify.footer.text17") }}：{{
            $vuetify.lang.t("$vuetify.footer.text18")
          }}
        </p>
        <p>
          {{ $vuetify.lang.t("$vuetify.footer.text4") }}：{{
            $vuetify.lang.t("$vuetify.footer.text16")
          }}
        </p>
        <p>
          {{ $vuetify.lang.t("$vuetify.footer.text2") }}：072-477-2459
        </p>
      </v-col>
      <v-col lg="1" sm="3" cols="4">
        <p class="name">{{ $vuetify.lang.t("$vuetify.footer.text5") }}</p>
        <p>
          <span @click="toUrl('/about')">{{
            $vuetify.lang.t("$vuetify.footer.text6")
            }}</span>
        </p>
        <p>
          <span @click="toUrl('/about#comment')">{{
            $vuetify.lang.t("$vuetify.footer.text7")
            }}</span>
        </p>
        <p>
          <span @click="toUrl('/about#meida')">{{
            $vuetify.lang.t("$vuetify.footer.text8")
            }}</span>
        </p>
      </v-col>
      <v-col lg="1" sm="2" cols="4">
        <p class="name">{{ $vuetify.lang.t("$vuetify.footer.text9") }}</p>
        <p>
          <span @click="toUrl('/book')">{{
            $vuetify.lang.t("$vuetify.footer.text10")
            }}</span>
        </p>
      </v-col>
      <v-col lg="1" sm="3" cols="4">
        <p class="name">{{ $vuetify.lang.t("$vuetify.footer.text11") }}</p>
        <p>
          <span @click="toUrl('/contact')">{{
            $vuetify.lang.t("$vuetify.footer.text12")
            }}</span>
        </p>
      </v-col>
      <v-col lg="1" sm="2" cols="7">
        <p class="name">{{ $vuetify.lang.t("$vuetify.footer.text13") }}</p>
        <p>
          <span @click="toUrl('/order')">{{
            $vuetify.lang.t("$vuetify.footer.text14")
            }}</span>
        </p>
        <!-- <p><a href="/#/order">{{ $vuetify.lang.t("$vuetify.footer.text15") }}</a></p> -->
      </v-col>
      <!-- <v-col lg="1" sm="4" cols="5">
                二维码
            </v-col> -->
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: "AppFooter",
  data() {
    return {
      user: localStorage.getItem("userInfo")
        ? JSON.parse(localStorage.getItem("userInfo"))
        : {},
    };
  },
  methods: {
    toUrl(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.footer-container {
  font-size: 14px;

  // padding: 0;
  .row {
    // max-width: 1450px;
    margin: 0 auto;
    justify-content: space-between;
  }

  .name {
    font-weight: 600;
  }

  span {
    color: #000;
    cursor: pointer;
  }

  .qrcode {
    width: 80px;
  }
}

@media (max-width: 960px) {
  .footer-container {
    .qrcode {
      width: 70px;
    }

    .register {
      background: linear-gradient(161deg, #ffed00 0%, #fed600 100%);
      box-shadow: 0px 0px 6px 0px rgba(254, 214, 0, 0.5);
      height: 30px;
      font-size: 14px;
      float: right;
    }
  }
}
</style>
