<template>
  <div id="register">
    <v-container class="d-flex justify-end pa-md-10">
      <v-card width="500px" class="register-card">
        <v-tabs v-model="tab" height="65px" background-color="transparent" grow>
          <v-tab v-for="item in items" :key="item.title" class="text-18">
            {{ $vuetify.lang.t(item.title) }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-form ref="registrationForm">
            <v-tab-item v-for="(item, index) in items" :key="item.title">
              <v-card color="basil" flat>
                <v-card-text>
                  <v-text-field class="text-md-16 text-14" v-if="item.formType === 1" color="#ccc" height="40px"
                    :placeholder="$vuetify.lang.t('$vuetify.user.text10')" :rules="rules.email"
                    v-model="item.formData.account">
                    <img slot="prepend-inner" class="mr-4" width="23px" src="../../assets/user/icon_email.png" alt="" />
                  </v-text-field>
                  <v-autocomplete class="text-md-16 text-14" v-if="item.formType === 2"
                    :placeholder="$vuetify.lang.t('$vuetify.user.text13')" :rules="rules.areaCode"
                    :items="$enum.AREACODE" item-text="name" item-value="value" v-model="item.formData.select"
                    clearable>
                  </v-autocomplete>
                  <!-- <v-select
                      class="text-md-16 text-14"
                      v-if="item.formType === 2"
                      :placeholder="$vuetify.lang.t('$vuetify.user.text13')"
                      :rules="rules.areaCode"
                      :items="$enum.AREACODE"
                      item-text="name"
                      item-value="value"
                      v-model="item.formData.select"
                      searchable
                      clearable
                  ></v-select> -->
                  <v-text-field class="text-md-16 text-14" v-if="item.formType === 2" color="#ccc" height="40px"
                    :rules="rules.phone" :placeholder="$vuetify.lang.t('$vuetify.user.text7')"
                    v-model="item.formData.account">
                    <img slot="prepend-inner" class="mr-4" width="20px" src="../../assets/user/icon_phone.png" alt="" />
                  </v-text-field>
                  <v-text-field class="text-md-16 text-14" color="#ccc" height="40px"
                    :placeholder="$vuetify.lang.t('$vuetify.user.text8')" :rules="rules.code"
                    v-model="item.formData.code">
                    <img slot="prepend-inner" class="mr-4" width="20px" src="../../assets/user/icon_code.png" alt="" />
                    <v-btn slot="append" class="code" color="#FED600" @click="handleClick(index)">{{
                      $vuetify.lang.t("$vuetify.user.text11") }}
                    </v-btn>
                  </v-text-field>
                  <v-text-field class="text-md-16 text-14" color="#ccc" height="40px"
                    :placeholder="$vuetify.lang.t('$vuetify.user.text2')" type="password" :rules="rules.password"
                    v-model="item.formData.password">
                    <img slot="prepend-inner" class="mr-4" width="20px" src="../../assets/user/icon_password.png"
                      alt="" />
                  </v-text-field>

                  <v-btn class="d-none d-md-block" depressed width="100%" height="46px" color="#FED600"
                    @click="handleSubmit(index)">{{ $vuetify.lang.t("$vuetify.register") }}
                  </v-btn>
                  <v-btn class="d-md-none text-18" depressed width="100%" height="46px" color="#FED600"
                    @click="handleSubmit(index)">{{ $vuetify.lang.t("$vuetify.register") }}
                  </v-btn>

                  <div class="text">
                    {{ $vuetify.lang.t("$vuetify.user.text30") }}
                    <a @click="toAgreement(1)">《{{ $vuetify.lang.t("$vuetify.user.text31") }}》</a>
                    和
                    <a @click="toAgreement(2)">《{{ $vuetify.lang.t("$vuetify.user.text32") }}》</a>
                  </div>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-form>
        </v-tabs-items>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { register, getCode, agreementList } from "../../api/index";

export default {
  name: "RegisterIndex",
  components: {
    // MyAgreement,
  },
  data() {
    return {
      tab: null,
      valid: true,
      items: [
        {
          title: "$vuetify.user.text33",
          formType: 1,
          formData: {
            account: "",
            password: "",
            code: "",
          },
        },
        {
          title: "$vuetify.user.text34",
          formType: 2,
          formData: {
            account: "",
            password: "",
            code: "",
            select: "",
          },
        },
      ],
      rules: {
        email: [
          (value) => !!value || this.$vuetify.lang.t("$vuetify.user.text10"),
          (value) => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || this.$vuetify.lang.t("$vuetify.user.text35");
          },
        ],
        phone: [
          (value) => !!value || this.$vuetify.lang.t("$vuetify.user.text7"),
          // (value) => !!value || this.$vuetify.lang.t("$vuetify.user.text36"),
          // (value) => {
          //   // const pattern = /^1[3456789]\d{9}$/;
          //   // return this.$vuetify.lang.t("$vuetify.user.text36");
          // },
        ],
        code: [(value) => !!value || this.$vuetify.lang.t("$vuetify.user.text8")],
        password: [(value) => !!value || this.$vuetify.lang.t("$vuetify.user.text2")],
        areaCode: [(value) => !!value || this.$vuetify.lang.t("$vuetify.user.text13")],
      },
    };
  },
  watch: {
    "$vuetify.lang.current": {
      handler() {
        this.$router.go(0);
      },
    },
  },
  methods: {
    async toAgreement(type) {
      let res = await agreementList({
        type: type,
      });
      if (res.code == 200) {
        let data = res.data.list[0];
        data.title =
          type == 1
            ? this.$vuetify.lang.t("$vuetify.user.text31")
            : this.$vuetify.lang.t("$vuetify.user.text32");
        localStorage.setItem("agreement", JSON.stringify(data));
        window.open("/agreement.html", "_blank");
      }
    },
    async handleSubmit(index) {
      // if (!this.$refs.registrationForm.validate()) {
      //   return;
      // }

      let params = {
        type: index === 0 ? 1 : 2, // 1:邮箱注册 2:手机注册
        account: this.items[index].formData.account,
        password: this.items[index].formData.password,
        code: this.items[index].formData.code,
      };
      if (index == 1) {
        params.areaCode = String(this.items[index].formData.select);
        if (params.areaCode == "") {
          this.$SnackBar.show(this.$vuetify.lang.t("$vuetify.user.text13"), {
            type: "error",
          });
          return;
        }
      }
      if (params.account == "") {
        this.$SnackBar.show(
          this.$vuetify.lang.t(
            index == 0 ? "$vuetify.user.text10" : "$vuetify.user.text7"
          ),
          {
            type: "error",
          }
        );
        return;
      }
      if (params.code == "") {
        this.$SnackBar.show(this.$vuetify.lang.t("$vuetify.user.text8"), {
          type: "error",
        });
        return;
      }
      if (params.password == "") {
        this.$SnackBar.show(this.$vuetify.lang.t("$vuetify.user.text2"), {
          type: "error",
        });
        return;
      }

      let res = await register(params);
      if (res.code == 200) {
        console.log(res);
        this.$SnackBar.show(this.$vuetify.lang.t("$vuetify.msg.text2"), {
          type: "success",
        });
        this.$router.push({ path: "/login" });
      }
    },
    async handleClick(index) {
      console.log("click");
      let account = this.items[index].formData.account;
      let areaCode = this.items[index].formData.select;
      let params = {
        type: index === 0 ? 1 : 2,
        action: 1,
      };
      if (index == 1) {
        if (areaCode == "") {
          this.$SnackBar.show(this.$vuetify.lang.t("$vuetify.user.text13"), {
            type: "error",
          });
          return;
        } else {
          params.areaCode = areaCode + "";
        }
      }
      if (account == "") {
        this.$SnackBar.show(
          index == 0
            ? this.$vuetify.lang.t("$vuetify.user.text10")
            : this.$vuetify.lang.t("$vuetify.user.text7"),
          {
            type: "error",
          }
        );
        return;
      } else {
        if (index == 0) {
          if (
            !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              account
            )
          ) {
            this.$SnackBar.show(this.$vuetify.lang.t("$vuetify.user.text35"), {
              type: "error",
            });
            return;
          }
        }
        params.account = account;
      }

      let res = await getCode(params);
      if (res.code == 200) {
        console.log(res);
        this.$SnackBar.show(this.$vuetify.lang.t("$vuetify.user.text37"), {
          type: "success",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#register {
  background: url(../../assets/user/img.png) no-repeat;

  .v-tab {
    font-size: 1.5rem;
    border-bottom: 1px solid #ccc;
  }

  .v-card__text {
    padding: 20px 40px;
  }

  .v-input {
    font-size: 1.25rem;
  }

  .text {
    width: 60%;
    margin: 10px auto 0;
    font-size: 0.875rem;
    text-align: center;

    a {
      color: rgba(0, 0, 0, 0.5);
      text-decoration: underline;
    }
  }

  .code {
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.5);
  }
}

@media (max-width: 960px) {
  #register {
    background-size: 100%;

    .register-card {
      width: 100%;
      margin-top: 80px;
    }

    .v-card__text {
      padding: 20px 20px 40px;
    }

    .text {
      font-size: 0.75rem;
    }

    .code {
      font-size: 0.875rem;
    }
  }
}
</style>
