import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import zhHans from '@/locale/zh-Hans' // 导入简体中文语言
import en from '@/locale/en' // 导入English语言
import ja from '@/locale/ja' // 导入日语语言
import ko from '@/locale/ko' // 导入韩语语言

Vue.use(Vuetify)

const opts = {
    theme: {
        themes: {
            light: {
                primary: '#FED600', // 修改默认的主色
                // secondary: '#b0bec5', // 修改默认的辅助色
                // accent: '#8c9eff', // 修改默认的强调色
                // error: '#ff5252', // 修改默认的错误色
                // info: '#2196f3', // 修改默认的信息色
                // success: '#4caf50', // 修改默认的成功色
                // warning: '#ff9800' // 修改默认的警告色
            },
            options: {
                customProperties: true,
            },
        },
    },
    lang: {
        locales: { zhHans, en, ja, ko },
        current: 'zhHans',
    }
}


export default new Vuetify(opts)
