<template>
    <div class="comment-start d-flex align-center">
        <div v-for="item in score" :key="item" class="comment-start-item">
            <v-img src="../../assets/icon_star.png" :width="isMd ? mdw : width"></v-img>
        </div>
        <div :class="['text-md-20','text-' + textSize, 'comment-start-score', 'pl-2']" v-if="isText">{{ score }}分</div>
    </div>
</template>

<script>
export default {
    name: "CommentStart",
    props: {
        score: Number,
        isText: Boolean,
        width: {
            type: Number,
            default: function() {
                return 22
            }
        },
        mdw: {
            type: Number,
            default: function() {
                return 32
            }
        },
        textSize: {
            type: Number,
            default: function() {
                return 12
            }
        }
    },
    data() {
        return {
            isMd: false
        }
    },
    created() {
        console.log(this.$vuetify.breakpoint.mdAndUp)
        this.isMd = this.$vuetify.breakpoint.mdAndUp
    }
}
</script>

<style lang="scss" scoped>
.comment-start {
    .comment-start-score {
        color: #FEBC37;
    }
}
</style>