<!-- Loading.vue -->
<template>
  <div class="loading" v-if="visible">
    <div class="spinner"></div>
    <!-- <div class="text">{{ message }}</div> -->
  </div>
</template>

<script>
export default {
  name: 'LoadingIndex',
  data() {
    return {
      visible: false,
      message: ''
    };
  },
  methods: {
    show() {
      this.visible = true;
    },
    hide() {
      this.visible = false;
    }
  }
};
</script>

<style scoped>
.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0, 0.2);
  z-index: 9999;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top-color: #007bff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.text {
  margin-top: 10px;
  font-size: 16px;
  color: #007bff;
}
</style>