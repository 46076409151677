import ja from 'vuetify/es5/locale/ja' // 导入Vuetify 自带的简体中文语言包

export default {
    label: '日本語',
    // page login
    login: 'ログイン',
    register: '登録する',
    username: 'ユーザー名',
    password: 'パスワード',
    login_account: 'ログインアカウント',
    submit: '提出する',
    logout: 'ログインします',
    none: 'データなし',
    seat: '席',
    day: '日',
    cancel: 'キャンセル',
    del: '消去',
    bookText: '予約する',
    week: '週',
    month: '月',
    year: '年',
    cDay:'日',
    // menu
    menu: {
        home: 'ホーム',
        free: 'レンタカーを自由に',
        about: '私たちについて',
        book: '予約する',
        contact: '連絡先',
        answer: 'ご質問',
        order: 'オーダー',
        personal: '個人情報',
        account: 'アカウントセキュリティ',
        payRecord: '支払い記録',
        charter: '貸切ツアー',
        consult: 'ご相談ください'
    },
    charter: {
        text1: 'トヨタ アルファ',
        text2: 'トヨタハイエース',
        text3: '※空港送迎、テーマパーク送迎等は片道料金です。',
        text4: '大阪転送',
        text5: '京都送迎',
        text6: 'ユニバーサル・スタジオ大阪',
        text7: '大阪（市内往復）',
        text8: '京都（市内往復）',
        text9: '大阪・奈良・京都（同日往復）',
        text10: '全て込みの価格/中国人ドライバー/空港送迎可能<br>※その他のモデルをご希望の場合は、閑散期・繁忙期やご予約状況により若干価格が変動する場合がございますのでご相談ください。 2回確認する必要があります。',
        text11: '関西エリア',
        text12: '清松 ハイヤーへお問い合わせ',
        alphard: '最大乗車人数は6名',
        hiace: '最大乗車人数は9名'
    },
    consult: {
        text1: '要件を選択してください:',
        text2: '【レンタカーのお問い合わせ】',
        text3: '【レンタルサービスに関するお問い合わせ】',
        text4: '氏名',
        text5: 'フリガナ',
        text6: '問い合わせ者の所在地を選択してください',
        text7: '日本に住む',
        text8: '日本国外在住',
        text9: '両方（複数人で相談する場合）',
        text10: '郵便番号',
        text11: 'ハイフンなしで入力してください',
        text12: '点击此处进行邮政编码搜索',
        text13: '場所',
        text14: '電子メール',
        text15:'電話番号',
        text16: 'レンタカーをご利用の場合は、ご希望の車種とご利用期間をご記入ください。',
        text17: 'レンタカーをご希望の場合は下記より車種をお選びください。',
        text18: '軽車両',
        text19: 'ハイブリッド電気自動車',
        text20: 'バン/ミニバン',
        text21: 'コンパクトカー',
        text22: '軽トラック・トラック',
        text23: 'その他',
        text24: 'その他のモデル',
        text25: '他のモデル以上をご希望の場合は下記にご記入ください。',
        text26: 'レンタカーのアドオンを選択してください：',
        text27: 'ETC車',
        text28: 'カーナビゲーション',
        text29: 'ドライブレコーダー',
        text30: 'カートランスポーター',
        text31: '4WD四輪駆動（小型商用車およびライトバンのみ）',
        text32: 'スタッドレスタイヤ',
        text33: 'LINE or Wechat IDなどご連絡先',
        text34: '希望開始時間',
        text35: 'ご希望の開始日を選択してください',
        text36: '予想される期間',
        text37: '数字を入力してください',
        text38: '希望の継続時間単位',
        text39: 'ドライバーの数：',
        text40: '人々',
        text41: 'その上',
        text42: '（レンタルサービスのみ）',
        text43: '必要な車両の台数：',
        text44: '車',
        text45: '以上',
        text46: 'ご質問、ご意見など',
        text47: '送信',
        text48: '電子メールでお問い合わせください'
    },
    user: {
        text1: 'メールボックス/携帯電話番号を入力してください',
        text2: 'パスワードを入力してください',
        text3: 'パスワードを忘れてください',
        text4: '今すぐ登録してください',
        text5: 'メールボックスを取得します',
        text6: '回復',
        text7: '携帯電話番号を入力してください',
        text8: '確認コードを入力してください',
        text9: 'もう一度パスワードを入力してください',
        text10: 'メールボックスを入力してください',
        text11: '確認コードを取得します',
        text12: 'パスワードを変更する',
        text13: '市外局番を選択してください',
        text14: 'ニックネーム',
        text15: 'ニックネームを入力してください',
        text16: 'アバター',
        text17: '保存',
        text18: '郵便',
        text19: '携帯電話',
        text20: '成功してください',
        text21: '著名なメンバー',
        text22: 'パスワードを変更します',
        text23: '現在のパスワード',
        text24: '新しいパスワード',
        text25: '新しいパスワードを確認します',
        text26: '現在のパスワードを入力してください',
        text27: '新しいパスワードを入力してください',
        text28: 'もう一度新しいパスワードを入力してください',
        text29: '2つの入力パスワードは一貫性がありません',
        text30: 'クリックしてログインまたは登録し、読んだか同意したことを示します',
        text31: 'Utorレンタカー契約書',
        text32: 'プライバシーポリシー',
        text33: 'メール登録',
        text34: '携帯電話の登録',
        text35: '正しいメールアドレスを入力してください',
        text36: '正しい携帯電話番号を入力してください',
        text37: '認証コードが送信されました'
    },
    home: {
        text1: '今すぐ確認してください',
        title1: 'レンタカープロセス',
        title2: 'ホット車',
        title3: '多言語カスタマーサポート',
        text2: '車のニーズを簡単に取得するには3つのステップしかかかりません',
        text3: '車両を選択してください',
        text4: '車両モデルを選択した後、必要です',
        text5: '情報を入力します',
        text6: '車の時間を選択して、個人情報を入力します',
        text7: '空港車',
        text8: '埋め込まれた車に応じて、指定された場所に車に乗って車を拾うために車の情報を取る',
        text9: '詳細をご覧ください',
        text10: '多言語サービスを提供します'
    },
    about: {
        title: '私たちについて',
        content: 'これが私たちのページのコンテンツです',
        text1: 'UTORを選ぶ理由',
        text2: 'UTORを選ぶ理由',
        text3: 'お客様の声',
        text4: 'ソーシャルメディア',
        text5: 'ライフスタイルの変化に伴い、人々のカーライフに対する要望はますます多様化しています。宇土レンタカーは、お客様に清潔・便利・安全・丁寧なレンタカーサービスの提供を心がけております。また、お客様の視点に立った考え方にも留意し、より多くのお客様に安心で快適なレンタカーサービスを提供できるよう今後も努力してまいります。\n' +
            'ご旅行の際の車選びのご相談がございましたら、お気軽にお問い合わせください。',
        text6: '豊富な車種選択',
        text7: 'お一人様でも、ご家族やお友達とのご旅行でも、お客様にぴったりの車両をご用意しております。手頃な価格の小型車から広々とした快適な高級 MPV まで、当社の車両はあら​​ゆるタイプをカバーしており、お客様の旅で最高の運転体験をお約束します。',
        text8: '家族向けの便利なサービス',
        text9: 'お子様の安全と快適性を確保するために、家族旅行向けに特別に用意されたチャイルドシートをご用意しています。また、全車両にETCを搭載しており、日本の高速道路の料金所を素早く通過できるため、列に並ぶ手間が省け、貴重な移動時間を節約できます。',
        text10: '透明性のある価格設定メカニズム',
        text11: '青松でレンタカーを借りるとき、隠れた料金を心配する必要はありません。ご予約時にすべての費用が明確になる透明性の高い料金体系をご用意しておりますので、余分な出費を気にすることなくご旅行をお楽しみいただけます。',
        text12: '24時間年中無休の多言語カスタマーサービスサポート',
        text13: '私たちは異国情緒あふれる場所を旅行する際のコミュニケーションの重要性を理解しています。そのため、当社のカスタマー サービス チームは 24 時間多言語サポートを提供しており、旅行中にいつ問題が発生しても、タイムリーなサポートを提供できます。',
        text14: '関西空港限定サービス',
        text15: 'ご旅行をより便利にご利用いただくために、関西国際空港での専用車の送迎サービスをご提供しております。日本に到着したばかりの方も、出国準備中の方も、空港でレンタカーのレンタルや返却の手続きが簡単に完了して、安心な旅の始まりや終わりを迎えられます。',
        text16: '総合的なセキュリティ',
        text17: '安全は私たちの最優先事項です。すべての車両はレンタル前に厳格な安全検査を受け、充実した保険が装備されているため、日本国内をどのキロメートルでもより安心して運転できます。',
        text18: '清松レンタカーは、日本を訪れるすべての旅行者に一流のレンタカーサービスを提供し、快適なだけでなく安心な旅をお約束します。私たちが提供するのは単なる車ではなく、お客様に合わせた旅行ソリューションの完全なセットです。 Qingsong Car Rentalを選ぶ6つの理由:',
        text19: 'Qingsong Car Rentalはあなたの旅行パートナーであるだけでなく、日本での親切なガイドでもあります。あなたの日本旅行をより思い出に残る楽しいものにするために、私たちを選んでください。皆様のご利用を心よりお待ちしております。楽しいご旅行をお祈りしております。'
    },
    contact: {
        title: 'お問い合わせ',
        text1: 'お問い合わせ',
        text2: '心からあなたに仕えます',
        text3: 'フィードバック',
        text4: 'あなたの名前',
        text5: 'あなたのメールボックス',
        text6: 'コンテンツ',
        text7: '名前を入力してください',
        text8: 'メールボックスを入力してください',
        text9: 'フィードバックするコンテンツを入力してください',
    },
    answer: {
        title: 'よくある質問の答え',
        text1: '日本で運転するにはどのような運転免許証が必要ですか?',
        text2: 'ジュネーブ条約で指定された形式の有効な国際運転免許証が必要です。<a href="/#/license">詳細を確認する</a><div style="text-indent: 1.5em;"> ※スイス、ドイツ、フランス、ベルギー、台湾、モナコの運転免許証をお持ちの方は、日本自動車連盟または大使館発行の正式な日本語翻訳文の提示が必要です。</div>',
        text3: '申請者以外の人が運転することはできますか？',
        text4: 'できる。ただし、レンタカードライバー全員の申し込みが必要となりますので、出発当日店頭にてドライバー全員の運転免許証をご提示ください。',
        text5: '予約せずにレンタカーを借りることはできますか?',
        text6: '店舗に空きがあれば即日レンタルも可能です。ただし、予約がいっぱいでご利用できない場合もございますので、事前のご予約をお勧めいたします。',
        text7: '予約をキャンセルする場合、キャンセル料を支払う必要がありますか?',
        text8: 'お客様のご都合によりご予約をキャンセルされる場合は、当社規定に基づき予約取消料（キャンセル料）を申し受けます。 7日前までにキャンセルすればキャンセル料はかかりません。出発予定日から6日以内にキャンセルされた場合、キャンセル料が発生いたします。',
        text9: '違法駐車した場合はどうすればよいですか？',
        text10: '切符を切られた場合は、所轄の警察署で手続きをし、罰金を支払ってから戻ってください。返却の際は「交通違反通知書」と支払日が記載された「納付書・領収書等」をご提示ください。返却時に「交通違反通知書」「納品書・受領証明書等」の提示がない場合は、放置違反金3万円をお支払いいただきます。 <div style="text-indent: 1.5em;">※後日反則金を納付の上、「交通規則違反通知書」と納付日が記載された「納付書、領収書等」を提出してください。当社では、違法駐車料金相当額を返金させていただきます。返金は指定口座に送金させていただきます。 （送金手数料はお客様のご負担となります。）</div><div style="text-indent: 1.5em;">※デポジットの支払い命令があった場合、またはお客様がデポジットを支払わない場合には、当社がデポジットを支払います。当社が指定する期日までに全額をお支払いください。 なお、所定の料金をお支払いいただく際には、全国レンタカー協会の情報管理システムへの登録等の措置が講じられます。協会のレンタカー代理店は、将来、顧客のレンタカーのリクエストを拒否する可能性があります。 </div>',
        text11: '出発時に必要な手続きは何ですか？',
        text12: '出国手続きの際、同社はドライバー全員の運転免許証やパスポートを確認し、顧客に利用規則の確認を求める。手続き完了後、契約書に基づき予約金をお支払いいただきます。 （出発時に精算されます。） <div style="text-indent: 1.5em;">※事前に料金が精算されている場合は、この手順は無視して大丈夫です。車の状態を簡単に説明した後、店員がお客様と一緒に車の表面の傷などを目視検査します。この検査が完了すると、車両を使用することができます。',
        text13: '営業時間外に車の引き取り・返却はできますか？',
        text14: '出発・返却手続きは当社営業時間内に行ってください。',
        text15: '登録会員が運転免許証を忘れた場合、レンタカーを借りることはできますか?',
        text16: 'ご出発時に運転免許証、パスポートのご提示がない場合はレンタルができませんので、当日は必ずご持参ください。',
        text17: '車を返却する際に注意すべきことは何ですか？',
        text18: 'ご返却前に忘れ物がないかご確認ください。忘れ物等の紛失につきましては責任を負いかねますのでご了承ください。',
        text19: '車を返すときにタンクを満タンにできない場合はどうすればよいですか?',
        text20: '直接当社へご返却下さい。当社は、実際の走行距離に応じて所定の代理給油料金をお客様に請求させていただきます。',
    },
    free: {
        title: 'レンタカーを自由に',
        text1: 'なぜ旅行に車を借りるのですか？',
        text2: 'あなたがやりたいことは何でも、自由に旅行してください',
        text3: 'レンタカーを自由にレンタカーし、好きなことをして、便利で快適なことをし、固定ルートと時刻表の制約を取り除き、いつでもどこでも未知の美しさを探索してください。停止したい場合は停止できます。行きたい場合は行ってください。',
        text4: '深く遊んで、目に見えない美しさを探ります',
        text5: '公共交通機関の時間制限を取り除き、いつでもどこでもあなたの目的地に訪れ、転送の待ち時間を節約してください。チャーターされたまたはタクシーと比較して、旅行コストを効果的に制御できます。',
        text6: '自由の探検、個人的な楽しみの旅',
        text7: '独立したプライベートスペースを持ち、親relativeや友人と親密な時間を共有してください。パインドライドとストレージスペースはあなたの旅をもっと快適にします。より高い自由、プライバシー、快適さを持つようにしましょう。',
        text8: '家族全員が集まって、旅を楽しんでください',
        text9: '部門全体は、豪華なインテリアのマルチメディアエンターテイメントシステムを備えた豪華な乳母車です。大きな内部空間は、あなたがより高い自由と快適さを持っている旅で、高齢者と子供たちの快適さを保証します。',
        text10: '最高のサービス',
        text11: '最高品質のサービスを提供します',
        text12: '詳細な旅行旅程の提案',
        text13: '無料の旅行の提案を無料で提供してください',
        text14: '選択 - オファーサービス',
        text15: 'シャトルサービスサービスを提供します',
        text16: '運転サービス',
        text17: '輸送サービスを提供します'
    },
    book: {
        title: 'スケジュールされた車両',
        text1: 'キーワード検索を入力してください',
        text2: '検索',
        text3: 'すべてのモデル',
        text4: 'すべてのブランド',
        text5: '乗客数',
        text6: 'レンタル済み',
        text7: '二流',
        text8: 'レビュー',
        text9: '最も早い利用可能日',
        text10: 'ユーザーレビュー',
        text11: '並べ替え',
        text12: '価格'
    },
    booking: {
        title: '今すぐ予約してください',
        text1: '予約済みの名前',
        text2: '所定の人の名前を入力してください',
        text3: '電話番号',
        text4: '市外局番を選択します',
        text5: '携帯電話番号を入力してください',
        text5_1: '正しい携帯電話番号を入力してください',
        text6: 'レンタカーの日付',
        text7: 'レンタカーの日付を選択してください',
        text6_1: 'レンタカー時間',
        text7_1: 'レンタカーの時間を選択してください',
        text8: '返品日',
        text9: '車の日付を選択してください',
        text8_1: '返品時間',
        text9_1: '車の時間を返すことを選択してください',
        text10: 'レンタカーの場所',
        text11: 'レンタカーの場所を選択してください',
        text12: '戻り場所',
        text13: '車の場所を返品することを選択してください',
        text14: 'パスポート番号',
        text15: '括弧を入力する必要はありません',
        text16: 'パスポート番号を入力してください',
        text17: '生年月日',
        text18: '生年月日を選択してください',
        text19: '子供の席',
        text20: '法律によれば、6歳未満の子供は子供の安全席または赤ちゃんの安全席を使用する必要があります。',
        text21: 'ETC',
        text21_1: 'などを選択してください',
        text22: '必要ありません',
        text23: '必要です',
        text24: '一部の店舗では、デバイスやカードを提供していません',
        text25: '保険報酬システム',
        text25_1: '保険報酬システムを選択してください',
        text26: 'レンタカーの二重保護計画',
        text27: '補償のみ',
        text28: '参加しないでください',
        text29: '免除報酬システムに関する情報。任命の詳細な相談情報では、免除報酬と事業損失補償（NOC）免除項目が各プロジェクトによってリストされています',
        text30: '運転免許証',
        text31: '保持している運転免許証の種類を選択してください。',
        text32: '1.ジュネーブ条約を満たす国際運転免許証（過去1年に発行）',
        text33: '2.スイス、ドイツ、フランス、ベルギー、台湾、モナコ、その他の国々が発行した運転免許証の開催、および運転免許証のための日本の翻訳書',
        text34: '3.日本で発行された運転免許証',
        text35: '上記の1および2の運転免許証の有効期間は、顧客が日本に到着してから1年以内、または運転免許証の有効期間の間に、以前の日付は有病率であるということです。 （日本の住民の有権者を保有している人は誰でも、国に入国するライセンスを確認または取得した後、日本で海外に行きます。3か月以内に再び日本に入ると、彼の帰国（入国）の日は運転する日として行動しません日本では、パスポートの入国章を通じて移民の日を確認します。 自動税関清算チャネルを使用して国に入ると、パスポートに入場章がありません。レンタカーサービスを提供することはできません。',
        text36: '選ぶ',
        title2: '飛行情報',
        text37: '到着',
        text38: '航空会社を入力してください',
        text39: 'フライト番号を入力してください',
        text40: '帰りの旅',
        text41: 'クーポン',
        text42: 'クーポンロールコードを入力して、注文金額を差し引きます',
        text43: '価格の詳細',
        text44: '一般価格',
        text45: 'クーポン控除',
        text46: '一時金',
        text47: '無料オプション',
        text48: 'ここをクリック'
    },
    orderDetail: {
        title1: '進捗状況を注文します',
        title2: '支払方法',
        title3: '情報を注文します',
        title4: 'レンタカーリスト',
        text1: '支払う',
        text2: '注文が提出されました、できるだけ早く支払ってください',
        text3: '確認確認',
        text4: '車両を割り当てる',
        text5: '車に乗る',
        text6: '車で',
        text7: '注文完了',
        text8: '銀行カードの支払い',
        text9: 'wechatの支払い',
        text10: 'Alipayの支払い',
        text11: '注文番号',
        text12: '注文時間',
        text13: '予約済みの名前',
        text14: '電話番号',
        text15: 'パスポート番号',
        text16: 'レンタカーポイント',
        text17: 'リターンポイント',
        text18: 'すぐに支払います',
        text19: '評価する',
        text20: '価格の詳細',
        text21: '合計金額',
        text22: '一時金'
    },
    orderComment: {
        text1: 'ここに評価コンテンツを入力してください。',
        text2: 'コメント'
    },
    footer: {
        text1: 'お問い合わせ',
        text2: '電話',
        text3: '郵便',
        text4: '住所',
        text5: '私たちについて',
        text6: '会社概要',
        text7: 'お客様の声',
        text8: 'ソーシャルメディア',
        text9: '車両を予約する',
        text10: '今すぐ予約する',
        text11: 'お問い合わせ',
        text12: '問題のフィードバック',
        text13: '注文履歴',
        text14: '私の旅程',
        text15: '過去の旅程',
        text16: '大阪府泉南郡熊取町大久保北3-192-6 ',
        text17: '会社名',
        text18: '宝源貿易株式会社',
        text19: '顧客'
    },
    pigination: {
        text1: '合計',
        text2: '記録',
        text3: 'いいえ。',
        text4: 'ページ',
        text5: '前のページへ',
        text6: '次のページ',
        text7: 'ジャンプ'
    },
    msg: {
        text1: 'ログイン成功',
        text2: '登録が成功しました',
        text3: 'パスワードが正常に変更されました',
        text4: 'コメント成功',
        text5: 'おめでとうございます。注文の支払いが正常に完了しました!',
        text6: 'お支払いありがとうございます!',
        text7: '支払いが失敗しました。ユーザーは支払いをキャンセルしました',
        text8: '支払いがキャンセルされました',
        text9: '注文を見る',
        text10: '記入されていない情報がありますのでご確認ください'
    },
    errorCode: {
        10001: 'システム例外',
        10002: 'データが存在しません',
        10003: '画像のみをサポートします（png、png、jpeg）',
        10004: '確認コード取得時の例外',
        10005: '認証コードエラー',
        10006: 'パスワードが間違っています',
        10007: 'アカウント情報が存在しません',
        10008: 'パラメータ例外',
        10009: '注文情報が存在しません',
        10010: '現在の注文は完了していないためコメントできません。',
        10011: '元のパスワードが間違っています',
        10012: '車両は存在しません',
        10013: '車両が足りない',
        10014: '現在の注文は削除できません',
        10015: '現在の注文はキャンセルできません',
        10016: 'アカウントが登録されました',
        10017: 'レンタカーの開始時間が異常であり、現在利用可能な車両がありません。',
        511: 'ログイン期限が切れています。再度ログインしてください',
        402: 'ログイン期限が切れています。再度ログインしてください'
    },
    enum: {
        text1: '車両価格',
        text2: 'チャイルドシート',
        text3: 'ETC',
        text4: '保険',
        text5: '未払い',
        text6: '支払われた',
        text7: '返金されました',
        text8: 'キャンセル',
        text9: '引き取り予定の車',
        text10: '引き取った車',
        text11: '完了'
    },
    address: {
        text1: '関西空港'
    },
    //驾照单页
    driving: {
        title: '運転免許証について',
        desc: '日本で運転するために必要な運転免許証や関連情報をご紹介します。 <br/>近年、レンタカー目的での国際運転免許証の偽造が多発しており、深刻な問題となっています。お客様のパスポートと国際運転免許証の発行国が一致しない場合、係員がパスポートの出入国記録を確認させていただく場合がございます。パスポートに国際運転免許証を発行した国の出入国記録がない場合は、国際運転免許証を発行した国の居住証明書を提示する必要がありますので、忘れずにご持参ください。 <br/>例：国際運転免許証を発行した国の身分証明書、運転免許証、滞在許可証など<br/>国際運転免許証が公認機関から取得した本人であるか確認できない場合発行国の判断、およびお客様が保有する国際運転免許証の有効性を証明できない場合には、レンタカーサービスの提供をお断りさせていただきます。 <br/>※W6モデルのレンタルには10人乗りの普通自動車運転免許が必要です。',
        detail: [
            {
                title: '国際運転免許証をお持ちのお客様',
                desc: 'パスポートと有効な国際運転免許証が必要です。 <br/>国際運転免許証は、1949 年の国際道路交通に関するジュネーブ条約に準拠する必要があります。 <br/>※国際運転免許証の有効期間は発行後1年、日本での運転有効期間は入国日（入国年月日のスタンプで確認）から1年間です。パスポートに記載されています）。 <br/>※「自動ゲート」を利用して税関を通過される場合は、税関通過後に係員から入国スタンプ（または上陸許可シール）をもらってください。スタンプ（または出国許可シール）がない場合は入国・再入国日が確認できないため、レンタカーを借りることができません。 <br/>※住民登録簿に記録されている者または外国人登録されている方が、出国後3か月以内（通算ではなく連続）に再入国した場合は、その再入国日となります。は「上陸日」とみなされず（「上陸日」は国際運転免許証の有効期間の計算に使用されます）、規定を満たさないためレンタカーを借りることができません。日本での運転。',
                text: '国際運転免許証',
                text1: '有効なパスポート',
            },
            {
                title: 'フランス、ドイツ、スイス、モナコ、ベルギー、台湾などの運転免許証をお持ちのお客様',
                desc: '日本の交通法規により、運転時には以下の情報を携帯する必要があります。出発店舗にてスタッフにご提示ください。 <br/>※「自動ゲート」を利用して税関を通過される場合は、税関通過後に係員から入国スタンプ（または上陸許可シール）をもらってください。スタンプ（または出国許可シール）がない場合は入国・再入国日が確認できないため、レンタカーを借りることができません。 <br/>※指定外国運転免許証をお持ちで、日本の住民登録簿に記載されている方、または外国人登録されている方は、出国後3か月以内（通算ではなく継続）の場合期限内に再入国した場合、再入国日は「上陸日」とはみなされません（「上陸日」は外国運転免許証の有効期間の計算に使用されます）。日本の運転法規を満たしていないため、レンタカーを借りることができない場合。',
                text: '有効な国内運転免許証',
                text1: '有効なパスポート',
                text2: '公式翻訳文書'
            }
        ],
        //翻译文书
        translate: {
            title: '公式翻訳文書の入手方法',
            desc: '公式日本語翻訳文書は以下の機関から入手できます。',
            detail: '・運転免許証の発行機関または該当国の大使館・領事館<br/>・日本自動車連盟（JAF）<br/>・ドイツ自動車協会（ADAC） ※ドイツの運転免許証のみ<br/>・台湾日本関係協会 ※台湾の運転免許証のみ<br/> ・株式会社ジプラス ※台湾の運転免許証のみ<br/>'

        }
    },
    order: {
        // text1: '租车时间',
    },
    // 展开 简体中文语言包的所有项目
    ...ja,
}
