<template>
  <v-app-bar app height="114px" color="#fff">
    <!-- <div class="d-flex">
      <div class="bar-item">首页</div>
      <div class="bar-item">关于我们</div>
    </div> -->
    <!-- <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title>Vuetify</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon>
            <v-icon>mdi-magnify</v-icon>
        </v-btn> -->
    <!-- 菜单 -->
    <div class="d-flex">
      <v-img src="../../assets/logo_home.png" contain width="200"></v-img>
      <!-- <div v-else class="text-44 font-weight-bold">Utor.</div> -->
      <v-tabs height="60px" v-model="tab" align-with-title active-class="v-tab-active" v-if="barType === 'all'">
        <v-tab v-for="(item, index) in tabs" :key="index" @click="toUrl(item.href)" :tab-value="index">
          <span v-if="user.userId || (item.name !== 'order' && !user.userId)">{{ $vuetify.lang.t(item.label) }}</span>
        </v-tab>
      </v-tabs>
    </div>

    <v-spacer></v-spacer>
    <!-- 注册 -->
    <v-btn text @click="toUrl('/register')" v-if="barType === 'all' && !user.userId">
      {{ $vuetify.lang.t("$vuetify.register") }}
    </v-btn>
    <!-- 登录 -->
    <v-btn depressed @click="toUrl('/login')" v-if="barType === 'all' && !user.userId" color="#FED600" :width="$vuetify.lang.current == 'zhHans' ? 100 : ''"
      :height="44">
      {{ $vuetify.lang.t("$vuetify.login") }}
    </v-btn>
    <!-- 首页 -->
    <v-btn text v-if="barType === 'user'" class="mr-10" @click="toUrl('/')">
      <img src="../../assets/user/icon_home.png" class="mr-1" alt="">
      {{ $vuetify.lang.t("$vuetify.menu.home") }}
    </v-btn>

    <!-- 个人中心 -->
    <v-menu v-if="barType === 'all' && user.userId" bottom min-width="230px" rounded offset-y>
      <template v-slot:activator="{ on }">
        <v-btn icon x-large v-on="on">
          <v-avatar size="46">
            <v-img :src="user.headPic || require('../../assets/user/avatar.png')" contain></v-img>
          </v-avatar>
        </v-btn>
      </template>
      <v-card>
        <v-list-item-content class="justify-center pa-5">
          <div class="mx-auto ">
            <div class="d-flex align-center" @click="toUrl('/personalInfo')">
              <v-avatar size="42">
                <v-img :src="user.headPic || require('../../assets/user/avatar.png')" contain></v-img>
              </v-avatar>
              <div class="pl-3">
                <div class="text-16 color-FDA500 font-weight-bold">
                  {{ user.nickname || $vuetify.lang.t("$vuetify.user.text21") }}
                </div>
                <div class="text-14 pt-1">{{ user.account }}</div>
              </div>
            </div>
            <v-divider class="my-3"></v-divider>
            <v-btn text class="text-14" color="rgba(0,0,0,0.7)" @click="toUrl('/order')">
              <v-img src="../../assets/user/icon_order.png" class="mr-1" alt=""></v-img>
              {{ $vuetify.lang.t("$vuetify.menu.order") }}
            </v-btn>
            <br>
            <!-- <v-btn text class="text-14" color="rgba(0,0,0,0.7)">
              <v-img src="../../assets/user/icon_pay.png" class="mr-1" alt=""></v-img>
              {{ $vuetify.lang.t("$vuetify.menu.payRecord") }}
            </v-btn> -->
            <v-divider class="my-3"></v-divider>
            <v-btn text class="text-14" color="rgba(0,0,0,0.7)" @click="logoutFunc">
              <v-img src="../../assets/user/icon_logout.png" class="mr-1" alt=""></v-img>
              {{ $vuetify.lang.t("$vuetify.logout") }}
            </v-btn>
          </div>
        </v-list-item-content>
      </v-card>
    </v-menu>

    <!-- 语言切换 -->
    <v-menu bottom offset-y>
      <template v-slot:activator="{ on, attrs }">

        <v-btn text v-bind="attrs" v-on="on">
          {{ localeText }}
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </template>

      <v-list flat>
        <v-list-item-group v-model="$vuetify.lang.current">
          <v-list-item @click="handleChangeLocale(item)" v-for="item in availableLanguages" color="#FED600"
            :key="item.value" :value="item.value">
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>

  </v-app-bar>
</template>

<script>
import { logout } from "../../api/index";
export default {
  name: "AppHeader",
  data() {
    return {
      tabs: [
        {
          label: "$vuetify.menu.home",
          name: "home",
          href: "/",
        },
        {
          label: "$vuetify.menu.free",
          name: "free",
          href: "/free",
        },
        {
          label: "$vuetify.menu.about",
          name: "about",
          href: "/about",
        },
        {
          label: "$vuetify.menu.book",
          name: "book",
          href: "/book",
        },
        {
          label: "$vuetify.menu.charter",
          name: "charter",
          href: "/charter",
        },
        {
          label: "$vuetify.menu.contact",
          name: "contact",
          href: "/contact",
        },
        {
          label: "$vuetify.menu.answer",
          name: "answer",
          href: "/answer",
        },
        {
          label: "$vuetify.menu.order",
          name: "order",
          href: "/order",
        },
      ],
      currentLanguage: "",
      barType: "all",
      tab: null,
      user: null
    };
  },
  created() {
    console.log('barType--', this.barType)
    if (localStorage.getItem("locale")) {
      this.$vuetify.lang.current = localStorage.getItem("locale");
    } else {
      localStorage.setItem("locale", "zhHans");
    }
    // this.currentLanguage = this.localeText;
    this.user = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")) : {};

  },
  mounted() {
    this.$nextTick(() => {
      this.barType = this.$route.meta.type;

    });
    this.$router.onReady(() => {
      console.log('路由')
      this.barType = this.$route.meta.type;
      this.tab = this.tabs.findIndex((tab) => tab.name === this.$route.name || tab.name === this.$route.meta.parent);

    })

  },
  watch: {
    "$vuetify.lang.current": {
      handler() {
        // 语言切换时重新请求数据
        // this.carRentalAddress = [this.$vuetify.lang.t("$vuetify.address.text1")];
        // this.returnCarAddress = [this.$vuetify.lang.t("$vuetify.address.text1")];
        // this.getDetail();
        // this.getConfig();
        // console.log('---------',this.localeText)
      },
    },
  },
  methods: {
    // 设置当前语言
    handleChangeLocale({ value }) {
      this.$vuetify.lang.current = value;
      // this.currentLanguage = this.localeText;
      localStorage.setItem("locale", value);
      document.title = this.$vuetify.lang.t("$vuetify.appName");
    },
    toUrl(path) {
      console.log(this.$route.path)
      if(path == this.$route.path) {
        this.$router.go(0);
      } else {
        this.$router.push(path);
        
      }
      this.barType = this.$route.meta.type;
    },
    async logoutFunc() {
      let res = await logout();
      if (res.code == 200) {
        console.log('---')
        localStorage.clear();
        this.toUrl('/login')
      }
    },
    handleUserInfoChange(userInfo) {
      // 处理 userInfo 变化后的逻辑
      console.log('User info changed:', userInfo);
      // 进行其他操作...
    },
  },
  computed: {
    // 遍历并获取项目中的所有语言包
    availableLanguages() {
      const { locales } = this.$vuetify.lang; // 所有的语言，来自 src/plugins/vuetify.js 中的配置
      return Object.keys(locales).map((lang) => {
        return {
          text: locales[lang].label, // 语言包显示的名字
          value: lang, // 语言包的值
        };
      });
    },
    // 当前语言, 用于语言切换菜单中显示的当前语言名字
    localeText() {
      console.log(this.availableLanguages, this.$vuetify.lang.current);
      const find = this.availableLanguages.find(
        (item) => item.value === this.$vuetify.lang.current
      );
      console.log(find);
      return find.text;
    },
  },
};
</script>

<style lang="scss">
.bar-item {
  font-size: 1rem;
}

.v-tab {
  font-size: 1rem;
  color: #000 !important;
  min-width: auto;
  padding: 0 8px;
}

.v-tab-active {
  color: #000;
}

.v-btn.v-size--default {
  font-size: 1rem;
}


</style>