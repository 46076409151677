<template>
    <div :class="[row == 2 ? 'label' : '']">
        <v-chip v-for="(item, index) in labels" :key="index" label :color="colors[index].color" class="mr-md-3 mr-2 mb-2"
            :text-color="colors[index].textColor">
            {{ item }}
        </v-chip>
    </div>
</template>

<script>
export default {
    name: "MyLabel",
    props: {
        labels: Array,
        row: {
            type: Number,
            default: 2
        }
    },
    data() {
        return {
            colors: [
                {
                    color: "#F1FCF7",
                    textColor: "#2BBF60"
                },
                {
                    color: "#F4F1FC",
                    textColor: "#805EE4"
                },
                {
                    color: "#F1F5FC",
                    textColor: "#5E8FE4"
                },
                {
                    color: "#FCF4F1",
                    textColor: "#FF8402"
                }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
.label {
    height: 80px;
     /* 限制高度 */
     overflow: hidden;
    /* 文本溢出用省略号表示 */
    // text-overflow: ellipsis;
    /* 显示为块级元素 */
    // display: -webkit-box;
    /* 控制显示行数 */
    -webkit-line-clamp: 2;
    /* 设置文本的方向 */
    -webkit-box-orient: vertical;
}
</style>