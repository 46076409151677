import axios from "axios";
import Vue from "vue";
import router from '../router';
// import { MessageBox, Message, Loading } from "element-ui";
// import store from "@/store";
// import { getToken } from "@/utils/auth";

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 30 * 1000,
});
const vm = new Vue();

service.interceptors.request.use(
  (config) => {
    vm.$Loading.show()

    console.log('当前语言---', localStorage.getItem('locale'));
    const lang = localStorage.getItem('locale');
    if (lang == 'zhHans' || lang == null) {
      config.headers["language"] = 'zh-cn';
    } else {
      config.headers["language"] = lang;
    }
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    console.log(error); // for debug
    return Promise.reject(error);
  }
);


service.interceptors.response.use(
  (response) => {
    vm.$Loading.hide()
    const res = response.data;
    let errorMsg = window.VueInstance.$vuetify.lang.locales[window.VueInstance.$vuetify.lang.current].errorCode;

    if (res.code !== 200) {
      if (errorMsg[res.code]) {
        vm.$SnackBar.show(errorMsg[res.code]);
      } else {
        vm.$SnackBar.show(res.msg || "Error");
      }
    }
    return res;
  },
  (error) => {
    // window.loading_all.close();
    console.log("err", error.response); // for debug
    vm.$Loading.hide()
    let errorMsg = window.VueInstance.$vuetify.lang.locales[window.VueInstance.$vuetify.lang.current].errorCode;

    if (error.response && error.response.data && error.response.data.code) {
      const errorCode = error.response.data.code;

      if (errorMsg[errorCode]) {
        vm.$SnackBar.show(errorMsg[errorCode]);
        if (errorCode == 402 || errorCode == 511) {
          localStorage.clear();
          router.replace({
            path: '/login',
            query: { redirect: router.currentRoute.fullPath }
          });
        }
      } else {
        vm.$SnackBar.show(error.response.data.msg || "Error");
      }
    } else {
      vm.$SnackBar.show('network error');
    }

    return Promise.reject(error);
  }
);

export default service;
