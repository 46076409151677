import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import About from '../views/About/index.vue';
import Register from '../views/User/Register.vue';
import Login from '../views/User/Login.vue';
import Contact from '../views/Contact/index.vue';
import Book from '../views/Book/index.vue';
import Order from '../views/Order/index.vue';
import Free from '../views/Free.vue';
import Answer from '../views/Answer.vue';
import Charter from '../views/charter.vue';
import Consult from '../views/Consult.vue';

Vue.use(VueRouter);

// 解决跳转到当前路由报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
};

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
            title: '首页',
            type: 'all',
            showNavbar: true
        },
    },
    {
        path: '/register',
        name: 'register',
        component: Register,
        meta: {
            title: '注册',
            type: 'user',
            showNavbar: true
        },
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            title: '登录',
            type: 'user',
            showNavbar: true
        },
    },
    {
        path: '/forgetPwd',
        name: 'forgetPwd',
        component: () => import('../views/User/ForgetPwd.vue'),
        meta: {
            title: '忘记密码',
            type: 'user',
            showNavbar: true
        },
    },
    {
        path: '/free',
        name: 'free',
        component: Free,
        meta: {
            title: '租车自由行',
            type: 'all',
            showNavbar: true
        },
    },
    {
        path: '/answer',
        name: 'answer',
        component: Answer,
        meta: {
            title: '常见问题解答',
            type: 'all',
            showNavbar: true
        },
    },
    {
        path: '/about',
        name: 'about',
        component: About,
        meta: {
            title: '关于我们',
            type: 'all',
            showNavbar: true
        },
    },
    {
        path: '/contact',
        name: 'contact',
        component: Contact,
        meta: {
            title: '联络我们',
            type: 'all',
            showNavbar: true
        },
    },
    {
        path: '/book',
        name: 'book',
        component: Book,
        meta: {
            title: '预定车辆',
            type: 'all',
            showNavbar: true
        }
    },
    {
        path: '/charter',
        name: 'charter',
        component: Charter,
        meta: {
            title: '包车游',
            type: 'all',
            showNavbar: true
        }
    },
    {
        path: '/consult',
        name: 'consult',
        component: Consult,
        meta: {
            title: '咨询',
            type: 'all',
            showNavbar: true
        }
    },
    {
        path: '/order',
        name: 'order',
        component: Order,
        meta: {
            title: '订单记录',
            type: 'all',
            showNavbar: true
        }
    },
    {
        path: '/personalInfo',
        name: 'personal',
        component: () => import('../views/User/Personal.vue'),
        meta: {
            title: '个人资料',
            type: 'all',
            showNavbar: true
        }
    },
    {
        path: '/updatePwd',
        name: 'updatePwd',
        component: () => import('../views/User/UpdatePwd.vue'),
        meta: {
            title: '修改密码',
            type: 'all',
            showNavbar: true
        }
    },
    {
        path: '/book/detail/:id',
        name: 'bookDetail',
        component: () => import('../views/Book/detail.vue'),
        meta: {
            title: '预定车辆详情',
            type: 'all',
            parent: 'book',
            showNavbar: true
        },
    },
    {
        path: '/book/book/:id',
        name: 'bookCar',
        component: () => import('../views/Book/book.vue'),
        meta: {
            title: '立即预定',
            type: 'all',
            parent: 'book',
            showNavbar: true
        },
    },
    {
        path: '/orderDetail/:id',
        name: 'orderDetail',
        component: () => import('../views/Book/orderDetail.vue'),
        meta: {
            title: '确认订单',
            type: 'all',
            parent: 'book',
            showNavbar: true
        },
    },
    {
        path: '/orderComment/:vehicleId/:orderId',
        name: 'orderComment',
        component: () => import('../views/Book/orderComment.vue'),
        meta: {
            title: '评价订单',
            type: 'all',
            parent: 'book',
            showNavbar: true
        },
    },
    {
        path: '/paySuccess/:id',
        name: 'paySuccess',
        component: () => import('../views/Book/paySuccess.vue'),
        meta: {
            title: '支付成功',
            type: 'all',
            parent: 'book',
            showNavbar: true
        },
    },
    {
        path: '/payCancel/:id',
        name: 'payCancel',
        component: () => import('../views/Book/payCancel.vue'),
        meta: {
            title: '取消支付',
            type: 'all',
            parent: 'book',
            showNavbar: true
        },
    },
    {
        path: '/license',
        name: 'license',
        component: () => import('../views/License.vue'),
        meta: {
            title: '关于驾驶执照',
            type: 'all',
            parent: 'book',
            showNavbar: true
        },
    }
];

const router = new VueRouter({
    mode: 'hash',
    // base: process.env.BASE_URL,
    routes,
    scrollBehavior(to) {
        if (to.hash) {
            return { selector: to.hash };
        } else {
            return { x: 0, y: 0 };
        }
    }
});


export default router;