import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import './styles/main.scss'
import SnackBar from './components/SnackBar';
import Loading from './components/MyLoading';
import enumerate from './utils/enumerate'
import { formatPrice, formatDate } from './utils/filters'

// 注册 SnackBar 为全局组件
Vue.component('SnackBar', SnackBar);

// 挂载 SnackBar 到 Vue.prototype 上
Vue.prototype.$SnackBar = {
  show(message, options = {}) {
    const snackbarInstance = new Vue(SnackBar).$mount();
    document.body.appendChild(snackbarInstance.$el);
    snackbarInstance.show(message, options);
  }
};

let loadingInstance = null;
Vue.prototype.$Loading = {
  show() {
    if (!loadingInstance) {
      loadingInstance = new Vue(Loading).$mount();
      document.body.appendChild(loadingInstance.$el);
    }
    loadingInstance.show();
  },
  hide() {
    if (loadingInstance) {
      loadingInstance.hide();
    }
  }
};

Vue.prototype.$enum = enumerate;

Vue.prototype.$formatDate = formatDate;

Vue.prototype.$formatPrice = formatPrice;



Vue.config.productionTip = false
const app = new Vue({
  vuetify,
  router,
  render: h => h(App),
  mounted() {
    document.title = this.$vuetify.lang.t("$vuetify.appName");
  }
}).$mount('#app');

// // 添加一个全局的前置守卫
// router.beforeEach((to, from, next) => {
//   document.title = this.$vuetify.lang.t("$vuetify.appName");
//   next();
// });

// 导出 Vue 实例
window.VueInstance = app;