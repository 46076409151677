import zhHans from 'vuetify/es5/locale/zh-Hans' // 导入Vuetify 自带的简体中文语言包

export default {
    appName: '青松租车',
    label: '简体中文',
    // page login
    login: '登录',
    register: '注册',
    username: '用户名',
    password: '密码',
    login_account: '登录帐户',
    submit: '提交',
    logout: '退出登录',
    none: '暂无数据',
    seat: '座',
    day: '天',
    cancel: '取消',
    del: '删除',
    bookText: '预定',
    week: '周',
    month: '月',
    year: '年',
    cDay:'天',
    // menu
    menu: {
        home: '首页',
        free: '租车自由行',
        about: '关于我们',
        book: '预定车辆',
        contact: '联络我们',
        answer: '常见问题',
        order: '订单记录',
        personal: '个人资料',
        account: '账号安全',
        payRecord: '支付记录',
        charter: '包车游',
        consult: '咨询我们'
    },
    charter: {
        text1: '丰田阿尔法',
        text2: '丰田hiace',
        text3: '* 机场接送、主题公园接送等都是单程价格。',
        text4: '大阪接送',
        text5: '京都接送',
        text6: '大阪环球影城',
        text7: '大阪（市内往返）',
        text8: '京都（市内往返）',
        text9: '大阪、奈良、京都（当天往返）',
        text10: '一价全含/华人司导/可接送机<br>※ 如需其他车型可咨询确认，根据淡旺季价格有些许浮动，预定需二次核价。',
        text11: '关西地区',
        text12: '咨询青松租车公司',
        alphard: '最大6名乘客',
        hiace: '最大9名乘客'
    },
    consult: {
      text1: '请选择您的要求:',
      text2: '【租车查询】',
      text3: '【关于租用服务的咨询】',
      text4: '姓名',
      text5: '拼音',
      text6: '请选择咨询者的所在地',
      text7: '日本在住',
      text8: '日本以外在住',
      text9: '两者（咨询者为多人时）',
      text10: '邮政编码',
      text11: '请输入不含连字符的内容',
      text12: '点击此处进行邮政编码搜索',
      text13: '地点',
      text14: '电子邮件',
      text15:'手机号码',
      text16: '如果您租车，请填写您想要的车辆类型和使用期限。',
      text17: '如果您想租车，请选择下面的汽车类型。',
      text18: '轻型车',
      text19: '混合动力汽车',
      text20: '厢式货车/小型货车',
      text21: '紧凑型汽车',
      text22: '轻型货车/货车',
      text23: '其他的',
      text24: '其他车型',
      text25: '如果您想要其他车型或以上车型，请填写以下信息。',
      text26: '选择租车附加选项：',
      text27: 'ETC车辆',
      text28: '汽车导航',
      text29: '行车记录仪',
      text30: '汽车运输车',
      text31: '4WD四轮驱动（仅适用于小型商用车和轻型厢式货车）',
      text32: '无钉防滑轮胎',
      text33: '联系信息，例如LINE或微信ID',
      text34: '期望开始时间',
      text35: '请选择您想要的开始日期',
      text36: '期望持续时间',
      text37: '请输入数字',
      text38: '期望持续时间单位',
      text39: '司机人数：',
      text40: '人',
      text41: '以上',
      text42: '（仅限租用服务）',
      text43: '所需汽车数量：',
      text44: '辆车',
      text45: '或以上',
      text46: '问题、意见等',
      text47: '发送',
      text48: '通过电子邮件联系我们'
    },
    user: {
        text1: '请输入邮箱/手机号',
        text2: '请输入密码',
        text3: '忘记密码',
        text4: '立即注册',
        text5: '邮箱找回',
        text6: '手机找回',
        text7: '请输入手机号',
        text8: '请输入验证码',
        text9: '请再次输入密码',
        text10: '请输入邮箱',
        text11: '获取验证码',
        text12: '更改密码',
        text13: '请选择区号',
        text14: '昵称',
        text15: '请输入昵称',
        text16: '头像',
        text17: '保存',
        text18: '邮箱',
        text19: '手机',
        text20: '保存成功',
        text21: '尊贵的会员',
        text22: '修改密码',
        text23: '当前密码',
        text24: '新密码',
        text25: '确认新密码',
        text26: '请输入当前密码',
        text27: '请输入新密码',
        text28: '请再次输入新密码',
        text29: '两次输入密码不一致',
        text30: '点击登录或注册表示您已阅读或同意',
        text31: 'Utor租车协议',
        text32: '隐私政策',
        text33: '邮箱注册',
        text34: '手机注册',
        text35: '请输入正确的邮箱',
        text36: '请输入正确的手机号',
        text37: '验证码已发送'
    },
    home: {
        text1: '立即查看',
        title1: '租车流程',
        title2: '热门车辆',
        title3: '多语言客服支持',
        text2: '只需3步，轻松搞定您的用车需求',
        text3: '选择车辆',
        text4: '选择您需要的车辆型号后点击预订',
        text5: '填写信息',
        text6: '选择用车时间并填写个人信息',
        text7: '机场取车',
        text8: '根据您填写的用车信息到指定地点取车',
        text9: '查看更多',
        text10: '为您提供多语种服务'
    },
    about: {
        title: '关于我们',
        content: '这里是关于我们页面的内容',
        text1: '选择UTOR的理由',
        text2: '为什么选择UTOR',
        text3: '客户好评',
        text4: '社交媒体',
        text5: '随着生活方式的改变，人们对于汽车生活的需求也越来越多元化。Uto Rental Car将致力于为客户提供清洁・便利・安全・周到的租车服务。另外我们也会重视从客户角度出发的想法，今后继续努力为更多的客户提供安心，舒适的租车服务。如果您对旅行时需要提供选车建议的话，请随时与我们联系。',
        text6: '丰富的车型选择',
        text7: '无论您是独自旅行还是与家人朋友一同出行，我们都能提供适合您的车辆。从经济实惠的小型车到宽敞舒适的豪华MPV，我们的车队涵盖了各种类型，确保您在旅途中享有最佳的驾驶体验。',
        text8: '专为家庭设计的便利服务',
        text9: '我们为家庭出行特别准备了儿童座椅，确保小朋友的安全与舒适。此外，所有车辆均配备ETC设备，让您可以快速通过日本的高速公路收费站，省去排队的烦恼，节省宝贵的旅行时间。',
        text10: '透明的定价机制',
        text11: '在青松租车，您无需担心隐藏费用。我们提供透明的价格体系，所有费用在预订时一目了然，您可以放心享受旅程，而无需担心额外开支。',
        text12: '全天候多语言客服支持',
        text13: '我们了解在异国他乡旅行时沟通的重要性。因此，我们的客服团队提供24小时的多语言支持，无论您在旅途中的任何时刻遇到问题，我们都能为您提供及时的帮助。',
        text14: '关西机场专属服务',
        text15: '为了让您的行程更加便利，我们在关西国际机场提供专属的取车与还车服务。无论您是刚抵达还是准备离开日本，都可以在机场轻松完成租车或还车手续，开启或结束您的无忧旅程。',
        text16: '全面的安全保障',
        text17: '安全是我们的首要任务。所有车辆在每次租赁前都经过严格的安全检查，并配备全险保障，让您在日本的每一公里都行驶得更加安心。',
        text18: '青松租车致力于为每一位来到日本的旅客提供一流的租车服务，确保您的旅途不仅舒适，而且无忧无虑。我们提供的不仅仅是一辆车，而是一整套为您量身定制的出行解决方案。以下是选择青松租车的六大理由：',
        text19: '青松租车不仅是您的出行伙伴，更是您在日本的贴心向导。选择我们，让您的日本之行更加难忘与愉快。期待为您服务，祝您旅途愉快！'
    },
    contact: {
        title: '联络我们',
        text1: '联系我们',
        text2: '我们将竭诚为您服务',
        text3: '问题反馈',
        text4: '您的姓名',
        text5: '您的邮箱',
        text6: '内容',
        text7: '请输入姓名',
        text8: '请输入邮箱',
        text9: '请输入您要反馈的内容',
    },
    answer: {
        title: '常见问题解答',
        text1: '在日本驾车需要何种驾照执照？',
        text2: '需符合日内瓦公约所规定格式的有效国际驾照执照。<a href="/#/license">查看详情</a><div style="text-indent: 1.5em;">※持有瑞士、德国、法国、比利时、台湾及摩纳哥之驾照执照者，需提供日本汽車联盟或大使馆颁发的日本官方翻译文本。</div>',
        text3: '可以由申请者外的人士驾驶吗？',
        text4: '可以。但是所有租車驾驶者都必须提出申请，因此，请于出发当日在店內出示所有驾驶者的驾驶執照。',
        text5: '不办理预约可以租车吗？',
        text6: '当天店内有空車就可租赁。但是，可能会因预约太满而无法提供车辆，因此建议事先预约。',
        text7: '取消预约时，需要支付取消手续费吗？',
        text8: '因顾客个人理由取消预约时，会根据本公司规定收取预约取消手续费（取消手续费）。提前7天取消不需要支付取消手续费。距离预约出发日6天之内取消，则会收取取消手续费。',
        text9: '违规停车时应该怎么办？',
        text10: '如被贴上罚单，请前往所属警察局办理手续并缴纳罚款后返回。还车时请出示「違反交通規則通知」和记载支付日期的「缴费单、收据等」等資料。如果还车时没有出示「交通違規告知書」「交付書·收据证明等」,一律需繳付30,000日元的違例停車罚金。<div style="text-indent: 1.5em;">※日后缴纳过罚款並向本公司出示「違反交通規則通知」和记载支付日期的「缴费单、收据等」后，可退回等額之违规停车费。退款將汇入指定戶口。（汇款手续费由顾客承担。）</div><div style="text-indent: 1.5em;">※本公司在收到放置違反金缴纳命令，或顾客未在本公司规定日期前全额支付规定费用时，将采取于一般社团法人全国租車协会资讯管理系统进行登记等措施，敬请谅解。该协会旗下租车车行今后可能会拒绝该顾客的租车要求。</div>',
        text11: '出发时需要办理什么手续？',
        text12: '在办理出发手续时，本公司將确认所有驾驶者的驾驶执照文件和护照，並请顾客确认使用规章。办理完手续后，须根据合约支付预定费用。（出发时结算。）<div style="text-indent: 1.5em;">※如果已事先结算费用，则可忽略此步骤。在简要说明车辆情况后，由店员与顾客一起目视检查车辆表面的伤痕等。此项检查完毕后即可使用车辆。</div>',
        text13: '可以在非营业时间取车或还车吗？',
        text14: '出发和还车手续须在本公司的营业时间内办理。',
        text15: '注册会员忘记带驾照执照时可以租车吗？',
        text16: '如出发时无法出示有效驾驶执照和护照，则不可租赁，当天请务必携带。',
        text17: '还车时有什么注意事项？',
        text18: '请在还车前自行确认有无遗忘物品。对于遗忘物品的遗失等，本公司概不负责，敬请谅解。',
        text19: '如果还车时无法加满油，应该怎么办？',
        text20: '请直接向本公司还车。本公司將根据实际行驶距离向顾客收取规定的代理加油手续费。',
    },
    free: {
        title: '租车自由行',
        text1: '为什么要租车出行呢？',
        text2: '随心所欲，自在出行',
        text3: '租车自由行，随心所欲，便利舒适，摆脱固定路线和时间表的束缚，随时随地探索未知的美好。您可以想停就停，想走就走，每一站都由您做主。',
        text4: '深入游玩，探索未见之美',
        text5: '摆脱公共交通时间限制，随时随地探访您心中的目的地，节省等待换乘时间。与包车或出租车相比，更能有效控制旅行成本。',
        text6: '自由探索，私享旅程',
        text7: '拥有独立私人空间，与亲朋好友共享亲密时光。充足的乘坐和储物空间，让您的旅途更加自在。让您拥有更高的自由度、私密度和舒适度。',
        text8: '全家欢聚，尊享旅程',
        text9: '全系豪华保姆车，配备豪华内饰、多媒体娱乐系统等。超大的内部空间在您拥有更高自由度和舒适度的旅程中确保老人和孩子的舒适。',
        text10: '最好的服务',
        text11: '为您提供最优质的服务',
        text12: '深度旅行行程建议',
        text13: '为您提供免费深度旅行建议',
        text14: '接送机服务',
        text15: '为您提供接送机服务',
        text16: '交车服务',
        text17: '为您提供交车服务'
    },
    book: {
        title: '预定车辆',
        text1: '请输入关键字搜索',
        text2: '搜索',
        text3: '全部车型',
        text4: '全部品牌',
        text5: '乘坐人数',
        text6: '已租',
        text7: '次',
        text8: '条评价',
        text9: '最早可用日期',
        text10: '用户评价',
        text11: '排序方式',
        text12: '价格'
    },
    booking: {
        title: '立即预定',
        text1: '预定人姓名',
        text2: '请输入预定人姓名',
        text3: '手机号码',
        text4: '选择区号',
        text5: '请输入手机号',
        text5_1: '请输入正确的手机号',
        text6: '租车日期',
        text7: '请选择租车日期',
        text6_1: '租车时间',
        text7_1: '请选择租车时间',
        text8: '还车日期',
        text9: '请选择还车日期',
        text8_1: '还车时间',
        text9_1: '请选择还车时间',
        text10: '租车地点',
        text11: '请选择租车地点',
        text12: '还车地点',
        text13: '请选择还车地点',
        text14: '护照号码',
        text15: '不用输入括号',
        text16: '请输入护照号码',
        text17: '出生日期',
        text18: '请选择出生日期',
        text19: '儿童座椅',
        text20: '根据法律，未满6岁的儿童必须使用儿童安全座椅或婴儿安全座椅。',
        text21: 'ETC',
        text21_1: '请选择ETC',
        text22: '不需ETC',
        text23: '需ETC',
        text24: '某些商店不提供ETC装置与卡片',
        text25: '保险补偿制度',
        text25_1: '请选择保险补偿制度',
        text26: '租车双重保护方案',
        text27: '仅免责补偿',
        text28: '不加入',
        text29: '关于免责补偿制度的资讯。在预约详细咨讯上，免责补偿与经营损失赔偿(NOC)免除项 目以各别项目列出',
        text30: '驾照类型',
        text31: '请由下列选择您所持有的驾照种类。',
        text32: '1.符合《日内瓦公约》的国际驾照（在过去一年内发出）',
        text33: '2.持有瑞士、德国、法国、比利时、台湾、摩纳哥等国发行的驾照，及驾照的日文翻译本',
        text34: '3.于日本发出的驾照',
        text35: '上述1和2的驾照的有效期限为从客户抵达日本起1年内，或与驾照的有效期限两者当中，日期较早的为准。 （但凡持日本住民登录票者，于出国确认或取得再入国许可后由日本出国、而3个月内再次入境日本时，其回国（入境）日并不作为可于日本国内开车之起始日。 ）我们将通过护照上的入境章确认入境日。 若使用自动通关闸道入境，护照上无入境章者，则无法提供租车服务。',
        text36: '请选择',
        title2: '班机信息',
        text37: '到达',
        text38: '请输入航空公司',
        text39: '请输入班机编号',
        text40: '回程',
        text41: '优惠券',
        text42: '输入优惠券卷码可以抵扣订单金额',
        text43: '价格明细',
        text44: '总价',
        text45: '优惠券抵扣',
        text46: '总额',
        text47: '选项免费',
        text48: '点击此处'
    },
    orderDetail: {
        title1: '订单进度',
        title2: '支付方式',
        title3: '订单信息',
        title4: '租车清单',
        text1: '待支付',
        text2: '订单已提交请尽快支付',
        text3: '门店确认中',
        text4: '待分配车辆',
        text5: '待取车',
        text6: '用车中',
        text7: '订单完成',
        text8: '银行卡支付',
        text9: '微信支付',
        text10: '支付宝支付',
        text11: '订单编号',
        text12: '下单时间',
        text13: '预定人姓名',
        text14: '手机号码',
        text15: '护照号码',
        text16: '租车点',
        text17: '还车点',
        text18: '立即支付',
        text19: '立即评价',
        text20: '价格明细',
        text21: '总价',
        text22: '总额'
    },
    orderComment: {
        text1: '请在这里输入评价内容，您的认可是对我们最大的支持哦！',
        text2: '发表评价'
    },
    footer: {
        text1: '联系我们',
        text2: '电话',
        text3: '邮箱',
        text4: '地址',
        text5: '关于我们',
        text6: '公司简介',
        text7: '客户好评',
        text8: '社交媒体',
        text9: '预定车辆',
        text10: '立即预定',
        text11: '联络我们',
        text12: '问题反馈',
        text13: '订单记录',
        text14: '我的行程',
        text15: '过往行程',
        text16: '大阪府泉南郡熊取町大久保北3-192-6',
        text17: '公司名',
        text18: '宝源貿易株式会社',
        text19: '客服'
    },
    pigination: {
        text1: '共',
        text2: '条记录',
        text3: '第',
        text4: '页',
        text5: '上一页',
        text6: '下一页',
        text7: '跳至'
    },
    msg: {
        text1: '登录成功',
        text2: '注册成功',
        text3: '更改密码成功',
        text4: '评论成功',
        text5: '恭喜您，订单支付成功',
        text6: '感谢您的支付！',
        text7: '支付失败，用户取消支付',
        text8: '支付已取消',
        text9: '查看订单',
        text10: '有信息未填写，请检查'
    },
    errorCode: {
        10001: '系统异常',
        10002: '数据不存在',
        10003: '仅支持图片（png、png、jpeg）',
        10004: '获取验证码异常',
        10005: '验证码错误',
        10006: '密码错误',
        10007: '账号信息不存在',
        10008: '参数异常',
        10009: '订单信息不存在',
        10010: '当前订单未完成，不能参与评论',
        10011: '原密码错误',
        10012: '车辆不存在',
        10013: '车辆不足',
        10014: '当前订单不可删除',
        10015: '当前订单不可取消',
        10016: '账号已注册',
        10017: '租车开始时间异常，当前时间无车辆可用',
        10018: '订单不是待支付订单',
        10019: '发送验证码失败',
        511: '登录已过期, 请重新登录',
        402: '登录已过期, 请重新登录'
    },
    enum: {
        text1: '车辆价格',
        text2: '儿童座椅',
        text3: 'ETC',
        text4: '保险',
        text5: '待支付',
        text6: '已支付',
        text7: '已退款',
        text8: '已取消',
        text9: '已分配车辆(待取车)',
        text10: '已取车',
        text11: '已完成',
        text12: '待评价'
    },
    address: {
        text1: '关西机场'
    },
    //驾照单页
    driving: {
        title: '关于驾驶执照',
        desc: '为您介绍在日本驾车所必备的驾驶执照和相关资料。<br/>近年来，伪造国际驾驶执照进行租车活动的现象越来愈多，已经成为严重问题。顾客的护照和国际驾驶执照发行国不一致时，工作人员可能会确认护照上的出入境记录。如护照上没有国际驾驶执照发行国的出入境纪录，则需要出示发行国的居住证明，请记得携带。<br/>例：国际驾驶执照发行国的ID，驾驶执照，居留证等<br/>如无法确认国际驾驶执照是否由本人从发行国认定的机构获取，且顾客本人所持国际驾驶执照有效性无法证明时，本公司将拒绝提供租车服务。<br/>*租借W6车型时，需要持可驾驶10人车的有效驾驶执照。',
        detail: [
            {
                title: '持有国际驾照的客户',
                desc: '必须有护照和有效的国际驾驶执照。<br/>国际驾驶执照必须符合《1949年日内瓦国际道路交通公约》。<br/>※国际驾驶执照的有效期为发行后1年，在日本的有效驾驶期限为自入国日起1年 (由护照上进入日本国的年月日盖章确认) 。<br/>※如果您使用“自动门”过关，请在过关后向工作人员获得入境印章(或上陆许可贴纸)。如果您没有印章(或上陆许可贴纸)，您将无法租车，因为无法确认您的入境日期/再上陆日期。<br/>※如果是有在日本居民登记簿中被纪录或有进行外国人登记的人，在离开日本后不到3 个月(不是合算，而是连续) 的时间内重新进入日本，则此重新进入日本的日期将无法被视为“上陆日” (“上陆日”用以计算国际驾驶执照可以使用的有效期限) ,我们将无法租车给您因为您不符合在日本驾驶的规定。',
                text: '国际驾驶执照',
                text1: '有效的护照',
            },
            {
                title: '持有法国、德国、瑞士、摩纳哥、比利时、台湾、驾驶执照的顾客',
                desc: '根据日本交通规则，驾驶时需要携带下述资料。请在出发店铺向本公司工作人员出示。<br/>※如果您使用“自动门”过关，请在过关后向工作人员获得入境印章(或上陆许可贴纸)。如果您没有印章(或上陆许可贴纸)，您将无法租车，因为无法确认您的入境日期/再上陆日期。<br/>※如果持有指定外国驾驶执照，且有在日本居民登记簿中被纪录或有进行外国人登记的人，在离开日本后不到3 个月(不是合算，而是连续) 的时间内重新进入日本，则此重新进入日本的日期将无法被视为“上陆日” (“上陆日”用以计算外国驾驶执照可以使用的有效期限) ,我们将无法租车给您因为您不符合在日本驾驶的规定。',
                text: '有效的本国驾驶执照',
                text1: '有效的护照',
                text2: '正式翻译文书'
            }
        ],
        //翻译文书
        translate: {
            title: '正式翻译文书的获取方法',
            desc: '日文的官方翻译文件请透过下述机关获取。',
            detail: '・驾驶执照的发行机构或对应国家的大使馆或领事馆<br/>・一般社团法人日本汽车联盟 (JAF)<br/>・德国自动车联盟 (ADAC) ※仅限德国驾照<br/>・台湾日本关系协会 ※仅限台湾驾照<br/>・ZIPLUS Co., Ltd. ※仅限台湾驾照<br/>'

        }
    },
    // 展开 简体中文语言包的所有项目
    ...zhHans,
}
