<template>
  <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y
    min-width="auto">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field v-model="date" :placeholder="placeholder" solo flat outlined prepend-inner-icon="mdi-calendar"
        readonly v-bind="attrs" v-on="on"></v-text-field>
    </template>
    <v-date-picker v-model="date" @input="onDateChange" no-title scrollable :locale="$vuetify.lang.current"
      :min="minDate" :max="maxDate" :allowed-dates="allowedDates"></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: "DatePicker",
  props: {
    placeholder: String,
    minDate: String,
    dates: Array,
  },
  data() {
    return {
      date: null,
      // date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
    }
  },
  computed: {
    maxDate() {
      const currentDate = new Date();
      const sixMonthsLater = new Date(currentDate.getTime() + 6 * 30 * 24 * 60 * 60 * 1000); // 6 months later
      return sixMonthsLater.toISOString().substr(0, 10);
    }
  },
  methods: {
    onDateChange(date) {
      this.$emit('date-changed', date)
      this.menu2 = false
    },
    allowedDates(date) {
      // console.log('不允许的日期',this.dates,date)
      if (this.dates) {
        const formattedDate = this.formatDate(date);
        for (const element of this.dates) {
          const rentalDate = this.formatDate(element.carRentalDate);
          const returnDate = this.formatDate(element.returnCarDate);

          if (formattedDate >= rentalDate && formattedDate < returnDate) {
            return false;
          }
        }
        return true;
      } else {
        return true;
      }
    },
    formatDate(dateStr) {
      const date = new Date(dateStr);
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
    }
      
  }
}
</script>