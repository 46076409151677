<template>
  <div id="register">
    <v-container class="d-flex justify-end pa-md-10">
      <v-card class="register-card">
        <!-- <v-card-title>登录</v-card-title> -->
        <v-form ref="form">
          <v-card-text>
            <div class="text-20 font-weight-bold text--primary pb-md-10 pb-7 text-center">
              {{ $vuetify.lang.t("$vuetify.login") }}
            </div>
            <v-text-field class="text-md-16 text-14" color="#ccc" height="35px"
              :placeholder="$vuetify.lang.t('$vuetify.user.text1')" :rules="rules.account" v-model="formData.account">
              <img slot="prepend-inner" class="mr-4" width="20px" src="../../assets/user/icon_account.png" alt="" />
            </v-text-field>
            <v-text-field class="text-md-16 text-14" color="#ccc" height="35px"
              :placeholder="$vuetify.lang.t('$vuetify.user.text2')" type="password" :rules="rules.password"
              v-model="formData.password">
              <img slot="prepend-inner" class="mr-4" width="20px" src="../../assets/user/icon_password.png" alt="" />
            </v-text-field>
            <div class="d-flex justify-space-between pb-6">
              <div class="text-md-16 text-14 color-000-36 font-weight-medium cursor-pointer"
                @click="$router.push('/forgetPwd')">
                {{ $vuetify.lang.t("$vuetify.user.text3") }}？
              </div>
              <div class="text-md-16 text-14 color-000-36 font-weight-medium cursor-pointer"
                @click="$router.push('/register')">
                {{ $vuetify.lang.t("$vuetify.user.text4") }}
              </div>
            </div>
            <!-- <v-select v-if="item.formType === 2" placeholder="请选择区号" :rules="rules.areaCode" :items="areaCodeItems"
              item-text="name" item-value="value" v-model="item.formData.select"></v-select> -->

            <v-btn class="d-none d-md-block" depressed width="100%" height="46px" color="#FED600" @click="handleSubmit">{{
              $vuetify.lang.t("$vuetify.login") }}</v-btn>
            <v-btn class="d-md-none text-20" depressed width="100%" height="46px" color="#FED600" @click="handleSubmit">{{
              $vuetify.lang.t("$vuetify.login") }}</v-btn>

            <div class="text">
              {{ $vuetify.lang.t("$vuetify.user.text30") }}
              <a @click="toAgreement(1)">《{{ $vuetify.lang.t("$vuetify.user.text31") }}》</a>
              和
              <a @click="toAgreement(2)">《{{ $vuetify.lang.t("$vuetify.user.text32") }}》</a>
            </div>
          </v-card-text>
        </v-form>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { login, agreementList } from "../../api/index";
export default {
  name: "LoginIndex",
  components: {
    // MyAgreement,
    // AppFooter
  },
  data() {
    return {
      tab: null,
      formData: {
        account: "",
        password: "",
      },
      areaCodeItems: [
        {
          name: "China",
          value: "86",
        },
        {
          name: "USA",
          value: "44",
        },
        {
          name: "Japan",
          value: "81",
        },
        {
          name: "Korea",
          value: "82",
        },
      ],
      rules: {
        account: [
          (value) => !!value || this.$vuetify.lang.t("$vuetify.user.text1"),
          // value => (value || '').length <= 20 || 'Max 20 characters',
          // value => {
          //   const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          //   return pattern.test(value) || 'Invalid e-mail.'
          // },
        ],
        password: [(value) => !!value || this.$vuetify.lang.t("$vuetify.user.text2")],
      },
    };
  },
  watch: {
    "$vuetify.lang.current": {
      handler() {
        this.$router.go(0);
      },
    },
  },
  methods: {
    async handleSubmit() {
      const valid = this.$refs.form.validate();
      if (!valid) {
        return;
      }
      let params = {
        account: this.formData.account,
        password: this.formData.password,
      };
      let res = await login(params);
      if (res.code == 200) {
        this.$SnackBar.show(this.$vuetify.lang.t("$vuetify.msg.text1"), { type: "success" });
        localStorage.setItem("userInfo", JSON.stringify(res.data));
        localStorage.setItem("token", res.data.token);
        this.$router.push({ path: "/" });
        location.reload();
      }
    },
    async toAgreement(type) {
      let res = await agreementList({
        type: type,
      });
      if (res.code == 200) {
        let data = res.data.list[0];
        data.title = type == 1 ? this.$vuetify.lang.t("$vuetify.user.text31") : this.$vuetify.lang.t("$vuetify.user.text32");
        localStorage.setItem('agreement', JSON.stringify(data));
        window.open('/agreement.html', '_blank')

      }
    }
  },
};
</script>

<style lang="scss" scoped>
#register {
  background: url(../../assets/user/img.png) no-repeat;

  .register-card {
    width: 500px;
  }

  .v-tab {
    font-size: 1.5rem;
    border-bottom: 1px solid #ccc;
  }

  .v-card__text {
    padding: 20px 40px;
  }

  .v-input {
    font-size: 1.25rem;
  }

  .text {
    width: 60%;
    margin: 10px auto 0;
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.5);
    text-align: center;

    a {
      color: rgba(0, 0, 0, 0.5);
      text-decoration: underline;
    }
  }

  .code {
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.5);
  }
}

@media (max-width: 960px) {
  #register {
    background-size: 100%;

    .register-card {
      width: 100%;
      margin-top: 80px;
    }

    .v-card__text {
      padding: 20px 20px 40px;
    }
    .text {
      font-size: 0.75rem;
      width: 70%;
    }
  }
}
</style>
<style>
#register .v-input__slot {
  align-items: flex-start;
}

.v-application--is-ltr .v-text-field .v-input__append-inner {
  margin-top: 0;
}
</style>
