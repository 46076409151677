<template>
  <!-- <v-card class="mx-auto overflow-hidden" height="400"> -->
  <div class="nav-mobile">
    <v-app-bar color="#fff" dense app flat class="mobile-header pr-0">
      <!-- <div class="text-44 font-weight-bold">Utor.</div> -->
      <div class="align-left">
        <v-img src="../../assets/logo_home.png" width="140"></v-img>
      </div>
      <div class="float-right">
        <v-btn class="pl-2 pr-2" rounded color="#FED600" href="/#/book">{{ $vuetify.lang.t("$vuetify.bookText") }}</v-btn>
        <a href="/#/login" style="color: #333;padding-left: 10px;vertical-align: middle;" v-if="!user.userId">{{
          $vuetify.lang.t("$vuetify.login") }}</a>
        <v-avatar size="42" v-else class="ml-3" @click="toUrl('/personalInfo')">
          <v-img :src="user.headPic || require('../../assets/user/avatar.png')" contain></v-img>
        </v-avatar>
        <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
      </div>

    </v-app-bar>

    <!-- <div class="nav-wrap"> -->
    <v-navigation-drawer v-model="drawer" fixed width="100%">
      <div class="d-flex justify-space-between pa-4">
        <div>
          <v-img src="../../assets/logo_home.png" width="140"></v-img>
        </div>
        <div @click="drawer = false">
          <v-icon>mdi-close</v-icon>
        </div>
      </div>
      <v-list nav class="pa-4 text-center">
        <v-list-item-group active-class="item-active">
          <template v-for="(item, index) in tabs">
            <v-list-item @click="toUrl(item.href)" :key="index" v-if="
              user.userId ||
              (item.name !== 'order' && item.name !== 'personal' && !user.userId)
            ">
              <v-list-item-title class="text-20">
                {{ $vuetify.lang.t(item.label) }}</v-list-item-title>
            </v-list-item>
          </template>
          <v-list-item class="item-active" v-if="!user.userId">
            <v-list-item-title class="text-20" @click="$router.push('/register')">{{
              $vuetify.lang.t("$vuetify.register")
              }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <v-list>
        <v-list-group no-action>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="text-20" v-text="currentLanguage"></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item-group v-model="$vuetify.lang.current">
            <v-list-item @click.stop="handleChangeLocale(item)" v-for="item in availableLanguages" color="#FED600"
              :key="item.value" :value="item.value">
              <v-list-item-title class="text-20">{{ item.text }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
    <!-- </div> -->
  </div>
  <!-- </v-card> -->
</template>

<script>
export default {
  name: "AppHeaderMobile",
  data: () => ({
    drawer: false,
    group: null,
    currentLanguage: "",
    tabs: [
      {
        label: "$vuetify.menu.home",
        name: "home",
        href: "/",
      },
      {
        label: "$vuetify.menu.free",
        name: "free",
        href: "/free",
      },
      {
        label: "$vuetify.menu.about",
        name: "about",
        href: "/about",
      },
      {
        label: "$vuetify.menu.book",
        name: "book",
        href: "/book",
      },
      {
        label: "$vuetify.menu.charter",
        name: "charter",
        href: "/charter",
      },
      {
        label: "$vuetify.menu.contact",
        name: "contact",
        href: "/contact",
      },
      {
        label: "$vuetify.menu.answer",
        name: "answer",
        href: "/answer",
      },
      {
        label: "$vuetify.menu.order",
        name: "order",
        href: "/order",
      },
      {
        label: "$vuetify.menu.personal",
        name: "personal",
        href: "/personalInfo",
      },
    ],
    user: {},
  }),
  created() {
    if (localStorage.getItem("locale")) {
      this.$vuetify.lang.current = localStorage.getItem("locale");
    } else {
      localStorage.setItem("locale", "zhHans");
    }
    this.currentLanguage = this.localeText;
    this.user = localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : {};
  },
  methods: {
    // 设置当前语言
    handleChangeLocale({ value }) {
      event.stopPropagation();
      this.$vuetify.lang.current = value;
      this.currentLanguage = this.localeText;
      localStorage.setItem("locale", value);
      this.drawer = false;
    },
    toUrl(path) {
      console.log(this.$route.path)
      if(path == this.$route.path) {
        this.$router.go(0);
      } else {
        this.$router.push(path);
        
      }
    },
  },
  computed: {
    // 遍历并获取项目中的所有语言包
    availableLanguages() {
      const { locales } = this.$vuetify.lang; // 所有的语言，来自 src/plugins/vuetify.js 中的配置
      return Object.keys(locales).map((lang) => {
        return {
          text: locales[lang].label, // 语言包显示的名字
          value: lang, // 语言包的值
        };
      });
    },
    // 当前语言, 用于语言切换菜单中显示的当前语言名字
    localeText() {
      console.log(this.availableLanguages, this.$vuetify.lang.current);
      const find = this.availableLanguages.find(
        (item) => item.value === this.$vuetify.lang.current
      );
      console.log(find);
      return find.text;
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-mobile {
  // .v-app-bar.v-app-bar--fixed {
  //   z-index: 7;
  // }

  // .v-navigation-drawer {
  //   top: 48px !important;
  //   height: calc(100% - 48px) !important;
  // }

  .item-active {
    background-color: #fed600;

    .theme--light.v-list-item--active:hover::before,
    .theme--light.v-list-item--active::before {
      opacity: 1;
    }
  }
}

::v-deep .v-toolbar__content,
.v-toolbar__extension {
  justify-content: space-between;
  padding-right: 0;
}

.theme--light.v-navigation-drawer {
  z-index: 999;
}
</style>
