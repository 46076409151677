<template>
  <v-container class="d-flex mb-8">
    <v-row>
      <v-col cols="12" md="4" lg="3" class="pr-0 d-none d-md-block">
        <div class="order-left mr-5 text-center">
          <v-avatar size="80">
            <v-img :src="user.headPic || require('../../assets/user/avatar.png')" cover width="30%"></v-img>
          </v-avatar>
          <div class="hr mb-4 mt-12"></div>
          <v-list nav class="pa-4 text-center">
            <v-list-item-group active-class="item-active" v-model="current">
              <v-list-item v-for="(item, index) in tabs" :key="index" @click="$router.push(item.href)">
                <v-list-item-title class="text-16">
                  {{ $vuetify.lang.t(item.label) }}
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
      </v-col>
      <v-col cols="12" class="d-md-none">
        <div class="order-left order-top text-center">
          <v-avatar size="80" class="mb-md-10 mb-5">
            <v-img :src="user.headPic || require('../../assets/user/avatar.png')" cover width="30%"></v-img>
          </v-avatar>
          <div class="d-md-none mb-5" @click="logoutFunc">{{ $vuetify.lang.t('$vuetify.logout') }}</div>
          <v-list nav class="text-center pa-0">
            <v-list-item-group active-class="item-active" v-model="current" class="d-flex align-center">
              <v-list-item v-for="(item, index) in tabs" :key="index" @click="$router.push(item.href)" class="mb-0">
                <v-list-item-title class="text-14">
                  {{ $vuetify.lang.t(item.label) }}
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
      </v-col>
      <v-col cols="12" md="8" lg="9">
        <div class="order-right pa-5 pa-md-8">
          <div class="form-title pb-md-8 pb-5 font-weight-bold">
            {{ $vuetify.lang.t("$vuetify.menu.order") }}
          </div>
          <div>
            <v-row>
              <v-col cols="12" md="6" lg="6" v-for="(item, index) in list" :key="index">
                <div class="order-item" @click="$router.push('/orderDetail/' + item.id)">
                  <div class="d-flex justify-space-between pb-3 align-center">
                    <div class="text-24 font-weight-bold">
                      {{ item.vehicleName }}
                    </div>
                    <div :class="[
                      'text-md-16',
                      'text-14',
                      item.payStatus == 1 ? 'color-FE9700' : 'text-000-5',
                    ]">
                      {{
                        item.orderStatus == 0
                            ? $vuetify.lang.t($enum.PAYSTATUS[item.payStatus])
                            : $vuetify.lang.t($enum.ORDERSTATUS[item.orderStatus])
                      }}
                    </div>
                  </div>
                  <div class="text-000-5 text-md-16 text-14 pb-2">
                    {{ $vuetify.lang.t("$vuetify.booking.text6_1") }}：{{
                      item.carRentalDate
                    }}
                  </div>
                  <div class="text-14 d-flex align-center pb-2">
                    <div class="mr-3">
                      <v-img src="../../assets/book/point_green.png"></v-img>
                    </div>
                    <div>{{ item.carRentalAddress }}</div>
                  </div>
                  <div class="text-14 d-flex align-center">
                    <div class="mr-3">
                      <v-img src="../../assets/book/point_red.png"></v-img>
                    </div>
                    <div>{{ item.returnCarAddress }}</div>
                  </div>
                  <div class="text-24 text-red pt-5 font-weight-bold">
                    JPY {{ $formatPrice(item.payAmount) }}
                  </div>

                  <div class="item-btn">
                    <v-btn plain class="mt-5 text-16" color="#EC5A4F"
                           v-if="item.payStatus == 4 || item.orderStatus == 3" @click.stop="delClick(item.id)">
                      {{ $vuetify.lang.t("$vuetify.del") }}
                    </v-btn>
                    <v-btn plain class="mt-5 text-16" color="#FED600" @click.stop="cancelClick(item.id)"
                           v-if="item.payStatus == 1">
                      {{ $vuetify.lang.t("$vuetify.cancel") }}
                    </v-btn>
                    <v-btn v-if="item.orderStatus == 3" :width="110" class="btn-submit mt-5 text-16"
                           @click.stop="$router.push('/orderComment/' + item.vehicleId + '/' + item.id)">
                      {{ $vuetify.lang.t("$vuetify.orderDetail.text19") }}
                    </v-btn>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
          <MyPigination class="mt-8 pr-0 pl-0" :total="totalCount" :size="size" @changePage="handleChangePage"></MyPigination>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MyPigination from "@/components/MyPigination";
import {orderList, orderDel, orderCancel, logout} from "../../api/index";

export default {
  name: "orderIndex",
  components: {
    MyPigination,
  },
  data: () => {
    return {
      tabs: [
        {
          label: "$vuetify.menu.personal",
          name: "personal",
          href: "/personalInfo",
        },
        {
          label: "$vuetify.menu.account",
          name: "updatePwd",
          href: "/updatePwd",
        },
        {
          label: "$vuetify.menu.order",
          name: "order",
          href: "/order",
        },
        // {
        //   label: "$vuetify.menu.payRecord",
        //   name: "payRecord",
        //   href: "/payRecord",
        // },
      ],
      current: 2,
      list: [],
      totalCount: 0,
      size: 10,
      page: 1,
    };
  },
  watch: {
    "$vuetify.lang.current": {
      immediate: true,
      handler() {
        // 语言切换时重新请求数据
        this.getList();
      },
    },
  },
  created() {
    // this.getList();
    this.user = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")) : {};
  },
  methods: {
    async getList() {
      let res = await orderList({
        page: this.page,
        size: this.size,
      });
      if (res.code == 200) {
        this.list = res.data.list;
        this.totalCount = res.data.count;
      }
    },
    async logoutFunc() {
      let res = await logout();
      if (res.code == 200) {
        localStorage.clear();
        this.$router.push('/login');
        window.location.reload();
      }
    },
    handleChangePage(page) {
      console.log(page);
      this.page = page;
      this.getList();
    },
    delClick(id) {
      orderDel({
        id: id,
      }).then((res) => {
        if (res.code == 200) {
          this.$SnackBar.show("删除成功", {type: "success"});
          this.getList();
        }
      });
    },
    cancelClick(id) {
      orderCancel({
        id: id,
      }).then((res) => {
        if (res.code == 200) {
          this.$SnackBar.show("取消成功", {type: "success"});
          this.getList();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.order-left {
  box-shadow: 0px 5px 45px 0px rgba(34, 53, 162, 0.08);
  // width: 25%;
  padding: 50px 42px 10rem;

  &.order-top {
    padding: 30px 15px 20px;

    .user-menu-item {
      width: 25%;
      margin: 0;
      border-radius: 6px;

      &.active,
      &:hover {
        font-weight: 500;
      }
    }
  }

  .hr {
    width: 100%;
    border-bottom: 1px dashed #ebebeb;
  }

  .user-menu-item {
    padding: 10px 0;
    cursor: pointer;
    margin: 10px 0;
  }

  .user-menu-item:hover {
    font-weight: bold;
  }

  .user-menu-item.active {
    background-color: #fed600;
    font-weight: bold;
  }

  .item-active {
    background-color: #fed600;

    .theme--light.v-list-item--active:hover::before,
    .theme--light.v-list-item--active::before {
      opacity: 1;
    }
  }

  ::v-deep .v-list-item--link:before {
    background-color: transparent;
  }
}

.order-right {
  box-shadow: 0px 5px 45px 0px rgba(34, 53, 162, 0.08);
  // width: 75%;

  .order-item {
    border-radius: 14px;
    border: 1px solid rgba(151, 151, 151, 0.6);
    padding: 20px;
    cursor: pointer;
    position: relative;

    .item-btn {
      position: absolute;
      bottom: 20px;
      right: 20px;
    }
  }
}

@media (max-width: 960px) {
  .order-right {
    .order-item {
      .item-btn {
        position: static;
        text-align: right;
      }
    }
  }
}
</style>
