import request from "@/utils/request";


// 上传图片 /fileService/v1/com/upload
export function upload(data) {
  return request({
    url: "/fileService/v1/com/upload",
    method: "post",
    data,
    baseURL: process.env.VUE_APP_FILE_API,
    headers: { 'Content-Type': 'multipart/form-data' }
  });
}


// 登录 POST /api/v1/user/login
export function login(data) {
  return request({
    url: "/user/login",
    method: "post",
    data,
  });
}

// 退出 /api/v1/user/logout
export function logout() {
  return request({
    url: "/user/logout",
    method: "post",
  });
}

// 注册 /api/v1/user/register
export function register(data) {
  return request({
    url: "/user/register",
    method: "post",
    data,
  });
}

// 获取验证码 /api/v1/com/seed/code
export function getCode(data) {
  return request({
    url: "/com/seed/code",
    method: "post",
    data,
  });
}

// 忘记密码 /api/v1/user/forgetPwd
export function forgetPwd(data) {
  return request({
    url: "/user/forgetPwd",
    method: "post",
    data,
  });
}

// 协议列表 /api/v1/agreement/list
export function agreementList(data) {
  return request({
    url: "/agreement/list",
    method: "post",
    data,
  });
}

// 修改个人信息 /api/v1/user/update
export function update(data) {
  return request({
    url: "/user/update",
    method: "post",
    data,
  });
}

// 修改密码 /api/v1/user/updatePwd
export function updatePwd(data) {
  return request({
    url: "/user/updatePwd",
    method: "post",
    data,
  });
}

// 轮播图列表 /api/v1/carouselMap/list
export function carouselMapList(data) {
  return request({
    url: "/carouselMap/list",
    method: "post",
    data,
  });
}

// 意见反馈 /api/v1/feedback/add
export function feedback(data) {
  return request({
    url: "/feedback/add",
    method: "post",
    data,
  });
}

// 车辆列表 /api/v1/vehicles/list
export function vehiclesList(data) {
  return request({
    url: "/vehicles/list",
    method: "post",
    data,
  });
}

// 车型列表 /api/v1/vehicles/type
export function vehiclesType(data) {
  return request({
    url: "/vehicles/type",
    method: "post",
    data,
  });
}

// 品牌列表 /api/v1/vehicles/brand
export function vehiclesBrand(data) {
  return request({
    url: "/vehicles/brand",
    method: "post",
    data,
  });
}

// 车辆详情 /api/v1/vehicles/info
export function vehiclesInfo(data) {
  return request({
    url: "/vehicles/info",
    method: "post",
    data,
  });
}

// 评论列表 /api/v1/comment/list
export function commentList(data) {
  return request({
    url: "/comment/list",
    method: "post",
    data,
  });
}

// 生成订单 /api/v1/order/generate
export function orderGenerate(data) {
  return request({
    url: "/order/generate",
    method: "post",
    data,
  });
}

// 订单列表 /api/v1/order/list
export function orderList(data) {
  return request({
    url: "/order/list",
    method: "post",
    data,
  });
}

// 择车配置 /api/v1/vehicles/config
export function vehiclesConfig(data) {
  return request({
    url: "/vehicles/config",
    method: "post",
    data,
  });
}

// 订单详情 /api/v1/order/info
export function orderInfo(data) {
  return request({
    url: "/order/info",
    method: "post",
    data,
  });
}

// 支付 /api/v1/pay/order
export function payOrder(data) {
  return request({
    url: "/pay/order",
    method: "post",
    data,
  });
}

// 新增评论 /api/v1/comment/add
export function commentAdd(data) {
  return request({
    url: "/comment/add",
    method: "post",
    data,
  });
}

// 删除订单 /api/v1/order/del
export function orderDel(data) {
  return request({
    url: "/order/del",
    method: "post",
    data,
  });
}

// 取消订单 /api/v1/order/cancel
export function orderCancel(data) {
  return request({
    url: "/order/cancel",
    method: "post",
    data,
  });
}

// 获取轮播图 /api/v1/carouselMap/list
export function getCarouselMapList(data) {
  return request({
    url: "/carouselMap/list",
    method: "post",
    data,
  });
}

// 包车
export function charteredBus(data) {
  return request({
    url: "/vehicles/charteredBus",
    method: "post",
    data,
  });
}