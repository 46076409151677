<template>
  <!-- <v-snackbar
    top 
    v-model="snackbar.show"
    :timeout="snackbar.timeout"
    content-class="888"
    @input="onInput"
  >
    {{ snackbar.text }}
   
  </v-snackbar> -->
  <div :class="['snackbar', snackbar.type]" v-if="snackbar.show">
    <div class="message">{{ snackbar.text }}</div>
  </div>
</template>

<script>
export default {
  name: 'SnackBar',
  data() {
    return {
      snackbar: {
        show: false,
        text: '',
        type: 'error',
        timeout: 3000
      }
    };
  },
  created() {
    setTimeout(() => {
      this.snackbar.show = false;
    }, this.snackbar.timeout);
  },
  methods: {
    onInput(value) {
      if (!value) {
        this.snackbar.text = '';
      }
    },
    show(message, options = {}) {
      console.log('options---',options)
      this.snackbar.text = message;
      this.snackbar.type = options.type || this.snackbar.type;
      this.snackbar.timeout = options.timeout || this.snackbar.timeout;
      this.snackbar.show = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.snackbar {
  position: fixed;
  top: 40px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  height: 50px;
  z-index: 9999;
  line-height: 50px;
  z-index: 9999;
  padding: 0 20px;
  &.error {
    background-color: #fef0f0;
    border: 1px solid #fde2e2;
    color: #f56c6c;

  }
  &.success {
    background-color: #f0f9eb;
    border: 1px solid #e1f3d8;
    color: #67c23a;
  }
}
</style>