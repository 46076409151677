import ko from 'vuetify/es5/locale/ko' // 导入Vuetify 自带的简体中文语言包

export default {
    label: '한국어',
    // page login
    login: '로그인',
    register: '등록하다',
    username: '사용자 이름',
    password: '비밀번호',
    login_account: '로그인 계정',
    submit: '제출하다',
    logout: '로그인을 종료하십시오',
    none: '데이터 없음',
    seat: '좌석',
    day: '일',
    cancel: '취소',
    del: '삭제',
    bookText: '예약하다',
    week: '주',
    month: '달',
    year: '년도',
    cDay:'하늘',
    // menu
    menu: {
        home: '첫 페이지',
        free: '무료로 렌터카',
        about: '우리에 대해',
        book: '차량을 예약하다',
        contact: '연락처',
        answer: '자주하는 질문',
        order: '주문 기록',
        personal: '개인 정보',
        account: '계정 보안',
        payRecord: '지불 기록',
        charter: '전세 투어',
        consult: '우리에게 상담하세요'
    },
    charter: {
        text1: '도요타 알파',
        text2: '도요타 하이에이스',
        text3: '* 공항 이동, 테마파크 이동 등은 모두 편도 가격입니다.',
        text4: '오사카 환승',
        text5: '교토 환승',
        text6: '유니버셜 스튜디오 오사카',
        text7: '오사카(시내 왕복)',
        text8: '교토(시내 왕복)',
        text9: '오사카, 나라, 교토 (당일 왕복)',
        text10: '올인클루시브 가격/중국인 운전기사/공항 픽업 및 샌딩 가능<br>※ 기타 기종 필요시 비성수기, 성수기, 예약상황에 따라 가격이 소폭 변동될 수 있으니 꼭 확인해주세요. 두 번 확인해야 합니다.',
        text11: '간사이 지역',
        text12: 'Qingsong 렌터카 회사에 문의하세요',
        alphard: '최대 6명',
        hiace: '최대 9명'
    },
    consult: {
        text1: '귀하의 요구 사항을 선택하십시오:',
        text2: '[렌터카 문의]',
        text3: '[렌탈 서비스 문의]',
        text4: '성',
        text5: '이름',
        text6: '문의자의 위치를 ​​선택해주세요',
        text7: '일본에서의 생활',
        text8: '일본 밖에서 생활하기',
        text9: '둘 다 (여러 명이 상담하는 경우)',
        text10: '우편번호',
        text11: '하이픈 없이 입력하세요.',
        text12: '点击此处进行邮政编码搜索',
        text13: '장소',
        text14: '이메일',
        text15:'전화 번호',
        text16: '차량을 렌트하실 경우, 원하시는 차량 종류와 이용 기간을 입력해주세요.',
        text17: '차량을 렌트하고 싶으신 경우, 아래에서 차량 종류를 선택해 주세요.',
        text18: '경자동차',
        text19: '하이브리드 전기 자동차',
        text20: '밴/미니밴',
        text21: '소형차',
        text22: '소형 트럭/트럭',
        text23: '기타',
        text24: '다른 모델',
        text25: '다른 모델 또는 그 이상의 모델을 원하시면 아래 정보를 입력해 주세요.',
        text26: '렌터카 추가 기능 선택：',
        text27: 'ETC 차량',
        text28: '자동차 네비게이션',
        text29: '운전 녹음기',
        text30: '자동차 운송업자',
        text31: '4WD 4륜 구동(소형 상용차 및 경밴에만 적용 가능)',
        text32: '스터드리스 미끄럼 방지 타이어',
        text33: 'LINE, WeChat ID 등의 연락처 정보',
        text34: '원하는 시작 시간',
        text35: '원하는 시작일을 선택해주세요',
        text36: '예상 기간',
        text37: '숫자를 입력하세요',
        text38: '원하는 기간 단위',
        text39: '운전자 수：',
        text40: '사람들',
        text41: '~ 위에',
        text42: '(렌탈 서비스만 해당)',
        text43: '필요한 차량 수：',
        text44: '자동차',
        text45: '또는 그 이상',
        text46: '질문, 의견 등',
        text47: '보내다',
        text48: '이메일로 문의하기'
    },
    user: {
        text1: '사서함/휴대 전화 번호를 입력하십시오',
        text2: '비밀번호를 입력하십시오',
        text3: '비밀번호를 잊어 버리십시오',
        text4: '지금 등록하십시오',
        text5: '사서함을 검색하십시오',
        text6: '회복',
        text7: '휴대폰 번호를 입력하십시오',
        text8: '확인 코드를 입력하십시오',
        text9: '비밀번호를 다시 입력하십시오',
        text10: '사서함을 입력하십시오',
        text11: '확인 코드를 가져옵니다',
        text12: '비밀번호를 변경하십시오',
        text13: '지역번호를 선택해주세요',
        text14: '닉네임',
        text15: '닉네임을 입력해주세요',
        text16: '화신',
        text17: '구하다',
        text18: '우편',
        text19: '휴대폰',
        text20: '성공적으로 저장되었습니다',
        text21: '존경하는 회원',
        text22: '비밀번호 변경',
        text23: '현재 비밀번호',
        text24: '새 비밀번호',
        text25: '새 비밀번호 확인',
        text26: '현재 비밀번호를 입력해주세요',
        text27: '새로운 비밀번호를 입력해주세요',
        text28: '새 비밀번호를 다시 입력해주세요',
        text29: '두 번 입력한 비밀번호가 일치하지 않습니다.',
        text30: '읽었거나 동의함을 표시하려면 로그인하거나 등록하려면 클릭하세요.',
        text31: 'Utor 자동차 렌탈 계약',
        text32: '개인 정보 보호 정책',
        text33: '이메일 등록',
        text34: '휴대폰 등록',
        text35: '올바른 이메일 주소를 입력해주세요',
        text36: '정확한 휴대폰번호를 입력해주세요',
        text37: '인증코드가 전송되었습니다'
    },
    home: {
        text1: '지금 보기',
        title1: '자동차 렌트 과정',
        title2: '인기 차량',
        title3: '다국어 지원',
        text2: '단 3단계만 거치면 자동차 요구 사항을 쉽게 충족할 수 있습니다.',
        text3: '차량 선택',
        text4: '필요한 차량 모델을 선택하고 예약을 클릭하세요.',
        text5: '정보를 입력하세요',
        text6: '차량 시간을 선택하고 개인정보를 입력하세요.',
        text7: '공항 픽업 차량',
        text8: '입력하신 차량 정보를 바탕으로 지정된 장소에서 차량을 픽업하세요.',
        text9: '더 보기',
        text10: '다국어 서비스를 제공합니다'
    },
    about: {
        title: '회사 소개',
        content: '회사 소개 페이지의 내용은 다음과 같습니다.',
        text1: 'UTOR를 선택하는 이유',
        text2: 'UTOR를 선택하는 이유',
        text3: '고객 평가',
        text4: '소셜 미디어',
        text5: '라이프 스타일이 변화함에 따라 자동차 라이프에 대한 사람들의 요구도 점점 다양해지고 있습니다. 유토렌터카는 고객에게 깨끗하고 편리하며 안전하고 세심한 렌터카 서비스를 제공하기 위해 최선을 다하고 있습니다. 또한, 저희 역시 고객의 입장에서 생각하는 아이디어에 귀를 기울이고, 더 많은 고객님께 안전하고 편안한 렌터카 서비스를 제공할 수 있도록 계속해서 노력하겠습니다.\n' +
            '여행 시 차량 선택에 대한 조언이 필요하시면 언제든지 연락주세요.',
        text6: '풍부한 자동차 모델 선택',
        text7: '혼자 여행하든, 가족이나 친구와 함께 여행하든, 우리는 귀하에게 딱 맞는 차량을 보유하고 있습니다. 합리적인 가격의 소형 자동차부터 넓고 편안한 고급 MPV까지 당사의 차량은 모든 유형을 포괄하여 여행 중 최고의 운전 경험을 보장합니다.',
        text8: '가족을 위한 편리한 서비스',
        text9: '아이들의 안전과 편안함을 보장하기 위해 가족 여행을 위한 어린이용 카시트를 특별히 준비했습니다. 또한, 모든 차량에는 ETC 장비가 장착되어 있어 일본의 고속도로 요금소를 빠르게 통과할 수 있어 줄을 서야 하는 번거로움이 없어져 귀중한 이동 시간을 절약할 수 있습니다.',
        text10: '투명한 가격 책정 메커니즘',
        text11: '칭송에서 자동차를 렌트할 때 숨겨진 비용에 대해 걱정할 필요가 없습니다. 예약 시 모든 비용이 명확한 투명한 가격 시스템을 제공하므로 추가 비용 걱정 없이 여행을 즐기실 수 있습니다.',
        text12: '연중무휴 다국어 고객 서비스 지원',
        text13: '우리는 이국적인 장소를 여행할 때 의사소통의 중요성을 이해합니다. 따라서 당사의 고객 서비스 팀은 24시간 다국어 지원을 제공하므로 여행 중 언제든지 문제가 발생하더라도 적시에 도움을 드릴 수 있습니다.',
        text14: '간사이공항 독점 서비스',
        text15: '더욱 편리한 여행을 위해 간사이 국제공항에서 전용 차량 픽업 및 반납 서비스를 제공합니다. 일본에 막 도착하셨거나 출국을 준비 중이시라면, 공항에서 렌터카나 반납 절차를 쉽게 완료하여 걱정 없이 여행을 시작하거나 마무리하실 수 있습니다.',
        text16: '포괄적인 보안',
        text17: '안전은 우리의 최우선 사항입니다. 모든 차량은 렌트 전 엄격한 안전 검사를 거치며, 전체 보험이 적용되어 있어 일본 내에서 매 킬로미터마다 더욱 안심하고 운전하실 수 있습니다.',
        text18: 'Qingsong Car Rental은 일본을 방문하는 모든 여행자에게 일류 렌터카 서비스를 제공하여 귀하의 여행이 편안할 뿐만 아니라 걱정도 없도록 보장하기 위해 최선을 다하고 있습니다. 우리가 제공하는 것은 단순한 자동차가 아니라 귀하에게 꼭 맞는 완벽한 여행 솔루션 세트입니다. Qingsong Car Rental을 선택하는 6가지 이유는 다음과 같습니다.',
        text19: 'Qingsong Car Rental은 귀하의 여행 파트너일 뿐만 아니라 일본에서의 사려깊은 가이드이기도 합니다. 여러분의 일본 여행을 더욱 기억에 남는 즐거운 여행으로 만들어드리겠습니다. 우리는 귀하의 서비스를 기대하며 즐거운 여행을 기원합니다!'
    },
    contact: {
        title: '문의하기',
        text1: '문의하기',
        text2: '성심성의껏 서비스해드리겠습니다',
        text3: '문제 피드백',
        text4: '당신의 이름',
        text5: '당신의 이메일',
        text6: '콘텐츠',
        text7: '이름을 입력하세요',
        text8: '이메일을 입력해주세요',
        text9: '피드백을 주고 싶은 내용을 입력해주세요',
    },
    answer: {
        title: '',
        text1: '일본에서 운전하려면 어떤 운전면허증이 필요합니까?',
        text2: '제네바 협약에 명시된 형식의 유효한 국제 운전 면허증이 필요합니다. ※스위스, 독일, 프랑스,<a href="/#/license">세부 사항을 확인하세요</a><div style="text-indent: 1.5em;"> ​​벨기에, 대만, 모나코 운전면허증을 소지한 분은 일본자동차연맹 또는 대사관에서 발행한 공식 일본어 번역문을 제출해야 합니다.</div>',
        text3: '신청자 외에 다른 사람이 운전할 수 있나요?',
        text4: '할 수 있다. 단, 렌터카 운전자는 모두 신청해야 하므로 출발 당일 매장 내 모든 운전자의 운전면허증을 제시해 주시기 바랍니다.',
        text5: '예약하지 않고도 차량을 렌트할 수 있나요?',
        text6: '매장에 공석이 있으면 당일 렌트도 가능합니다. 단, 예약이 너무 많아 차량 이용이 불가능할 수 있으니 사전에 예약하시는 것을 권장드립니다.',
        text7: '예약 취소 시 취소 수수료를 지불해야 하나요?',
        text8: '고객이 개인적인 사유로 예약을 취소하는 경우, 회사 규정에 따라 예약 취소 수수료(취소 수수료)가 부과됩니다. 7일 전에 취소하시면 취소 수수료가 없습니다. 출발 예정일로부터 6일 이내에 취소하는 경우 취소 수수료가 부과됩니다.',
        text9: '불법 주차를 했다면 어떻게 해야 할까요?',
        text10: '티켓이 발부된 경우에는 가까운 경찰서에 방문하여 절차를 완료하고 벌금을 납부한 후 반납하시기 바랍니다. 차량 반납시에는 \'교통위반 통지서\'와 납부일이 기재된 \'납부서, 영수증 등\'을 제시해 주시기 바랍니다. 차량 반납 시 \'교통 위반 통지서\', \'배달 편지, 영수증 증명서 등\'을 제시하지 않은 경우 30,000엔의 불법주차 과태료가 부과됩니다. <div style="text-indent: 1.5em;"> ※추후 벌금을 납부하신 후 \'교통법규 위반 통지서\' 및 납부일이 기재된 \'납부서, 영수증 등\'을 제시하신 후 당사에서는 불법주차 금액에 상당하는 수수료를 환불해 드립니다. 환불금액은 지정계좌로 입금됩니다. (송금수수료는 고객부담입니다.)</div><div style="text-indent: 1.5em;">※보증금 지급을 위한 주문을 받은 후 회사가 보증금을 지급하거나, 고객이 이를 이행하지 않을 경우 회사가 지정한 날짜까지 전액을 납부하셔야 하며, 소정의 요금을 납부하시면 전국자동차렌터카협회 정보관리시스템에 등록되는 등의 조치가 이루어지오니 유의하시기 바랍니다. 협회의 렌터카 대리점은 향후 고객의 렌터카 요청을 거부할 수 있습니다. </div>',
        text11: '출국 시 어떤 절차를 완료해야 합니까?',
        text12: '회사는 출국 수속 시 모든 운전자의 운전면허증 및 여권을 확인하고, 고객에게 이용규칙 확인을 요청합니다. 모든 절차를 완료한 후, 계약서에 따라 예약금을 결제하셔야 합니다. (출국 시 정산됩니다.) <div style="text-indent: 1.5em;"> ※사전에 요금이 정산된 경우에는 이 단계를 무시하셔도 됩니다.차량 상태에 대한 간단한 설명을 드린 후, 직원이 고객과 함께 차량 표면의 흠집 등을 육안으로 검사합니다. 본 점검이 완료되면 차량을 이용하실 수 있습니다.',
        text13: '영업시간 외에 차량을 인수하거나 반납할 수 있나요?',
        text14: '출국 및 귀국 절차는 반드시 회사 업무시간 내에 완료되어야 합니다.',
        text15: '등록된 회원이 운전면허증을 잊어버린 경우 자동차를 렌트할 수 있나요?',
        text16: '출국 시 유효한 운전면허증과 여권을 제시하지 못할 경우 대여가 불가능하오니 반드시 당일 지참하시기 바랍니다.',
        text17: '차량 반납 시 주의할 점은 무엇인가요?',
        text18: '차량을 반납하기 전에 잊어버린 물건이 없는지 확인하시기 바랍니다. 분실물 등의 분실에 대해서는 당사에서는 책임을 지지 않습니다. 양해해 주시기 바랍니다.',
        text19: '차량 반납 시 연료를 가득 채울 수 없으면 어떻게 해야 하나요?',
        text20: '차량은 당사로 직접 반납해 주시기 바랍니다. 회사는 고객에게 실제 이동 거리를 기준으로 소정의 대리점 주유 요금을 청구합니다.',
    },
    free: {
        title: '자동차를 렌트해 자유롭게 여행해보세요',
        text1: '여행을 위해 자동차를 렌트하는 이유는 무엇인가요？',
        text2: '원하는 대로 자유롭게 여행하세요',
        text3: '자동차를 렌트해 원하는 대로 편리하고 편안하게 여행하세요. 정해진 노선과 시간표의 제약을 벗어나 언제 어디서나 미지의 아름다움을 탐험해보세요. 원할 때 멈추고, 원할 때 갈 수 있으며, 모든 정류장을 결정할 수 있습니다.',
        text4: '깊이있게 플레이하고 보이지 않는 아름다움을 탐험해보세요',
        text5: '대중교통의 시간적 제약을 없애고, 언제 어디서나 좋아하는 목적지를 방문하여 환승을 기다리는 시간을 절약해보세요. 전세 자동차나 택시에 비해 여행 비용을 보다 효과적으로 관리할 수 있습니다.',
        text6: '자유롭게 탐색하고 개인적으로 여행을 즐기세요',
        text7: '가족, 친구들과 친밀한 시간을 공유할 수 있는 독립적인 개인 공간을 확보하세요. 좌석과 수납공간이 넉넉해 여행이 더욱 편안해집니다. 더 높은 수준의 자유, 프라이버시 및 편안함을 누릴 수 있습니다.',
        text8: '온 가족이 모여 여행을 즐겨보세요',
        text9: '모든 럭셔리 유모 자동차 시리즈에는 고급스러운 인테리어, 멀티미디어 엔터테인먼트 시스템 등이 장착되어 있습니다. 초대형 내부 공간은 여행 중 노인과 어린이의 편안함을 더욱 자유롭고 편안하게 보장합니다.',
        text10: '최고의 서비스',
        text11: '최고의 품질의 서비스를 제공해드립니다',
        text12: '심층적인 여행 일정 제안',
        text13: '무료로 심층적인 여행 조언을 제공합니다.',
        text14: '공항 픽업 및 샌딩 서비스',
        text15: '공항 픽업 및 샌딩 서비스를 제공합니다.',
        text16: '배달 서비스',
        text17: '차량배달 서비스를 제공해드립니다'
    },
    book: {
        title: '차량 예약',
        text1: '검색어를 입력해주세요',
        text2: '찾다',
        text3: '모든 모델',
        text4: '모든 브랜드',
        text5: '승객 수',
        text6: '임대',
        text7: '이류',
        text8: '리뷰',
        text9: '가장 빠른 사용 가능 날짜',
        text10: '사용자 리뷰',
        text11: '정렬',
        text12: '가격'
    },
    booking: {
        title: '지금 예약하세요',
        text1: '부커의 이름',
        text2: '예약자 성함을 입력해주세요',
        text3: '전화 번호',
        text4: '지역번호를 선택하세요',
        text5: '휴대폰번호를 입력해주세요',
        text5_1: '정확한 휴대폰번호를 입력해주세요',
        text6: '렌터카 날짜',
        text7: '대여 날짜를 선택하세요.',
        text6_1: '렌터카 시간',
        text7_1: '렌터카 이용 시간을 선택해주세요.',
        text8: '귀국일',
        text9: '귀국 날짜를 선택하세요.',
        text8_1: '반환 시간',
        text9_1: '반납시간을 선택해주세요',
        text10: '렌터카 위치',
        text11: '렌터카 위치를 선택해주세요.',
        text12: '반납 장소',
        text13: '하차 장소를 선택해주세요.',
        text14: '여권번호',
        text15: '대괄호를 입력할 필요가 없습니다.',
        text16: '여권번호를 입력해주세요',
        text17: '생일',
        text18: '생년월일을 선택해주세요',
        text19: '어린이용 카시트',
        text20: '법적으로 6세 미만의 어린이는 어린이용 안전시트 또는 유아용 안전시트를 사용해야 합니다.',
        text21: 'ETC',
        text21_1: 'ETC를 선택해주세요.',
        text22: 'ETC가 필요하지 않습니다',
        text23: 'ETC 필요',
        text24: '일부 매장에서는 ETC 기기 및 카드를 제공하지 않습니다.',
        text25: '보험 보상 제도',
        text25_1: '보험보상제도를 선택해 주세요',
        text26: '렌터카 이중 보호 플랜',
        text27: '보상에 대한 면책 ​​조항',
        text28: '가입하지 마세요',
        text29: '면제 보상 제도에 관한 정보입니다. 상세예약정보에는 보상면제와 영업손실보상(NOC) 항목이 별도로 기재되어 있습니다.',
        text30: '운전면허 종류',
        text31: '귀하가 소지하고 있는 운전면허증의 종류를 다음 중에서 선택해 주십시오.',
        text32: '1.제네바협약에 의거한 국제운전면허증(최근 1년 이내 발급)',
        text33: '2.스위스, 독일, 프랑스, ​​벨기에, 대만, 모나코 등의 국가에서 발행한 운전 면허증과 운전 면허증의 일본어 번역본을 소지',
        text34: '3.일본에서 발행된 운전면허증',
        text35: '상기 1, 2의 운전면허는 고객이 일본에 도착한 날로부터 1년 또는 운전면허의 유효기간 중 빠른 날짜까지 유효합니다. (일본인 주민등록표 소지자가 출국 확인 또는 재입국 허가 취득 후 출국한 후 3개월 이내에 재입국하는 경우, 귀국(입국)일은 운전 시작일로 간주되지 않습니다. 일본.) 여권에 찍힌 입국 스탬프를 통해 입국 날짜를 확인합니다. 자동통관 게이트를 이용하여 입국하실 경우, 여권에 입국 도장이 찍혀 있지 않은 경우 렌터카 서비스가 제공되지 않습니다.',
        text36: '선택해주세요',
        title2: '항공편 정보',
        text37: '도착하다',
        text38: '항공사를 입력해주세요',
        text39: '항공편명을 입력해주세요',
        text40: '왕복 여행',
        text41: '쿠폰',
        text42: '주문 금액을 공제하려면 쿠폰 코드를 입력하세요.',
        text43: '가격 세부정보',
        text44: '총 가격',
        text45: '쿠폰 차감',
        text46: '일시금',
        text47: '옵션 무료',
        text48: '여기를 클릭하세요'
    },
    orderDetail: {
        title1: '주문 진행',
        title2: '결제수단',
        title3: '주문정보',
        title4: '렌터카 체크리스트',
        text1: '지불 예정',
        text2: '주문이 제출되었습니다. 최대한 빨리 결제해 주세요.',
        text3: '매장 확인중입니다',
        text4: '배정 차량',
        text5: '픽업 예정 차량',
        text6: '자동차에서 사용 중',
        text7: '주문 완료',
        text8: '은행카드 결제',
        text9: '위챗페이',
        text10: '알리페이 결제',
        text11: '주문 번호',
        text12: '주문 시간',
        text13: '부커의 이름',
        text14: '전화 번호',
        text15: '여권번호',
        text16: '렌터카 지점',
        text17: '반환 지점',
        text18: '지금 결제',
        text19: '평가하다',
        text20: '가격 세부정보',
        text21: '총 가격',
        text22: '일시금'
    },
    orderComment: {
        text1: '여기에 평가 내용을 입력해 주세요. 귀하의 승인이 우리의 가장 큰 지원입니다!',
        text2: '리뷰를 남겨주세요'
    },
    footer: {
        text1: '문의하기',
        text2: '전화',
        text3: '우편',
        text4: '주소',
        text5: '회사 소개',
        text6: '회사 프로필',
        text7: '고객 평가',
        text8: '소셜 미디어',
        text9: '차량 예약',
        text10: '지금 예약하세요',
        text11: '문의하기',
        text12: '문제 피드백',
        text13: '주문기록',
        text14: '내 여행 일정',
        text15: '지난 여행 일정',
        text16: '오사카부 이즈미군 구마치마치 오쿠보쿠 3-192-6',
        text17: '회사명',
        text18: '바오위안무역유한회사',
        text19: '고객'
    },
    pigination: {
        text1: '총',
        text2: '기록',
        text3: '아니요.',
        text4: '페이지',
        text5: '이전 페이지',
        text6: '다음 페이지',
        text7: '도약'
    },
    msg: {
        text1: '로그인 성공',
        text2: '등록 성공',
        text3: '비밀번호가 성공적으로 변경되었습니다.',
        text4: '댓글 성공',
        text5: '축하합니다. 주문 결제가 성공적으로 이루어졌습니다.',
        text6: '결제해 주셔서 감사합니다!',
        text7: '결제 실패, 사용자가 결제 취소',
        text8: '결제가 취소되었습니다.',
        text9: '주문 보기',
        text10: '기재되지 않은 정보가 있습니다. 확인해주세요.'
    },
    errorCode: {
        10001: '시스템 예외',
        10002: '데이터가 존재하지 않습니다',
        10003: '이미지만 지원（png、png、jpeg）',
        10004: '인증코드 수신 예외',
        10005: '인증코드 오류',
        10006: '잘못된 비밀번호',
        10007: '계정 정보가 존재하지 않습니다',
        10008: '매개변수 예외',
        10009: '주문정보가 존재하지 않습니다',
        10010: '현재 주문이 완료되지 않았으므로 댓글을 달 수 없습니다.',
        10011: '원래 비밀번호가 틀렸어요',
        10012: '차량이 존재하지 않습니다',
        10013: '차량이 부족함',
        10014: '현재 주문은 삭제할 수 없습니다.',
        10015: '현재 주문은 취소할 수 없습니다.',
        10016: '계정이 등록되었습니다',
        10017: '렌터카 시작 시간이 비정상적이며 현재 이용 가능한 차량이 없습니다.',
        511: '로그인이 만료되었습니다. 다시 로그인해 주세요.',
        402: '로그인이 만료되었습니다. 다시 로그인해 주세요.'
    },
    enum: {
        text1: '차량 가격',
        text2: '어린이용 카시트',
        text3: 'ETC',
        text4: '보험',
        text5: '지불되지 않음',
        text6: '유급의',
        text7: '환불됨',
        text8: '취소',
        text9: '픽업 예정 차량',
        text10: '픽업된 자동차',
        text11: '완전한'
    },
    address: {
        text1: '간사이공항'
    },
    //驾照单页
    driving: {
        title: '운전면허에 대해서',
        desc: '일본에서 운전하는데 필요한 운전면허증과 관련 정보를 소개합니다. <br/>최근에는 자동차 렌트 활동을 위해 국제 운전 면허증을 위조하는 현상이 점점 더 빈번해지고 심각한 문제가 되고 있습니다. 고객의 여권이 국제운전면허증 발급국가와 일치하지 않는 경우, 직원이 여권에 기재된 출입국 기록을 확인할 수 있습니다. 여권에 국제운전면허증을 발급한 국가의 출입국 기록이 없을 경우, 국제운전면허증을 발급한 국가의 거주증명서를 반드시 지참하시기 바랍니다. <br/>예시 : 국제운전면허증을 발급한 국가의 신분증, 운전면허증, 거주허가증 등 <br/>국제운전면허증을 인정된 기관에서 취득하였는지 확인할 수 없는 경우 발급국가별, 고객이 보유한 국제운전면허증의 유효성 등을 입증할 수 없는 경우 회사는 렌터카 서비스 제공을 거부할 수 있습니다. <br/>*W6 모델 렌트 시, 10인승 차량을 운전할 수 있는 유효한 운전면허증이 필요합니다.',
        detail: [
            {
                title: '국제운전면허증을 소지한 고객',
                desc: '여권과 유효한 국제 운전 면허증이 필요합니다. <br/>국제 운전 면허증은 1949년 국제 도로 교통에 관한 제네바 협약을 준수해야 합니다. <br/> ※국제운전면허증의 유효기간은 발급 후 1년, 일본에서의 유효한 운전기간은 입국일로부터 1년입니다. (일본에 입국한 연월일 스탬프로 확인) 여권에). <br/>※\'자동 게이트\'를 이용하여 세관을 통과하실 경우, 세관 통과 후 직원에게 입국 스탬프(또는 상륙 허가 스티커)를 받아주시기 바랍니다. 스탬프(또는 하선허가 스티커)가 없을 경우 입국/재입국 날짜가 확인되지 않아 차량 렌트가 불가능합니다. <br/> ※일본 주민등록부에 기재되어 있거나 외국인등록을 한 사람이 일본에서 출국한 후 3개월 이내(총합이 아닌 연속) 이내에 재입국하는 경우에는 재입국일 "도착일"(국제 운전 면허증의 유효 기간을 계산하는 데 "도착일"을 사용함)로 간주되지 않으며, 규정을 충족하지 않아 자동차를 렌트할 수 없습니다. 일본에서 운전하기.',
                text: '국제 운전 면허증',
                text1: '유효한 여권',
            },
            {
                title: '프랑스, 독일, 스위스, 모나코, 벨기에, 대만 등의 운전면허증을 소지한 고객',
                desc: '일본 교통 법규에 따라 운전 시 다음 정보를 지참해야 합니다. 출발 매장에서 직원에게 보여주세요. <br/>※\'자동 게이트\'를 이용하여 세관을 통과하실 경우, 세관 통과 후 직원에게 입국 스탬프(또는 상륙 허가 스티커)를 받아주시기 바랍니다. 스탬프(또는 하선허가 스티커)가 없을 경우 입국/재입국 날짜가 확인되지 않아 차량 렌트가 불가능합니다. <br/>※외국 운전면허증을 소지하고 일본 주민등록부에 기록되거나 외국인 등록을 한 경우, 일본 출국 후 3개월 이내(계산이 아닌 연속)인 경우 기한 내에 일본에 재입국하는 경우, 일본에 재입국한 날은 \'상륙일\'(외국 운전면허증의 유효기간 산정에는 \'상륙일\'을 사용함)로 간주하지 않으며, 귀하가 일본 운전 규정을 충족하지 못하기 때문에 자동차를 렌트할 수 없습니다.',
                text: '유효한 국내 운전 면허증',
                text1: '유효한 여권',
                text2: '공식 번역 문서'
            }
        ],
        //翻译文书
        translate: {
            title: '공식 번역 서류를 구하는 방법',
            desc: '공식 일본어 번역 문서는 다음 기관에서 구할 수 있습니다.。',
            detail: '・운전면허증 발급 기관 또는 해당 국가의 대사관 또는 영사관<br/>・일본 자동차 연맹(JAF)<br/>・독일 자동차 협회(ADAC) ※독일 운전 면허증에 한함<br/>・대만-일본 교류 협회 ※대만 운전 면허증에 한함<br/>・ZIPLUS Co., Ltd. ※대만 운전 면허증에 한함<br/>'

        }
    },
    order: {
        // text1: '租车时间',
    },
    // 展开 简体中文语言包的所有项目
    ...ko,
}
