<template>
  <div>
    <div class="banner">
      <h3 class="text-36 text-30 font-weight-black pb-md-2 aa">{{ $vuetify.lang.t("$vuetify.answer.title") }}</h3>
      <h3 class="text-36 text-30 font-weight-medium tt">FAQ</h3>
    </div>
    <v-container>
      <div class="text-md-28 text-20 font-weight-bold text-center pt-md-15 pt-10 pb-md-15 pb-6">{{
        $vuetify.lang.t("$vuetify.answer.title") }}</div>
      <div class="answer-item text-md-16 text-14 pb-6" v-for="(item, index) in list" :key="index">
        <div class="color-FE9700" v-html="item.Q"></div>
        <div class="" v-html="item.A">
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'AnswerIndex',
  data() {
    return {
      list: [
        {
          Q: 'Q：' + this.$vuetify.lang.t('$vuetify.answer.text1'),
          A: 'A：' + this.$vuetify.lang.t('$vuetify.answer.text2')
        },
        {
          Q: 'Q：' + this.$vuetify.lang.t('$vuetify.answer.text3'),
          A: 'A：' + this.$vuetify.lang.t('$vuetify.answer.text4')
        },
        {
          Q: 'Q：' + this.$vuetify.lang.t('$vuetify.answer.text5'),
          A: 'A：' + this.$vuetify.lang.t('$vuetify.answer.text6')
        },
        {
          Q: 'Q：' + this.$vuetify.lang.t('$vuetify.answer.text7'),
          A: 'A：' + this.$vuetify.lang.t('$vuetify.answer.text8')
        },
        {
          Q: 'Q：' + this.$vuetify.lang.t('$vuetify.answer.text9'),
          A: 'A：' + this.$vuetify.lang.t('$vuetify.answer.text10')
        },
        {
          Q: 'Q：' + this.$vuetify.lang.t('$vuetify.answer.text11'),
          A: 'A：' + this.$vuetify.lang.t('$vuetify.answer.text12')
        },
        {
          Q: 'Q：' + this.$vuetify.lang.t('$vuetify.answer.text13'),
          A: 'A：' + this.$vuetify.lang.t('$vuetify.answer.text14')
        },
        {
          Q: 'Q：' + this.$vuetify.lang.t('$vuetify.answer.text15'),
          A: 'A：' + this.$vuetify.lang.t('$vuetify.answer.text16')
        },
        {
          Q: 'Q：' + this.$vuetify.lang.t('$vuetify.answer.text17'),
          A: 'A：' + this.$vuetify.lang.t('$vuetify.answer.text18')
        },
        {
          Q: 'Q：' + this.$vuetify.lang.t('$vuetify.answer.text19'),
          A: 'A：' + this.$vuetify.lang.t('$vuetify.answer.text20')
        }
      ]
    }
  },
  watch: {
    "$vuetify.lang.current": {
      handler() {
        // 语言切换时重新请求数据
        this.$router.go(0);
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.banner {
  //background-size: 100% 100%;
  //padding: 80px 0;
  color: #fff;
  text-align: center;
  background: url(../assets/free/bg.jpg) no-repeat center;
  padding: 100px 0;
}

.answer-item {
  line-height: 2.2;
}

@media (max-width: 960px) {
  .banner {
    //background: url(../assets/charter/bg.jpg) no-repeat;
    background-size: 100% 100%;
    padding: 52px 0;
    //text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
    //letter-spacing: 8px;
    color: #fff;
  }
  .aa{
    font-size: 1.875rem !important;
  }
  .tt {
    letter-spacing: 1px;
    font-size: 1rem !important;
  }
}
</style>