<template>
  <div>
    <div class="banner">
      <h3 class="text-md-36 text-30 font-weight-black pb-md-2">
        {{ $vuetify.lang.t("$vuetify.menu.charter") }}
      </h3>
      <h3 class="text-md-36 text-30 font-weight-medium tt">PRIVATE TOUR</h3>
    </div>
    <v-container>
      <div class="d-md-flex car-container">
        <div class="car-item" @click="$router.push('/charter/#car1')">
          <!-- <v-img src="../assets/charter/alphard.png" width="400px"></v-img> -->
          <img src="../assets/charter/alphard.png" alt="" />
          <div class="tip" style="color: black">
            {{ $vuetify.lang.t("$vuetify.charter.alphard") }}
          </div>
          <div class="name">
            TOYOTA ALPHARD <br />
            {{ $vuetify.lang.t("$vuetify.charter.text1") }}
          </div>
        </div>
        <div class="car-item" @click="$router.push('/charter/#car2')">
          <!-- <v-img src="../assets/charter/hiace.png"></v-img> -->
          <img src="../assets/charter/hiace.png" alt="" />
          <div class="tip" style="color: black">
            {{ $vuetify.lang.t("$vuetify.charter.hiace") }}
          </div>
          <div class="name">
            TOYOTA HI-ACE <br />
            {{ $vuetify.lang.t("$vuetify.charter.text2") }}
          </div>
        </div>
      </div>
    </v-container>
    <div class="content1" id="car1">
      <div class="header">
        <div class="d-md-inline-flex">
          <!-- <v-img src="../assets/charter/alphard.png" width="160px" contain></v-img> -->
          <img
            src="../assets/charter/alphard.png"
            alt=""
            width="100px"
            class="d-md-none"
          />
          <img
            src="../assets/charter/alphard.png"
            alt=""
            width="160px"
            class="d-none d-md-block"
          />
          <div class="name" style="color: #000000">
            TOYOTA ALPHARD <br />
            {{ $vuetify.lang.t("$vuetify.charter.text1") }}
          </div>
        </div>
        <div class="pt-2" style="color: #000000">
          {{ $vuetify.lang.t("$vuetify.charter.text3") }}
        </div>
      </div>
      <div class="price">
        <div class="border">
          <div class="border-svg">
            <!--            <svg preserveAspectRatio="none" viewBox="38.5 20 123 160" height="200"-->
            <!--                 width="200" xmlns="http://www.w3.org/2000/svg"-->
            <!--                 aria-hidden="true">-->
            <!--              <g>-->
            <!--                <path d="m50.5 20-12 14.9V180h123V20h-111z"></path>-->
            <!--                &lt;!&ndash;                d="M10 10 H 90 V 90 H 10 L 10 10"&ndash;&gt;-->
            <!--                &lt;!&ndash;                m50.5 20-12 14.9V180h123V20h-111z&ndash;&gt;-->
            <!--              </g>-->
            <!--            </svg>-->
          </div>
          <!--          <div class="arrow">-->
          <!--            <v-img src="../assets/charter/icon_arrow2.png" contain width="50px"></v-img>-->
          <!--          </div>-->
          <div class="price-main">
            <button>
              <svg
                data-bbox="42 25 116.667 150"
                viewBox="0 0 200 200"
                height="200"
                width="200"
                xmlns="http://www.w3.org/2000/svg"
                data-type="shape"
              >
                <g>
                  <path
                    d="M120.783 87.308c-1.55 8.134-8.216 14.775-16.366 16.317-14.684 2.775-27.309-9.817-24.525-24.442 1.541-8.125 8.216-14.775 16.366-16.316 14.675-2.767 27.309 9.816 24.525 24.441Zm37.884-3.991C158.667 51.167 132.5 25 100.333 25 68.175 25 42 51.167 42 83.317c0 .125.008.25.017.366L42 84.95c0 23.458 16.625 52.475 49.408 86.233 2.375 2.459 5.55 3.817 8.925 3.817 3.384 0 6.559-1.358 8.942-3.808 32.775-33.75 49.392-62.775 49.392-86.242l-.017-1.267c.017-.116.017-.241.017-.366Z"
                    fill-rule="evenodd"
                  ></path>
                </g>
              </svg>
              {{ $vuetify.lang.t("$vuetify.charter.text11") }}
            </button>
            <div class="d-md-flex justify-space-between">
              <div class="item">{{ $vuetify.lang.t("$vuetify.charter.text4") }}</div>
              <div class="item">11000JPY~</div>
            </div>
            <div class="d-md-flex justify-space-between">
              <div class="item">{{ $vuetify.lang.t("$vuetify.charter.text5") }}</div>
              <div class="item">17600JPY~</div>
            </div>
            <div class="d-md-flex justify-space-between">
              <div class="item">{{ $vuetify.lang.t("$vuetify.charter.text6") }}</div>
              <div class="item">11000JPY~</div>
            </div>
            <div class="d-md-flex justify-space-between">
              <div class="item">{{ $vuetify.lang.t("$vuetify.charter.text7") }}</div>
              <div class="item">39600JPY~/{{ $vuetify.lang.t("$vuetify.day") }}</div>
            </div>
            <div class="d-md-flex justify-space-between">
              <div class="item">{{ $vuetify.lang.t("$vuetify.charter.text8") }}</div>
              <div class="item">48400JPY~/{{ $vuetify.lang.t("$vuetify.day") }}</div>
            </div>
            <div class="d-md-flex justify-space-between">
              <div class="item">{{ $vuetify.lang.t("$vuetify.charter.text9") }}</div>
              <div class="item">52800JPY~/{{ $vuetify.lang.t("$vuetify.day") }}</div>
            </div>
          </div>
          <div class="price-brand">TOYOTA ALPHARD</div>
          <!--          <div class="bottom">-->
          <!--            <v-img src="../assets/charter/icon_bottom2.png" width="70px"></v-img>-->
          <!--          </div>-->
        </div>
      </div>
      <div
        class="desc"
        style="color: #000000"
        v-html="$vuetify.lang.t('$vuetify.charter.text10')"
      ></div>
    </div>
    <div class="content1 content2" id="car2">
      <div class="header">
        <div class="d-md-inline-flex">
          <!-- <v-img src="../assets/charter/hiace.png" width="160px" contain></v-img> -->
          <img src="../assets/charter/hiace.png" alt="" width="100px" class="d-md-none" />
          <img
            src="../assets/charter/hiace.png"
            alt=""
            width="160px"
            class="d-none d-md-block"
          />
          <div class="name">
            TOYOTA HI-ACE <br />
            {{ $vuetify.lang.t("$vuetify.charter.text2") }}
          </div>
        </div>
        <div class="pt-2">{{ $vuetify.lang.t("$vuetify.charter.text3") }}</div>
      </div>
      <div class="price">
        <div class="border">
          <div class="border-svg">
            <!--            <svg preserveAspectRatio="none" data-bbox="38.5 20 100 160" viewBox="38.5 20 123 160" height="200"-->
            <!--                 width="200" xmlns="http://www.w3.org/2000/svg" data-type="shape" role="presentation"-->
            <!--                 aria-hidden="true">-->
            <!--              <g>-->
            <!--                <path d="m50.5 20-12 14.9V180h123V20h-111z"></path>-->
            <!--              </g>-->
            <!--            </svg>-->
          </div>
          <!--          <div class="arrow">-->
          <!--            <v-img src="../assets/charter/icon_arrow2.png" contain width="50px"></v-img>-->
          <!--          </div>-->
          <div class="price-main">
            <button>
              <svg
                data-bbox="42 25 116.667 150"
                viewBox="0 0 200 200"
                height="200"
                width="200"
                xmlns="http://www.w3.org/2000/svg"
                data-type="shape"
              >
                <g>
                  <path
                    d="M120.783 87.308c-1.55 8.134-8.216 14.775-16.366 16.317-14.684 2.775-27.309-9.817-24.525-24.442 1.541-8.125 8.216-14.775 16.366-16.316 14.675-2.767 27.309 9.816 24.525 24.441Zm37.884-3.991C158.667 51.167 132.5 25 100.333 25 68.175 25 42 51.167 42 83.317c0 .125.008.25.017.366L42 84.95c0 23.458 16.625 52.475 49.408 86.233 2.375 2.459 5.55 3.817 8.925 3.817 3.384 0 6.559-1.358 8.942-3.808 32.775-33.75 49.392-62.775 49.392-86.242l-.017-1.267c.017-.116.017-.241.017-.366Z"
                    fill-rule="evenodd"
                  ></path>
                </g>
              </svg>
              {{ $vuetify.lang.t("$vuetify.charter.text11") }}
            </button>
            <div class="d-md-flex justify-space-between">
              <div class="item">{{ $vuetify.lang.t("$vuetify.charter.text4") }}</div>
              <div class="item">15400JPY~</div>
            </div>
            <div class="d-md-flex justify-space-between">
              <div class="item">{{ $vuetify.lang.t("$vuetify.charter.text5") }}</div>
              <div class="item">24200JPY~</div>
            </div>
            <div class="d-md-flex justify-space-between">
              <div class="item">{{ $vuetify.lang.t("$vuetify.charter.text6") }}</div>
              <div class="item">13200JPY~</div>
            </div>
            <div class="d-md-flex justify-space-between">
              <div class="item">{{ $vuetify.lang.t("$vuetify.charter.text7") }}</div>
              <div class="item">48400JPY~/{{ $vuetify.lang.t("$vuetify.day") }}</div>
            </div>
            <div class="d-md-flex justify-space-between">
              <div class="item">{{ $vuetify.lang.t("$vuetify.charter.text8") }}</div>
              <div class="item">44000JPY~/{{ $vuetify.lang.t("$vuetify.day") }}</div>
            </div>
            <div class="d-md-flex justify-space-between">
              <div class="item">{{ $vuetify.lang.t("$vuetify.charter.text9") }}</div>
              <div class="item">52800JPY~/{{ $vuetify.lang.t("$vuetify.day") }}</div>
            </div>
          </div>
          <div class="price-brand">TOYOTA HIACE</div>
          <!--          <div class="bottom">-->
          <!--            <v-img src="../assets/charter/icon_bottom2.png" width="70px"></v-img>-->
          <!--          </div>-->
        </div>
      </div>
      <div
        class="desc"
        v-html="$vuetify.lang.t('$vuetify.charter.text10')"
        style="color: #000000"
      ></div>
    </div>
    <div class="btn-consult" @click="$router.push('/consult')">
      <img
        src="../assets/charter/icon_car.png"
        alt=""
        style="vertical-align: middle; margin-right: 20px"
      />
      <span style="vertical-align: middle">{{
        $vuetify.lang.t("$vuetify.charter.text12")
      }}</span>
    </div>

    <img
      src="../assets/charter/QA.jpg"
      alt=""
      class="d-md-block d-none"
      width="150px"
      style="position: fixed; right: 0; bottom: 5vh"
      @click="$router.push('/consult')"
    />

    <img
      src="../assets/charter/top.jpg"
      alt=""
      :class="['d-md-none', 'd-md-none top', showTopButton ? 'active' : '']"
      @click="scrollToTop"
    />
  </div>
</template>

<script>
export default {
  name: "charterIndex",
  data: () => {
    return {
      showTopButton: false,
    };
  },
  created() {
    // this.getList();
    //   this.user = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")) : {};
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const viewportHeight = window.innerHeight; // 获取视口高度
      this.showTopButton = window.scrollY > viewportHeight; // 当滚动距离超过一屏时显示按钮
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  background: url(../assets/charter/bg.jpg) no-repeat;
  //background-size: 100% 100%;
  background-position: center;
  padding: 100px 0;
  //color: #fff;
  text-align: center;
}

.car-container {
  justify-content: space-evenly;

  .car-item {
    padding-top: 30px;
    margin: 20px 0;
    position: relative;
    cursor: pointer;

    img {
      width: 400px;
    }

    .tip {
      display: inline-block;
      width: 200px;
      height: 50px;
      line-height: 50px;
      border-radius: 50px;
      text-align: center;
      color: #fff;
      background-color: rgb(254, 214, 0);
      border: 2px solid #fff;
      position: absolute;
      left: 0;
      top: 0;
    }

    .name {
      font-weight: bold;
      font-size: 28px;
      text-align: center;
      margin-top: 20px;
    }
  }
}

.content1 {
  background-color: #fbfcfe;
  width: 100%;
  color: #fff;
  text-align: center;

  .header {
    height: 200px;
    padding-top: 40px;

    .name {
      font-size: 28px;
      font-weight: bold;
      margin-left: 30px;
    }
  }

  //../../assets/about/bg_logo.png
  .price {
    background: url(../assets/about/bg_logo.png);

    .border {
      width: 760px;
      border: 1px solid #000000;
      margin: 0 auto 20px;
      padding: 40px 50px 30px;
      position: relative;

      .border-svg {
        svg {
          position: absolute;
          left: -70px;
          top: 20px;
          width: 790px;
          height: 85%;
          fill: transparent;
          stroke: #000000;
          stroke-width: 1px;

          &:not([data-type="ugc"]) {
            overflow: visible;
          }

          * {
            vector-effect: non-scaling-stroke;
          }
        }
      }

      .arrow {
        position: absolute;
        left: -60px;
        top: 100px;
      }

      .bottom {
        position: absolute;
        right: 40px;
        bottom: 20px;
      }
    }

    button {
      transition: all 0.2s ease, visibility 0s;
      border: 0px solid rgb(71, 67, 197);
      background: #feab00;
      border-radius: 0px;
      box-shadow: 3.54px 3.54px #f7f6ef;
      width: 370px;
      height: 70px;
      margin-bottom: 30px;
      font-size: 24px;

      svg {
        width: 23px;
        height: 23px;
        fill: #fff;
        vertical-align: middle;
      }
    }

    .item {
      background-color: rgb(16, 36, 103);
      border: 1px solid rgb(71, 67, 197);
      width: 49%;
      height: 60px;
      line-height: 60px;
      margin-bottom: 20px;
    }

    .price-brand {
      color: rgb(27, 108, 223);
      font-size: 28px;
      font-weight: bold;
      margin-top: 20px;
    }
  }

  .desc {
    padding-bottom: 50px;
  }
}

.content2 {
  background-color: #fff;

  .header {
    color: #333;
  }

  .price {
    background: url(../assets/about/bg_logo.png);
    padding-bottom: 30px;

    button {
      transition: all 0.2s ease, visibility 0s;
      border: 0px solid rgb(71, 67, 197);
      border-radius: 0px;
      box-shadow: 3.54px 3.54px #cfc89e;
      background: #7f5600;
    }

    .border {
      border-color: rgb(16 36 103);

      .border-svg {
        svg {
          stroke: #102467;
        }
      }
    }

    .item {
      border-color: rgb(255 212 90);
      background-color: rgb(81, 76, 40);
    }

    .price-brand {
      color: #ffd45a;
    }
  }
}

.btn-consult {
  transition: all 0.2s ease, visibility 0s;
  border: 0px solid rgb(71, 67, 197);
  border-radius: 0px;
  box-shadow: 3.54px 3.54px #cfc89e;
  background: #feab00;
  width: 600px;
  height: 70px;
  line-height: 70px;
  color: #fff;
  font-size: 24px;
  text-align: center;
  margin: 30px auto;
  cursor: pointer;
}

@media (max-width: 960px) {
  .banner {
    // background: url(../assets/contact/img1_m.png) no-repeat;
    background-size: 100% 100%;
    padding: 52px 0;
    text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
    letter-spacing: 8px;
  }
  .car-container {
    .car-item {
      text-align: right;

      img {
        width: 90%;
      }
    }
  }
  .tt {
    letter-spacing: 1px;
    font-size: 1rem !important;
  }
  .content1 {
    .header {
      height: auto;
    }

    .price {
      margin-top: 40px;

      .border {
        width: 95%;
        padding: 40px 15px 30px;
      }

      .item {
        width: 100%;
        height: 50px;
        line-height: 50px;
        margin-bottom: 5px;
      }

      button {
        width: 60%;
        height: 50px;
        position: absolute;
        left: 15px;
        top: -25px;
      }
    }

    .desc {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .btn-consult {
    width: 80%;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
  }
  .top {
    position: fixed;
    right: 30px;
    bottom: 5vh;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s 0.5s, opacity 0.5s ease 0s;
    &.active {
      opacity: 1;
      visibility: visible;
      transition-delay: 0s;
    }
  }
}
</style>
