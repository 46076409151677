<template>
  <div id="about">
    <v-img id="about-img" src="../../assets/about/img.png"></v-img>
    <v-container>
      <!-- 关于我们 -->
      <div class="about">
        <h3 class="text-md-38 text-26 font-weight-black mt-md-8 mt-2">
          {{ $vuetify.lang.t("$vuetify.about.title") }}
        </h3>
        <h3 class="text-md-38 text-26 font-weight-medium mt-2 mb-5">ABOUT US</h3>
        <!-- <div class="text-img mt-md-8 mt-2">
          <v-img src="../../assets/about/text.png"></v-img>
        </div>
        <div class="text-en-img mt-md-5 mt-2 mb-5">
          <v-img src="../../assets/about/text_en.png"></v-img>
        </div> -->
        <v-img
          src="../../assets/about/bg_logo.png"
          class="ml-auto mr-auto bg-logo"
        ></v-img>

        <div class="content">
          <div class="mt-md-16 mb-md-4 mb-2 logo-img">
            <v-img src="../../assets/logo.png"></v-img>
          </div>

          <!-- <h3 class="text-h3 font-weight-medium mt-16 mb-4">Utor.</h3> -->
          <p class="text-md-16 text-14">
            {{ $vuetify.lang.t("$vuetify.about.text5") }}
          </p>
        </div>
        <v-img src="../../assets/about/img_car.png" class="img-car"></v-img>
      </div>
      <!-- 选择UTOR的理由 -->
      <div class="reason">
        <div
          class="text-14 text-md-24 color-000-36 font-weight-medium text-center pb-md-5 pb-2"
        >
          {{ $vuetify.lang.t("$vuetify.about.text1") }}
        </div>
        <div class="text-md-34 text-20 font-weight-bold text-center">
          {{ $vuetify.lang.t("$vuetify.about.text2") }}？
        </div>
        <p class="text-md-16 text-14 pt-6 pt-md-16 text-line-height-2">
          {{ $vuetify.lang.t("$vuetify.about.text18") }}
        </p>
        <v-row class="mb-md-12 mt-md-16 mt-6 ">
          <v-col cols="12">
            <div class="d-flex align-center mb-md-5 mb-2">
              <v-img
                class="img-index"
                src="../../assets/about/icon_reason_1.png"
                contain
              ></v-img>
              <div style="width: 90%" class="ml-4">
                <div class="text-md-24 text-14 font-weight-bold md-1">
                  {{ $vuetify.lang.t("$vuetify.about.text6") }}
                </div>
                <!-- <div class="text-md-24 text-12 text-000-5">
                  {{ $vuetify.lang.t("$vuetify.about.text7") }}
                </div> -->
              </div>
            </div>
            <div class="text-md-14 text-12 text-000-5 text-line-height-2">
              {{ $vuetify.lang.t("$vuetify.about.text7") }}
            </div>
          </v-col>
          <!-- <v-col cols="12" md="5">
            <v-img contain src="../../assets/about/img_reason_1.png"></v-img>
          </v-col> -->
        </v-row>
        <v-row class="mb-md-12">
          <!-- <v-col md="5" cols="12" class="d-none d-md-block">
            <v-img contain src="../../assets/about/img_reason_2.png"></v-img>
          </v-col> -->
          <v-col cols="12">
            <div class="d-flex align-center mb-md-5 mb-2">
              <v-img
                class="img-index"
                contain
                src="../../assets/about/icon_reason_2.png"
              ></v-img>
              <div style="width: 90%" class="ml-4">
                <div class="text-md-24 text-14 font-weight-bold">
                  {{ $vuetify.lang.t("$vuetify.about.text8") }}
                </div>
                <!-- <div class="text-md-24 text-12 text-000-5">
                  {{ $vuetify.lang.t("$vuetify.about.text10") }}
                </div> -->
              </div>
            </div>
            <div class="text-md-14 text-12 text-000-5 text-line-height-2">
              {{ $vuetify.lang.t("$vuetify.about.text9") }}
            </div>
          </v-col>
          <!-- <v-col md="5" cols="12" class="d-md-none">
            <v-img contain src="../../assets/about/img_reason_2.png"></v-img>
          </v-col> -->
        </v-row>
        <v-row class="mb-md-12">
          <v-col cols="12">
            <div class="d-flex align-center mb-md-5 mb-2">
              <v-img
                class="img-index"
                contain
                src="../../assets/about/icon_reason_3.png"
              ></v-img>
              <div style="width: 90%" class="ml-4">
                <div class="text-md-24 text-14 font-weight-bold">
                  {{ $vuetify.lang.t("$vuetify.about.text10") }}
                </div>
                <!-- <div class="text-md-24 text-12 text-000-5">
                  {{ $vuetify.lang.t("$vuetify.about.text13") }}
                </div> -->
              </div>
            </div>
            <div class="text-md-14 text-12 text-000-5 text-line-height-2">
              {{ $vuetify.lang.t("$vuetify.about.text11") }}
            </div>
          </v-col>
          <!-- <v-col md="5" cols="12">
            <v-img src="../../assets/about/img_reason_3.png"></v-img>
          </v-col> -->
        </v-row>
        <v-row class="mb-md-12">
          <v-col cols="12">
            <div class="d-flex align-center mb-md-5 mb-2">
              <v-img
                class="img-index"
                contain
                src="../../assets/about/icon_reason_4.png"
              ></v-img>
              <div style="width: 90%" class="ml-4">
                <div class="text-md-24 text-14 font-weight-bold">
                  {{ $vuetify.lang.t("$vuetify.about.text12") }}
                </div>
              </div>
            </div>
            <div class="text-md-14 text-12 text-000-5 text-line-height-2">
              {{ $vuetify.lang.t("$vuetify.about.text13") }}
            </div>
          </v-col>
        </v-row>
        <v-row class="mb-md-12">
          <v-col cols="12">
            <div class="d-flex align-center mb-md-5 mb-2">
              <v-img
                class="img-index"
                contain
                src="../../assets/about/icon_reason_5.png"
              ></v-img>
              <div style="width: 90%" class="ml-4">
                <div class="text-md-24 text-14 font-weight-bold">
                  {{ $vuetify.lang.t("$vuetify.about.text14") }}
                </div>
              </div>
            </div>
            <div class="text-md-14 text-12 text-000-5 text-line-height-2">
              {{ $vuetify.lang.t("$vuetify.about.text15") }}
            </div>
          </v-col>
        </v-row>
        <v-row class="mb-md-12">
          <v-col cols="12">
            <div class="d-flex align-center mb-md-5 mb-2">
              <v-img
                class="img-index"
                contain
                src="../../assets/about/icon_reason_6.png"
              ></v-img>
              <div style="width: 90%" class="ml-4">
                <div class="text-md-24 text-14 font-weight-bold">
                  {{ $vuetify.lang.t("$vuetify.about.text16") }}
                </div>
              </div>
            </div>
            <div class="text-md-14 text-12 text-000-5 text-line-height-2">
              {{ $vuetify.lang.t("$vuetify.about.text17") }}
            </div>
          </v-col>
        </v-row>
        <p class="text-md-16 text-14 pt-6 pt-md-16">
          {{ $vuetify.lang.t("$vuetify.about.text19") }}
        </p>
      </div>
    </v-container>
    <!-- 客户好评 -->
    <div class="comment" id="comment">
      <v-container>
        <v-img
          contain
          src="../../assets/about/icon_comment.png"
          height="65"
          class="img-comment"
        ></v-img>
        <h5 class="text-md-34 text-20 font-weight-bold text-center">
          {{ $vuetify.lang.t("$vuetify.about.text3") }}
        </h5>
        <div
          ref="scrollContainer"
          class="scroll-container"
          @mouseover="stopScrolling"
          @mouseout="startScrolling"
        >
          <div class="scroll-content" v-if="items.length > 0">
            <!-- <div v-for="(item, index) in extendedItems" :key="index" class="scroll-item">
                            {{ item.text }}
                        </div> -->
            <v-card
              class="comment-card ma-md-5 ma-2"
              v-for="(item, index) in extendedItems"
              :key="index"
            >
              <v-card-text>
                <div class="d-flex align-center">
                  <v-avatar>
                    <img :src="item.headPic" />
                  </v-avatar>
                  <div class="name font-weight-medium pl-3">{{ item.nickname }}</div>
                </div>
                <p class="pt-3 pb-md-6 pb-0 text-md-14 text-12">
                  {{ item.content }}
                </p>
                <v-divider></v-divider>
                <!-- <div class="d-flex justify-space-between pt-md-4 pt-1"> -->
                <!-- <div class="d-flex align-start">
                    <v-img src="../../assets/about/icon_like.png" contain></v-img>
                    <div class="text-000-5 text-md-14 text-12 pl-1">{{item.score}}</div>
                  </div> -->
                <!-- <div class="d-flex">
                    <v-img src="../../assets/about/icon_star.png" contain></v-img>
                    <v-img src="../../assets/about/icon_star.png" contain></v-img>
                    <v-img src="../../assets/about/icon_star.png" contain></v-img>
                  </div> -->
                <!-- </div> -->
                <CommentStar
                  class="pb-3 mt-md-4 mt-1"
                  :score="item.score"
                  :mdw="24"
                ></CommentStar>
              </v-card-text>
            </v-card>
          </div>
          <div
            class="text-center pt-8 text-md-24 text-14 text-000-5"
            v-if="items.length == 0"
          >
            {{ $vuetify.lang.t("$vuetify.none") }}
          </div>
        </div>
      </v-container>
    </div>
    <!-- 社交媒体 -->
    <v-container class="media" id="media">
      <h5 class="text-md-34 text-20 font-weight-bold text-center">
        {{ $vuetify.lang.t("$vuetify.about.text4") }}
      </h5>
      <div class="d-flex justify-space-between mt-md-15 mt-6 media-con">
        <div class="text-center">
          <v-img
            src="../../assets/about/icon_facebook.png"
            cover
            class="mr-auto ml-auto"
          ></v-img>
          <div class="name">Facebook</div>
        </div>
        <div class="text-center">
          <v-img
            src="../../assets/about/icon_instagram.png"
            cover
            class="mr-auto ml-auto"
          ></v-img>
          <div class="name">Instagram</div>
        </div>
        <div class="text-center">
          <v-img
            src="../../assets/about/icon_book.png"
            cover
            class="mr-auto ml-auto"
          ></v-img>
          <div class="name">小红书</div>
        </div>
        <div class="text-center">
          <v-img
            src="../../assets/about/icon_klook.png"
            cover
            class="mr-auto ml-auto"
          ></v-img>
          <div class="name">Klook</div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import { commentList } from "../../api/index";
import CommentStar from "@/components/CommentStar/index.vue";

export default {
  name: "AboutIndex",
  components: {
    CommentStar,
  },
  data() {
    return {
      model: null,
      items: [
        { text: "Item 1" },
        { text: "Item 2" },
        { text: "Item 3" },
        { text: "Item 1" },
        { text: "Item 2" },
        { text: "Item 3" },
        { text: "Item 1" },
        { text: "Item 2" },
        { text: "Item 3" },
        { text: "Item 1" },
        { text: "Item 2" },
        { text: "Item 3" },
        { text: "Item 1" },
        { text: "Item 2" },
        { text: "Item 3" },
        // 更多项目...
      ],
      scrollInterval: null,
      isScrolling: false,
    };
  },

  mounted() {
    this.getCommentList();
    this.startScrolling();
  },
  beforeDestroy() {
    this.stopScrolling();
  },
  methods: {
    getCommentList() {
      commentList({
        page: 1,
        size: 999,
        vehicleId: 0,
      }).then((res) => {
        if (res.code == 200) {
          this.items = res.data.list;
        }
      });
    },
    startScrolling() {
      if (!this.isScrolling) {
        this.scrollInterval = setInterval(() => {
          const container = this.$refs.scrollContainer;
          const currentScrollLeft = container.scrollLeft;
          const contentWidth = container.scrollWidth;
          const containerWidth = container.clientWidth;

          // 当滚动到末尾时回到开头
          if (currentScrollLeft >= contentWidth - containerWidth) {
            container.scrollLeft = 0;
          } else {
            container.scrollLeft = currentScrollLeft + 5; // 每次滚动 5px
          }
        }, 100); // 每 50ms 滚动一次
        this.isScrolling = true;
      }
    },
    stopScrolling() {
      clearInterval(this.scrollInterval);
      this.isScrolling = false;
    },
  },
  computed: {
    extendedItems() {
      return [...this.items, ...this.items]; // 重复列表以实现无限滚动
    },
  },
};
</script>

<style lang="scss" scoped>
#about {
  .about {
    position: relative;

    .content {
      position: absolute;
      bottom: 18%;

      p {
        line-height: 2;
      }
    }

    .text-img {
      width: 19%;
    }

    .text-en-img {
      width: 15%;
    }

    .img-car {
      position: absolute;
      top: -45%;
      right: 0;
      width: 50%;
    }

    .bg-logo {
      width: 90%;
    }

    .logo-img {
      width: 4%;
    }
  }

  .reason {
    padding: 80px 0 60px;

    .title {
      font-size: 1.5rem;
      color: rgba(0, 0, 0, 0.36);
    }
    .img-index {
      width: 65px;
      flex: none;
    }
  }

  .comment {
    background-color: #f9fbfc;
    padding: 60px 0 120px;
    position: relative;

    .comment-card {
      width: 368px;
    }

    .img-comment {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -25px;
    }

    .scroll-container {
      overflow-x: hidden;
      /* 隐藏水平滚动条 */
      white-space: nowrap;
      width: 100%;

      .scroll-content {
        display: inline-flex;
      }

      .name {
        font-size: 1.25rem;
        color: #000;
      }
    }
  }

  .media {
    padding-bottom: 140px;
    padding-top: 140px;

    .name {
      color: rgba(0, 0, 0, 0.5);
      font-size: 1.25rem;
    }

    .media-con > div {
      .v-responsive {
        width: 112px;
        height: 112px;
      }
    }
  }
}

@media (max-width: 960px) {
  #about-img {
    height: 82px;
  }

  #about {
    .reason {
      padding: 20px 0 40px;

      .img-index {
        width: 47px;
      }
    }

    .comment {
      padding: 31px 0 34px;

      .img-comment {
        height: 46px !important;
        top: -20px;
      }

      .comment-card {
        width: 252px;
        border-radius: 12px;

        .v-card__subtitle,
        .v-card__text,
        .v-card__title {
          padding-bottom: 8px;
        }
      }

      .scroll-container {
        .name {
          font-size: 0.875rem;
        }
      }
    }

    .about {
      .img-car {
        width: 70%;
        top: -26%;
      }

      .text-img {
        width: 80px;
      }

      .text-en-img {
        width: 64px;
      }

      .content {
        position: inherit;
      }

      .bg-logo {
        position: absolute;
        width: 100%;
      }

      .logo-img {
        width: 14%;
      }
    }

    .media {
      padding-bottom: 40px;
      padding-top: 40px;

      .media-con > div {
        width: 18%;

        .v-responsive {
          width: 48px;
          height: 48px;
        }
      }

      .name {
        font-size: 14px;
      }
    }
  }
}
</style>
