<template>
  <div>
    <div class="banner">
      <h3 class="text-36 text-30 font-weight-black pb-md-2 aa">
        {{ $vuetify.lang.t("$vuetify.book.title") }}
      </h3>
      <h3 class="text-36 text-30 font-weight-medium tt">BOOKING VEHICLES</h3>
    </div>
    <v-container class="content">
      <div class="name font-weight-bold text-center pt-md-7 pt-3 mb-7">
        {{ $vuetify.lang.t("$vuetify.book.title") }}
      </div>
      <div class="search mb-5">
        <v-row>
          <v-col cols="12" md="3">
            <v-text-field v-model="searchData.keyword" outlined solo flat class="search-input text-14" hide-details
              :placeholder="$vuetify.lang.t('$vuetify.book.text1')">
              <div slot="append" class="search-btn text-14 font-weight-bold" @click="search">
                {{ $vuetify.lang.t("$vuetify.book.text2") }}
              </div>
            </v-text-field>
          </v-col>
          <v-col cols="6" md="2">
            <v-select :items="vehiclesType" outlined solo flat hide-details
              :placeholder="$vuetify.lang.t('$vuetify.book.text3')" :menu-props="{ offsetY: true }"
              v-model="searchData.vehicleTypeId" item-value="id" item-text="name" class="text-14"
              @change="search"></v-select>
          </v-col>
          <v-col cols="6" md="2">
            <v-select :items="brandList" outlined solo flat hide-details
              :placeholder="$vuetify.lang.t('$vuetify.book.text4')" v-model="searchData.brandId"
              :menu-props="{ offsetY: true }" item-value="id" item-text="brandName" class="text-14"
              @change="search"></v-select>
          </v-col>
          <v-col cols="6" md="2">
            <v-select :items="passengerList" outlined solo flat hide-details
              :placeholder="$vuetify.lang.t('$vuetify.book.text5')" v-model="searchData.passenger"
              :menu-props="{ offsetY: true }" item-value="id" item-text="name" class="text-14"
              @change="search"></v-select>
          </v-col>
          <v-col cols="6" md="2">
            <v-select :items="sortList" v-model="searchData.sortField" outlined solo flat hide-details
              item-value="value" item-text="name" class="text-14" :placeholder="$vuetify.lang.t('$vuetify.book.text11')"
              @change="search"></v-select>
          </v-col>
          <v-col cols="6" md="1">
            <v-btn-toggle v-model="searchData.sortType" mandatory @change="search">
              <v-btn plain value="asc">
                <v-icon>mdi-arrow-up</v-icon>
              </v-btn>
              <v-btn value="desc">
                <v-icon>mdi-arrow-down</v-icon>
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
      </div>
      <!-- <div class="list d-flex flex-wrap justify-space-between">
                <div class="list-item mb-5" v-for="item in 4" :key="item">
                    <v-img contain src="../../assets/book/car.png"></v-img>
                    <div class="font-weight-bold text-32 pt-4 pb-2">埃尔法</div>
                    <div class="text-24 text-000-5 pb-2">丰田 / 埃尔法</div>
                    <div class="text-24 text-000-5 pb-2">自动挡 / 7/8座</div>
                    <CommentStar class="pb-3" :score="5" isText></CommentStar>
                    <MyLabel :labels="['ETC', '行车记录仪', '车载导航', '保险']"></MyLabel>
                    <div class="price">
                        <span class="text-50 font-weight-bold text-red">JPY 19888</span><span class="text-24">/天</span>
                    </div>
                    <v-btn class="btn-submit text-22 mt-3" width="100%" @click="$router.push('/book/detail')">立即预定</v-btn>
                </div>
            </div> -->
      <v-row class="list align-stretch" v-if="items.length > 0">
        <v-col cols="6" md="4" v-for="(item, index) in items" :key="index" :class="['pa-1', 'pa-md-3']">
          <div class="list-item mb-5">
            <v-img class="d-md-none" cover :src="item.vehiclePic.split(',')[0]" width="100%" height="90"></v-img>
            <v-img class="d-none d-md-block" cover :src="item.vehiclePic.split(',')[0]" width="100%"
              height="240"></v-img>
            <div class="font-weight-bold text-md-20 text-16 mt-4 mb-2 two-line-clamp">
              {{ item.name }}
            </div>
            <div class="text-14 text-000-5 pb-2">
              {{ item.brandName }} / {{ item.subBrand }}
            </div>
            <div class="text-14 text-000-5 pb-2">
              {{ item.speed }} / {{ item.passenger }}{{ $vuetify.lang.t("$vuetify.seat") }}
            </div>
            <CommentStar class="pb-3" :score="item.score" isText></CommentStar>
            <MyLabel :labels="(item.tagList && item.tagList.split(',')) || []"></MyLabel>
            <div class="price">
              <span class="text-28 font-weight-bold text-red">JPY {{ $formatPrice(item.price) }}</span><span
                class="text-14">/{{ $vuetify.lang.t("$vuetify.day") }}</span>
            </div>
            <v-btn class="btn-submit text-14 mt-3" width="100%"
              @click="$router.push('/book/detail/' + item.vehicleId)">{{ $vuetify.lang.t("$vuetify.booking.title")
              }}</v-btn>
          </div>
        </v-col>
      </v-row>
      <div class="text-center pt-8 text-md-24 text-14 text-000-5" v-if="items.length == 0">
        {{ $vuetify.lang.t("$vuetify.none") }}
      </div>
      <!-- <v-pagination v-model="page" :length="10" class="mt-7"></v-pagination> -->
      <!-- <MyPigination></MyPigination> -->
      <MyPigination class="mt-7" :total="totalCount" :size="size" @changePage="handleChangePage"
        v-if="items.length > 0"></MyPigination>
    </v-container>
  </div>
</template>

<script>
import MyPigination from "@/components/MyPigination/index.vue";
import CommentStar from "@/components/CommentStar/index.vue";
import MyLabel from "@/components/MyLabel/index.vue";
import { vehiclesList, vehiclesType, vehiclesBrand } from "../../api/index";
export default {
  name: "BookIndex",
  components: {
    MyPigination,
    CommentStar,
    MyLabel,
  },
  data() {
    return {
      items: [],
      page: 1,
      totalCount: 0,
      size: 9,
      searchData: {
        keyword: "",
        brandId: 0,
        vehicleTypeId: 0,
        passenger: 0,
        sortField: "",
        sortType: "desc",
      },
      vehiclesType: [],
      brandList: [],
      passengerList: [
        {
          id: 0,
          name: this.$vuetify.lang.t("$vuetify.book.text5"),
        },
        {
          id: 1,
          name: "5人" + this.$vuetify.lang.t("$vuetify.seat"),
        },
        {
          id: 2,
          name: "5-10人" + this.$vuetify.lang.t("$vuetify.seat"),
        },
        {
          id: 3,
          name: "10人以上",
        },
      ],
      sortList: [],
    };
  },
  watch: {
    "$vuetify.lang.current": {
      immediate: true,
      handler() {
        // 语言切换时重新请求数据
        this.getVehiclesType();
        this.getBrandList();
        this.getList();
        this.passengerList[0].name = this.$vuetify.lang.t("$vuetify.book.text5");
        this.sortList = [
          {
            name: this.$vuetify.lang.t("$vuetify.book.text11"),
            value: "",
          },
          {
            name: this.$vuetify.lang.t("$vuetify.book.text12"),
            value: "price",
          },
        ];
      },
    },
  },
  created() {
    this.getVehiclesType();
    this.getBrandList();
    this.getList();
  },
  methods: {
    async getList() {
      let params = {
        page: this.page,
        size: this.size,
        ...this.searchData,
      };
      let res = await vehiclesList(params);
      if (res.code == 200) {
        this.items = res.data.list;
        this.totalCount = res.data.count;
      }
    },
    search() {
      console.log(this.searchData);
      this.getList();
    },
    getVehiclesType() {
      vehiclesType().then((res) => {
        if (res.code == 200) {
          this.vehiclesType = [
            {
              id: 0,
              name: this.$vuetify.lang.t("$vuetify.book.text3"),
            },
          ];
          this.vehiclesType = this.vehiclesType.concat(res.data.list);
        }
      });
    },
    getBrandList() {
      vehiclesBrand().then((res) => {
        if (res.code == 200) {
          this.brandList = [
            {
              id: 0,
              brandName: this.$vuetify.lang.t("$vuetify.book.text4"),
            },
          ];
          this.brandList = this.brandList.concat(res.data.list);
        }
      });
    },
    handleChangePage(value) {
      this.page = value;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  background: url(../../assets/free/bg.jpg) no-repeat;
  //background-size: 100% 100%;
  //padding: 80px 0;
  color: #fff;
  text-align: center;
  background-position: center;
  padding: 100px 0;
}

.content {
  .name {
    font-size: 1.5rem;
    position: relative;

    &::after {
      content: "";
      display: inline-block;
      width: 49px;
      height: 3px;
      background-color: #fed600;
      position: absolute;
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .search {
    .search-input {
      position: relative;
      overflow: hidden;
    }

    .search-btn {
      height: 100%;
      width: 30%;
      background-color: #fed600;
      position: absolute;
      right: 0;
      top: 0;
      text-align: center;
      line-height: 4;
    }
  }

  .list-item {
    box-shadow: 0px 5px 45px 0px rgba(34, 53, 162, 0.08);
    border-radius: 30px;
    padding: 20px;
    height: 100%;
  }
}

@media (max-width: 960px) {
  .banner {
    //background: url(../../assets/contact/img1_m.png) no-repeat;
    background-size: 100% 100%;
    padding: 52px 0;
    //text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
    //letter-spacing: 8px;
    color: #fff;
  }
  .aa{
    font-size: 1.875rem !important;
  }
  .tt {
    //letter-spacing: 1px;
    font-size: 1rem !important;
  }
  .content {
    .name {
      font-size: 1.25rem;
    }

    .list {
      padding: 0 6px;
    }

    .list-item {
      padding: 10px;
      border-radius: 8px;
    }

    // .list .col-6 {
    //     padding:
    // }
    .search {
      .search-btn {
        width: 20%;
        line-height: 4.3;
      }
    }
  }
}
</style>
