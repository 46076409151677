<template>
    <div>
        <div class="banner">
            <h3 class="text-md-36 text-30 font-weight-black pb-md-2">{{ $vuetify.lang.t("$vuetify.menu.consult") }}</h3>
            <h3 class="text-md-36 text-30 font-weight-medium tt">CONSULT US</h3>
        </div>
        <div class="container" style="margin-top: 10px;">
            <div class="header d-none d-md-block">
                <div>{{ $vuetify.lang.t("$vuetify.consult.text48") }}</div>
                <img src="../assets/charter/email.png" alt="">
            </div>
            <div class="d-md-none text-center text-20 mb-8 font-weight-bold">
                <img src="../assets/charter/email_m.png" alt="" width="60px">
                <div>{{ $vuetify.lang.t("$vuetify.consult.text48") }}</div>
            </div>
            <v-form ref="form">
                <!-- 要求 -->
                <div class="item">
                    <div class="item-title">
                        {{ $vuetify.lang.t("$vuetify.consult.text1") }}
                    </div>
                    <v-radio-group v-model="formData.demand" class="mt-0">
                        <v-radio :value="1" :label="$vuetify.lang.t('$vuetify.consult.text2')"></v-radio>
                        <v-radio :value="2" :label="$vuetify.lang.t('$vuetify.consult.text3')"></v-radio>
                    </v-radio-group>
                </div>
                <!-- 姓名 -->
                <div class="item">
                    <div class="item-title">{{ $vuetify.lang.t("$vuetify.consult.text4") }} <span class="text-red"><span class="text-red">*</span></span></div>
                    <v-text-field v-model="formData.name" outlined solo flat required :rules="rules.name"></v-text-field>
                </div>
                <!-- 汉字注假名 -->
                <div class="item">
                    <div class="item-title">{{ $vuetify.lang.t("$vuetify.consult.text5") }} <span class="text-red"><span class="text-red">*</span></span></div>
                    <v-text-field v-model="formData.writeKana" outlined solo flat required :rules="rules.writeKana"></v-text-field>
                </div>
                <!-- 申请人信息 -->
                <div class="item">
                    <div class="item-title">
                        {{ $vuetify.lang.t("$vuetify.consult.text6") }} <span class="text-red"><span class="text-red">*</span></span>
                    </div>
                    <v-radio-group v-model="formData.appInfo" class="mt-0" :rules="rules.appInfo">
                        <v-radio :value="1" :label="$vuetify.lang.t('$vuetify.consult.text7')"></v-radio>
                        <v-radio :value="2" :label="$vuetify.lang.t('$vuetify.consult.text8')"></v-radio>
                        <v-radio :value="3" :label="$vuetify.lang.t('$vuetify.consult.text9')"></v-radio>
                    </v-radio-group>
                </div>
                <!-- 邮政编码 -->
                <div class="item">
                    <div class="item-title">{{ $vuetify.lang.t("$vuetify.consult.text10") }} <span class="text-red">*</span></div>
                    <v-text-field v-model="formData.postalCode" outlined solo flat required :placeholder="$vuetify.lang.t('$vuetify.consult.text11')" :rules="rules.postalCode"></v-text-field>
                </div>
                <!-- 地点 -->
                <div class="item">
                    <div class="item-title">{{ $vuetify.lang.t("$vuetify.consult.text13") }} <span class="text-red">*</span></div>
                    <v-text-field v-model="formData.address" outlined solo flat required :rules="rules.address"></v-text-field>
                </div>
                <!-- 电子邮件 -->
                <div class="item">
                    <div class="item-title">{{ $vuetify.lang.t("$vuetify.consult.text14") }} <span class="text-red">*</span></div>
                    <v-text-field v-model="formData.email" outlined solo flat required :rules="rules.email"></v-text-field>
                </div>
                <!-- 手机号码 -->
                <div class="item">
                    <div class="item-title">{{ $vuetify.lang.t("$vuetify.consult.text15") }} <span class="text-red">*</span></div>
                    <v-text-field v-model="formData.phone" outlined solo flat required :rules="rules.phone"></v-text-field>
                </div>
                <div class="pb-3">{{ $vuetify.lang.t('$vuetify.consult.text16') }}</div>
                <!-- 选择汽车类型 -->
                <div class="item">
                    <div class="item-title">
                        {{ $vuetify.lang.t("$vuetify.consult.text17") }}
                    </div>
                    <v-radio-group v-model="formData.rentalType" class="mt-0">
                        <v-radio :value="1" :label="$vuetify.lang.t('$vuetify.consult.text18')"></v-radio>
                        <v-radio :value="2" :label="$vuetify.lang.t('$vuetify.consult.text19')"></v-radio>
                        <v-radio :value="3" :label="$vuetify.lang.t('$vuetify.consult.text20')"></v-radio>
                        <v-radio :value="4" :label="$vuetify.lang.t('$vuetify.consult.text21')"></v-radio>
                        <v-radio :value="5" :label="$vuetify.lang.t('$vuetify.consult.text22')"></v-radio>
                        <v-radio :value="6" :label="$vuetify.lang.t('$vuetify.consult.text23')"></v-radio>
                    </v-radio-group>
                </div>
                <!-- 其他车型 -->
                <div class="item">
                    <div class="item-title">{{ $vuetify.lang.t("$vuetify.consult.text24") }}</div>
                    <v-text-field v-model="formData.rentalOther" outlined solo flat required :placeholder="$vuetify.lang.t('$vuetify.consult.text25')"></v-text-field>
                </div>
                <!-- 选择租车附加选项 -->
                <div class="item">
                    <div class="item-title">
                        {{ $vuetify.lang.t("$vuetify.consult.text26") }}
                    </div>
                    <div>
                        <v-checkbox hide-details v-model="formData.additional" :label="$vuetify.lang.t('$vuetify.consult.text27')" :value="1"></v-checkbox>
                        <v-checkbox hide-details v-model="formData.additional" :label="$vuetify.lang.t('$vuetify.consult.text28')" :value="2"></v-checkbox>
                        <v-checkbox hide-details v-model="formData.additional" :label="$vuetify.lang.t('$vuetify.consult.text29')" :value="3"></v-checkbox>
                        <v-checkbox hide-details v-model="formData.additional" :label="$vuetify.lang.t('$vuetify.consult.text30')" :value="4"></v-checkbox>
                        <v-checkbox hide-details v-model="formData.additional" :label="$vuetify.lang.t('$vuetify.consult.text31')" :value="5"></v-checkbox>
                        <v-checkbox v-model="formData.additional" :label="$vuetify.lang.t('$vuetify.consult.text32')" :value="6"></v-checkbox>
                    </div>
                </div>
                <!-- 联系信息 -->
                <div class="item">
                    <div class="item-title">{{ $vuetify.lang.t("$vuetify.consult.text33") }}</div>
                    <v-text-field v-model="formData.contact" outlined solo flat required></v-text-field>
                </div>
                <!-- 期望的开始日期 -->
                <div class="item">
                    <div class="item-title">{{ $vuetify.lang.t("$vuetify.consult.text34") }}</div>
                    <DatePicker :placeholder="$vuetify.lang.t('$vuetify.consult.text35')"
                        :minDate="$formatDate(new Date().toISOString())" @date-changed="onDateChange">
                    </DatePicker>
                </div>
                <div class="d-md-flex">
                    <!-- 期望的时期 -->
                    <div class="item item1">
                        <div class="item-title">{{ $vuetify.lang.t("$vuetify.consult.text36") }}</div>
                        <v-text-field v-model="formData.day" outlined solo flat required type="number" min="0" :placeholder="$vuetify.lang.t('$vuetify.consult.text37')"></v-text-field>
                    </div>
                    <!-- 所需时间单位 -->
                    <div class="item ml-md-4">
                        <div class="item-title mb-3">
                            {{ $vuetify.lang.t("$vuetify.consult.text38") }}
                        </div>
                        <v-radio-group v-model="formData.dayType" class="mt-0" row>
                            <v-radio :value="1" :label="$vuetify.lang.t('$vuetify.cDay')"></v-radio>
                            <v-radio :value="2" :label="$vuetify.lang.t('$vuetify.week')"></v-radio>
                            <v-radio :value="3" :label="$vuetify.lang.t('$vuetify.month')"></v-radio>
                            <v-radio :value="4" :label="$vuetify.lang.t('$vuetify.year')"></v-radio>
                        </v-radio-group>
                    </div>
                </div>
                <div class="d-md-flex">
                    <!-- 司机人数 -->
                    <div class="item item2">
                        <div class="item-title">
                            {{ $vuetify.lang.t("$vuetify.consult.text39") }}
                        </div>
                        <v-radio-group v-model="formData.driver" class="mt-0">
                            <v-radio :value="1" :label="1 + $vuetify.lang.t('$vuetify.consult.text40')"></v-radio>
                            <v-radio :value="2" :label="2 + $vuetify.lang.t('$vuetify.consult.text40')"></v-radio>
                            <v-radio :value="3" :label="3 + $vuetify.lang.t('$vuetify.consult.text40')"></v-radio>
                            <v-radio :value="4" :label="4 + $vuetify.lang.t('$vuetify.consult.text40') + $vuetify.lang.t('$vuetify.consult.text41')"></v-radio>
                            <v-radio :value="5" :label="0 + $vuetify.lang.t('$vuetify.consult.text40') + $vuetify.lang.t('$vuetify.consult.text42')"></v-radio>
                        </v-radio-group>
                    </div>
                    <!-- 所需汽车数量 -->
                    <div class="item item2">
                        <div class="item-title">
                            {{ $vuetify.lang.t("$vuetify.consult.text43") }} <span class="text-red">*</span>
                        </div>
                        <v-radio-group v-model="formData.car" class="mt-0" :rules="rules.car">
                            <v-radio :value="1" :label="1 + $vuetify.lang.t('$vuetify.consult.text44')"></v-radio>
                            <v-radio :value="2" :label="2 + $vuetify.lang.t('$vuetify.consult.text44')"></v-radio>
                            <v-radio :value="3" :label="3 + $vuetify.lang.t('$vuetify.consult.text44')"></v-radio>
                            <v-radio :value="4" :label="4 + $vuetify.lang.t('$vuetify.consult.text44')"></v-radio>
                            <v-radio :value="5" :label="5 + $vuetify.lang.t('$vuetify.consult.text44') + $vuetify.lang.t('$vuetify.consult.text45')"></v-radio>
                        </v-radio-group>
                    </div>
                </div>
                <div class="item">
                    <div class="item-title">
                        {{ $vuetify.lang.t("$vuetify.consult.text46") }}
                    </div>
                    <v-textarea outlined flat solo v-model="formData.remark"></v-textarea>
                </div>
            </v-form>
            <div class="text-center">
                <v-btn :width="300" class="btn-submit mt-5 text-20" @click="submitFunc">
                {{ $vuetify.lang.t("$vuetify.submit") }}
                </v-btn>
            </div>
        </div>
        
        <img src="../assets/charter/QA.jpg" alt=""
         class="d-md-block d-none"
         width="150px"
         style="position: fixed;right: 0;bottom: 5vh;" @click="scrollToTop">

         <img src="../assets/charter/top.jpg" alt="" :class="['d-md-none', 'd-md-none top', showTopButton ? 'active' : '']" @click="scrollToTop">
    </div>
</template>

<script>
import DatePicker from "../components/DatePicker/index.vue";
import { charteredBus } from '@/api/index';
export default {
    name: 'consultIndex',
    components: {
        DatePicker
    },
    data: () => {
        return {
            showTopButton: false,
            formData: {
                demand: 0,
                name: '',
                writeKana: '',
                appInfo: 0,
                postalCode: '',
                address: '',
                email: '',
                phone: '',
                rentalType: 0,
                rentalOther: '',
                additional: [],
                contact: '',
                start: '',
                day: 0,
                dayType: 0,
                driver: 0,
                car: 0,
                remark: ''
            },
            rules: {
                name: [value => !!value],
                writeKana: [value => !!value],
                appInfo: [value => !!value],
                postalCode: [value => !!value],
                address: [value => !!value],
                email: [value => !!value],
                phone: [value => !!value],
                car: [value => !!value]
            },
        }
    },
    created() {
        
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        onDateChange(date) {
            this.formData.start = date;
        },
        handleScroll() {
            const viewportHeight = window.innerHeight; // 获取视口高度
            this.showTopButton = window.scrollY > viewportHeight; // 当滚动距离超过一屏时显示按钮
        },
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        },
        async submitFunc() {
            const valid =  this.$refs.form.validate();
            if (!valid) {
                return;
            }
            this.formData.day = Number(this.formData.day);
            this.formData.additional = this.formData.additional.join(',');
            let res = await charteredBus({
                ...this.formData
            });
            if (res.code == 200) {
                this.$SnackBar.show('提交成功', { type: 'success' });
                this.$refs.form.reset();
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.banner {
  background: url(../assets/charter/bg.jpg) no-repeat;
    //background-size: 100% 100%;
  background-position: center;
    padding: 100px 0;
    //color: #fff;
    text-align: center;
}

.container {
    max-width: 700px;
    .item {
        .item-title {
            font-weight: bold;
            padding-bottom: 5px;
        }
        &.item1 {
            width: 35%;
        }
        &.item2 {
            width: 50%;
        }
    }
    .header {
        position: relative;
        line-height: 5;
        font-size: 24px;
        text-align: center;
        margin-bottom: 50px;
        font-weight: bold;
        // color: rgb(27,108,223);
        img {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}
.v-input--selection-controls {
    margin-top: 0;
}

@media (max-width: 960px) {
    .banner {
        // background: url(../assets/contact/img1_m.png) no-repeat;
        background-size: 100% 100%;
        padding: 52px 0;
        text-shadow:
            -1px -1px 0 #fff,
            1px -1px 0 #fff,
            -1px 1px 0 #fff,
            1px 1px 0 #fff;
        letter-spacing: 8px;
    }
    .container {
        .item {
            &.item1 {
                width: 100%;
            }
            &.item2 {
                width: 100%;
            }
        }
    }
    .car-container {
      .car-item {
        text-align: right;

        img {
          width: 90%;
        }
      }
    }
    .tt{
        letter-spacing:1px;
        font-size: 1rem !important;
    }
    .top {
        position: fixed;
        right: 30px;
        bottom: 5vh;
        opacity: 0;
        visibility: hidden;
        transition: visibility 0s .5s,opacity .5s ease 0s;
        &.active {
            opacity: 1;
            visibility: visible;
            transition-delay: 0s;
        }
    }
}
</style>
