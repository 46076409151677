export function formatPrice(value) {
  if (!value) return '0';
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function parseDate(dateString) {
  // 确保日期字符串为 ISO 8601 格式
  const isoDate = dateString.replace(/-/g, '-').replace(/T/g, ' ').replace(/\..+/, '');
  return new Date(isoDate);
}

export function formatDate(dateString, format = 'YYYY-MM-DD HH:mm:ss') {
  const date = parseDate(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  return format.replace('YYYY', year)
    .replace('MM', month)
    .replace('DD', day)
    .replace('HH', hours)
    .replace('mm', minutes)
    .replace('ss', seconds);
}
