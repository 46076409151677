<template>
  <v-app>
    <AppHeader class="d-none d-md-block" v-if="$route.meta.showNavbar"></AppHeader>
    <MobileHeader class="d-md-none" v-if="$route.meta.showNavbar"></MobileHeader>
    <v-main>
      <router-view />
    </v-main>
    <AppFooter v-if="$route.meta.showNavbar"></AppFooter>
  </v-app>
</template>

<script>
import AppHeader from "./components/AppHeader/index.vue";
import MobileHeader from "./components/AppHeader/mobile.vue";
import AppFooter from "./components/AppFooter/index.vue";
export default {
  name: "App",
  components: {
    AppFooter,
    AppHeader,
    MobileHeader
  },

  data: () => ({
    //
  }),
};
</script>

<style scoped>
.v-main {
    padding-top: 114px !important;
  }
@media (max-width: 960px) {
  .v-main {
    padding-top: 48px !important;
  }
}
</style>
