<template>
  <v-container>
    <div class="d-md-flex align-center justify-center pb-7 d-none">
      <span>{{ $vuetify.lang.t("$vuetify.pigination.text1") }}{{ total }}{{ $vuetify.lang.t("$vuetify.pigination.text2")
      }}，{{ $vuetify.lang.t("$vuetify.pigination.text3") }}{{ currentPage }}/{{ totalPages }}{{
  $vuetify.lang.t("$vuetify.pigination.text4") }}</span>
      <!-- 自定义 "上一页" 按钮 -->
      <v-btn class="text-14 ml-5" outlined :disabled="isFirstPage" @click="prevPage">
        {{ $vuetify.lang.t("$vuetify.pigination.text5") }}
      </v-btn>

      <!-- 自定义分页数字 -->
      <div class="d-flex pagination ml-2 mr-2">
        <div class="item" v-for="(item, index) in pages" :key="index">
          <v-btn text tile :min-width="37" :class="{ primary: item === currentPage, 'pa-0': true }"
            @click="goToPage(item)">
            {{ item }}
          </v-btn>
        </div>
      </div>
      <!-- 自定义 "下一页" 按钮 -->
      <v-btn class="text-14" outlined :disabled="isLastPage" @click="nextPage">
        {{ $vuetify.lang.t("$vuetify.pigination.text6") }}
      </v-btn>

      <!-- 输入框跳转到特定页 -->
      <div class="ml-5">
        {{ $vuetify.lang.t("$vuetify.pigination.text7") }}
        <div style="width: 60px; height: 37px; display: inline-block">
          <v-text-field v-model="jumpToPage" :hide-details="true" type="number" min="1" dense solo flat outlined
            @change="onJumpToPageChange"></v-text-field>
        </div>
        {{ $vuetify.lang.t("$vuetify.pigination.text4") }}
      </div>
    </div>

    <!-- 手机端 分页 -->
    <div class="d-flex align-center justify-center pb-7 d-md-none">
      <!-- 自定义 "上一页" 按钮 -->
      <v-btn class="text-14" outlined :disabled="isFirstPage" @click="prevPage">
        {{ $vuetify.lang.t("$vuetify.pigination.text5") }}
      </v-btn>

      <!-- 显示当前页 -->
      <div class="d-flex pagination ml-2 mr-2 text-14">
        <div class="item">
          <v-btn text outlined class="text-14" tile :min-width="37"
            :class="{ primary: currentPage === currentPage, 'pa-0': true }" @click="goToPage(currentPage)">
            {{ currentPage }}
          </v-btn>
        </div>

        <!-- 显示省略号 -->
        <span class="pl-1 pr-1" v-if="totalPages > 2 && currentPage >= 1 && currentPage < totalPages">...</span>

        <!-- 显示最后一页 -->
        <div class="item" v-if="currentPage !== totalPages">
          <v-btn text outlined tile class="text-14" :min-width="37"
            :class="{ primary: currentPage === totalPages, 'pa-0': true }" @click="goToPage(totalPages)">
            {{ totalPages }}
          </v-btn>
        </div>
      </div>
      <!-- 自定义 "下一页" 按钮 -->
      <v-btn class="text-14" outlined :disabled="isLastPage" @click="nextPage">
        {{ $vuetify.lang.t("$vuetify.pigination.text6") }}
      </v-btn>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "MyPagination",
  data() {
    return {
      currentPage: 1,
      totalPages: 0, // 假设总共有 10 页
      jumpToPage: 1, // 用户输入跳转到的页码
    };
  },
  props: {
    total: {
      type: Number,
      default: 0,
    },
    size: {
      type: Number,
      default: 10,
    },
  },
  created() {
    this.totalPages = Math.ceil(this.total / this.size);
  },
  watch: {
    total(newTotal) {
      this.totalPages = Math.ceil(newTotal / this.size);
    },
    size(newSize) {
      this.totalPages = Math.ceil(this.total / newSize);
    },
  },
  computed: {
    isFirstPage() {
      return this.currentPage === 1;
    },
    isLastPage() {
      return this.currentPage === this.totalPages;
    },
    pages() {
      const visiblePages = 5;
      let startPage = Math.max(1, this.currentPage - Math.floor(visiblePages / 2));
      let endPage = Math.min(this.totalPages, startPage + visiblePages - 1);

      if (endPage - startPage + 1 < visiblePages) {
        startPage = Math.max(1, endPage - visiblePages + 1);
      }

      return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
    },
  },
  methods: {
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage -= 1;
        this.$emit("changePage", this.currentPage);
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage += 1;
        this.$emit("changePage", this.currentPage);
      }
    },
    goToPage(page) {
      this.currentPage = page;
      this.$emit("changePage", page);
    },
    onJumpToPageChange(newPage) {
      newPage = Number(newPage)
      if (newPage >= 1 && newPage <= this.totalPages) {
        this.currentPage = newPage;
        this.$emit("changePage", newPage);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  border: 1px solid #ededed;

  .item {
    border-right: 1px solid #ededed;
  }
}

::v-deep .v-text-field--outlined.v-input--dense.v-text-field--single-line>.v-input__control>.v-input__slot,
.v-text-field--outlined.v-input--dense.v-text-field--outlined>.v-input__control>.v-input__slot {
  min-height: 36px;
}

@media (max-width: 960px) {
  .pagination {
    border: none;

    .item {
      border-right: none;
    }
  }
}
</style>
