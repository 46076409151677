<template>
    <div>
        <div class="banner">
            <h3 class="text-36 text-30 font-weight-black pb-md-2 aa">{{ $vuetify.lang.t("$vuetify.free.title") }}</h3>
            <h3 class="text-36 text-30 font-weight-medium tt">CAR RENTAL SELF GUIDED TOUR</h3>
        </div>
        <v-container>
            <div class="text-md-24 text-14 color-000-36 text-center pt-md-12 pt-8 pb-md-5 pb-2 font-weight-bold">{{
                $vuetify.lang.t("$vuetify.free.title") }}</div>
            <div class="text-md-34 text-20 text-center pb-md-16 pb-5 font-weight-bold">{{ $vuetify.lang.t("$vuetify.free.text1") }}</div>
            <div class="d-flex flex-wrap justify-space-between">
                <div class="free-item">
                    <div class="text-20 pb-md-4 font-weight-bold">{{ $vuetify.lang.t("$vuetify.free.text2") }}
                    </div>
                    <div class="text-md-16 text-14 text-000-5">{{ $vuetify.lang.t("$vuetify.free.text3") }}</div>
                    <v-img contain src="../assets/free/img1.png" class="item-img"></v-img>
                </div>
                <div class="free-item">
                    <div class="text-20 pb-md-4 font-weight-bold">{{ $vuetify.lang.t("$vuetify.free.text4") }}
                    </div>
                    <div class="text-md-16 text-14 text-000-5">{{ $vuetify.lang.t("$vuetify.free.text5") }}</div>
                    <v-img contain src="../assets/free/img1.png" class="item-img"></v-img>
                </div>
                <div class="free-item">
                    <div class="text-20 pb-md-4 font-weight-bold">{{ $vuetify.lang.t("$vuetify.free.text6") }}
                    </div>
                    <div class="text-md-16 text-14 text-000-5">{{ $vuetify.lang.t("$vuetify.free.text7") }}</div>
                    <v-img contain src="../assets/free/img1.png" class="item-img"></v-img>
                </div>
                <div class="free-item">
                    <div class="text-20 pb-md-4 font-weight-bold">{{ $vuetify.lang.t("$vuetify.free.text8") }}
                    </div>
                    <div class="text-md-16 text-14 text-000-5">{{ $vuetify.lang.t("$vuetify.free.text9") }}</div>
                    <v-img contain src="../assets/free/img1.png" class="item-img"></v-img>
                </div>
            </div>
            <div class="text-md-24 text-14 color-000-36 text-center pt-5 pb-md-5 pb-2 font-weight-bold">{{
                $vuetify.lang.t("$vuetify.free.text10") }}</div>
            <div class="text-md-34 text-20 text-center pb-16 font-weight-bold">{{ $vuetify.lang.t("$vuetify.free.text11") }}</div>
            <v-row class="mb-md-16 mb-12 pl-8 pr-8 pl-md-0 pr-md-0">
                <v-col md="4" cols="12" class="pa-0 pa-md-3 mb-5 mb-md-0">
                    <div class="d-inline-flex align-center">
                        <v-img class="server-img" contain src="../assets/free/img2.png"></v-img>
                        <div class="ml-5">
                            <div class="text-20 font-weight-bold">{{ $vuetify.lang.t("$vuetify.free.text12") }}</div>
                            <div class="text-md-16 text-14 text-000-5 pt-3">{{ $vuetify.lang.t("$vuetify.free.text13") }}
                            </div>
                        </div>
                    </div>
                </v-col>
                <v-col md="4" cols="12" class="pa-0 pa-md-3 mb-5 mb-md-0">
                    <div class="d-inline-flex align-center">
                        <v-img class="server-img" contain src="../assets/free/img3.png"></v-img>
                        <div class="ml-5">
                            <div class="text-20 font-weight-bold">{{ $vuetify.lang.t("$vuetify.free.text14") }}</div>
                            <div class="text-md-16 text-14 text-000-5 pt-3">{{ $vuetify.lang.t("$vuetify.free.text15") }}
                            </div>
                        </div>
                    </div>
                </v-col>
                <v-col md="4" cols="12" class="pa-0 pa-md-3">
                    <div class="d-inline-flex align-center">
                        <v-img class="server-img" contain src="../assets/free/img4.png"></v-img>
                        <div class="ml-5">
                            <div class="text-20 font-weight-bold">{{ $vuetify.lang.t("$vuetify.free.text16") }}</div>
                            <div class="text-md-16 text-14 text-000-5 pt-3">{{ $vuetify.lang.t("$vuetify.free.text17") }}
                            </div>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
    name: 'FreeIndex',
    data() {
        return {

        }
    }
}
</script>

<style lang="scss" scoped>
.banner {
    background: url(../assets/free/bg.jpg) no-repeat;
    //background-size: 100% 100%;
    //padding: 80px 0;
    //color: #fff;
    text-align: center;
  background-position: center;
  padding: 100px 0;
  color: white;
}

.answer-item {
    line-height: 2.2;
}

.free-item {
    width: 49%;
    line-height: 2;
    padding: 40px 40px 70px;
    box-shadow: 0px 5px 45px 0px rgba(34, 53, 162, 0.08);
    position: relative;
    margin-bottom: 40px;

    .item-img {
        width: 120px;
        position: absolute;
        bottom: 25px;
        right: 25px;
    }
}

.server-img {
    width: 120px;
}

@media (max-width: 960px) {
    .banner {
        //background: url(../assets/charter/bg.jpg) no-repeat;
        background-size: 100% 100%;
        padding: 52px 0;
      //text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
      //letter-spacing: 8px;
      color: white;
    }
  .tt {
    //letter-spacing: 1px;
    font-size: 1rem !important;
  }
  .aa{
    font-size: 1.875rem !important;
  }
    .free-item {
        width: 100%;
        padding: 20px 20px 40px;
        margin-bottom: 20px;

        .item-img {
            width: 89px;
            bottom: 12px;
            right: 12px;
        }
    }

    .server-img {
        width: 110px;
    }
}</style>